import { Link, useNavigate } from "react-router-dom";
import { signInAction, clearMessage } from "../service/actions/authActions";
import {useFormik} from "formik";
import {signInSchema} from "../schemas/authSchemas";
import {faCircleExclamation, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "sonner";

const LogIn = () => {
    const {signInError, successMessage} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);


    const onSubmit = async (values, actions) => {
        await dispatch(clearMessage());
        try {
            await dispatch(signInAction(values, navigate));
            if (successMessage) {
                actions.resetForm();
            }
        } catch (err) {
            const errMsg = err?.response?.data || "unexpected error occurred";
            toast.error(errMsg);
        }
    };

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            username: "",
            password: ""
        },
        validationSchema: signInSchema,
        onSubmit
    })


    return (
        <section className="login">
            {successMessage && (<div>{successMessage}</div>)}

            {signInError &&
                <div className="link">
                    {signInError}
                    <div
                        onClick={async () => {
                            dispatch(clearMessage())
                            navigate("/reset-email")
                        }}
                        className="signup-link"
                    >
                        Forget Password
                    </div>
                </div>
            }

            <div className="login-header">
                <p>LOG IN</p>
            </div>

            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="input-form"
            >
                <div className="input-box">
                    <input
                        id="username"
                        name="username"
                        type="text"
                        placeholder="Username"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        autoComplete="off"
                    />
                    {errors.username && touched.username
                        && <p className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation}/>
                            </span>
                            {errors.username}</p>
                    }
                </div>

                <div className="input-box">
                    <div className="input-area">
                        <input
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="off"
                            className="password-input"
                        />
                        <FontAwesomeIcon
                            icon={showPassword ? faEye : faEyeSlash}
                            onClick={() => setShowPassword(!showPassword)}
                            className="eye-icon"
                            style={{color: showPassword ? "#3083ff" : "#6f6f6f"}}
                        />

                    </div>
                    {errors.password && touched.password
                        && <p className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation}/>
                            </span>
                            {errors.password}</p>
                    }
                </div>

                <div className="login-btn-area">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="login-btn"
                    >
                        {isSubmitting ? <div className="loading-spinner"></div> : "Login"}
                    </button>
                </div>

            </form>

            <div className="link">
                <p>Do you have Account? <br/>
                    Click to <Link to={"/signup"} className="signup-link">Create Account</Link>
                </p>
            </div>

        </section>
    );
};

export default LogIn;