import { NavLink, useLocation } from 'react-router-dom';

export default function PsFooter1() {
    const location = useLocation();


    const isHomeActive = (
        location.pathname === `/space/${location.pathname.split('/')[2]}` ||
        location.pathname.endsWith('/') ||
        location.pathname.endsWith('/home')
    );

    return (
        <footer className="ps-footer">
            <div className="ps-nav">
                <NavLink
                    to="home"
                    className="ps-footer-btn"
                    style={isHomeActive ? { color: "#fff" } : null}
                >
                    Home
                </NavLink>
                <NavLink
                    to="your-page"
                    className="ps-footer-btn"
                    style={({ isActive }) => isActive ? { color: "#fff" } : null}
                >
                    Your page
                </NavLink>
            </div>
        </footer>
    );
}
