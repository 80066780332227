const LandingPage = () => {

    const handleNext = async () => {
        await localStorage.setItem("initialize", "true");
        window.location.href = "/signup";
    }

    return (
        <div>
            <div className="app-title">InSpace</div>
            <div className="section_1">
                <div className="main-title">
                    InSpace isn't just another photo sharing platform.
                </div>
                <div className="sub">
                    It's place where you & your friends connect and
                    share moments with <span className="span-sub">inside a private space</span>
                </div>
            </div>

            <div className="section_2">
                <div className="head_2">
                    What is a private Space, and <br/>How Does it Work?
                </div>
                <div className="parts">
                    <div className="part-1">
                        <div className="part-1_head">
                            <span>🏠</span> Your Own Private Hangout with Friends
                        </div>
                        <div className="part-1_body">
                            <p>
                                Interact comfortably with your closest friends no pressure,
                                no judgement
                            </p>
                            <p>
                                Imagine your own place with friends of the internet authentic and personal
                            </p>
                            <p>
                                Spaces are exclusive to your space members only, where every
                                interactions feels real and meaningful
                            </p>
                        </div>
                    </div>
                    <div className="part-2">
                        <div className="part-2_head">
                            Cool Features You'll Love
                        </div>
                        <div className="part-2_body">
                            <div className="card">
                                <div className="card-head">
                                    Privacy First
                                </div>
                                <div className="card-body">
                                    Only the people in your Space
                                    can see what you share. No Outsiders, no worries
                                </div>
                                <div className="card-icon">
                                    <img src="/Icon.svg" alt="privacy"/>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-head">
                                    Simple Posting
                                </div>
                                <div className="card-body">
                                    Share up to three posts a day with your friends inside a space,
                                    using emojis to react. Hit your limit?
                                    Just chill and enjoy the moment until tomorrow.
                                </div>
                                <div className="card-icon">
                                    <img src="/Icon2.svg" alt="camera"/>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-head">
                                    Member Pages
                                </div>
                                <div className="card-body">
                                    Everyone had a personal gallery within
                                    the Space, showcasing all their favorite
                                    memories
                                </div>
                                <div className="card-icon">
                                    <img src="/image-solid.svg" alt="moment"/>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-head">
                                    Evolving Experience
                                </div>
                                <div className="card-body">
                                    Spaces are always growing with new features
                                    to make sharing more fun and personal. Stay tuned for
                                    exciting updates!
                                </div>
                                <div className="card-icon">
                                    <img src="/arrow-rotate-right-solid.svg" alt="update"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="section_3">
                <div className="head_3">
                    Spaces Vs Groups
                </div>
                <div className="sub-head_3">
                    What's the Real Difference?
                </div>
                <div className="body_3">
                    <p>
                        Spaces: Your <span className="body_3-span1">Private hub an entire app-like experience</span>
                        for sharing, laughing, and making memories with your
                        friends in a secure and personal setting.
                    </p>
                    <p>
                        Groups: Just <span className="body_3-span2">basic chat rooms good for quick chats</span>
                        but lacking the fun and depth of a real Space.
                    </p>
                </div>
            </div>

            <div className="section_4">
                <div className="head_4">
                    Your Privacy Matters - It's <br/>More Than a Feature;
                    It's Our Foundation
                </div>
                <div className="body_4">
                    <div className="container">
                        <div className="container_head">
                            <img src="/Vector.svg" alt="privacy"/>
                            <h2>Total Control</h2>
                        </div>
                        <div className="content">
                            <p className="description">
                                Only space friends can see your posts.
                                Even Inspace can't view or use your content.
                            </p>
                            <div className="message">
                                No Ads, No tracking <br/>
                                just genuine connections
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="container_head">
                            <img src="/db.svg" alt="secure"/>
                            <h2>Secure Storage</h2>
                        </div>
                        <div className="content">
                            <p className="description">
                                Your photos are protected with ever-changing links
                            </p>
                            <div className="message">
                                Keep your memories <br/>
                                safe and secure.
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="container_head">
                            <img src="/earth-americas-solid2.svg" alt="explore"/>
                            <h2>No Outside Access</h2>
                        </div>
                        <div className="content">
                            <p className="description">
                                No one else can see or access it<br/>
                                from your space, expect members
                            </p>
                            <div className="message">
                                Your Private Space <br/>
                                Your World.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section_5">
                <div className="head_5">
                    Member Pages: Your Friends Galleries in One Place
                </div>
                <div className="sub-head_5">
                    See All Your Friends Posts <br/>
                    in One space
                </div>
                <div className="body_5">
                    <div className="video">
                        Video later added
                    </div>

                    <div className="body_5-description">
                        <p>
                            Member Pages bring
                            <span>everyone's memories together in a private</span>,
                            secure way.
                        </p>

                        <p>
                            Unlike other platforms like Instagram or Snapchat,
                            where <span>posts can be public or private on individual profiles</span>
                            or might <span>disappear quickly.</span>
                        </p>

                        <p>
                            Member Pages offer <span>a long lasting and comfortable way</span>
                            to enjoy and reflect on your shared moments
                            <span>not only you all your friends combined.</span>
                        </p>

                    </div>
                </div>

            </div>

            <div className="section_6">
                <div className="head_6">
                    Explore Outside Space
                    <img src="/earth-americas-solid2.svg" alt="explore"/>
                </div>

                <div className="section_6-container">
                    <div className="section_6-visual">Image</div>
                    <div className="section_6-description">
                        Discover new friendships, <br/>
                        explore profiles, and join other Spaces
                    </div>
                </div>

                <div className="section_6-notes">
                    <div className="section_6-note">
                        <div className="section_6-prompt-title">
                            Inside a Space
                        </div>
                        <div className="section_6-prompt-value">
                            <p style={{color: "#14AE5C"}}>Private</p> |
                            <p style={{color: "#74C0FC"}}>Personal</p> |
                            <p style={{color: "#D732A8"}}>Exclusive</p>
                        </div>
                    </div>

                    <div className="section_6-note">
                        <div className="section_6-prompt-title">
                            Outside a Space
                        </div>
                        <div className="section_6-prompt-value">
                            <p style={{color: "#DB2F2F"}}>Public</p> |
                            <p style={{color: "#D9D9D9"}}>Exploratory</p> |
                            <p style={{color: "#FFD43B"}}>Social</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section_7">

                <div className="head_7">
                    New Features Coming Soon inside a Space
                </div>

                <div className="sub-head_7">
                    Exciting Updates to Make <br/>
                    Your Spaces More Fun
                </div>

                <div className="body_7">
                    <div className="section_7-card">
                        <div className="section_7-card-head">
                            Status
                        </div>
                        <p className="section_7-card-description">
                            Express your current mood or thoughts
                            within your Spaces.
                        </p>
                    </div>

                    <div className="section_7-card">
                        <div className="section_7-card-head">
                            Challenges
                        </div>
                        <p className="section_7-card-description">
                            Engage in fun challenges within your
                            Spaces to keep the excitement alive.
                        </p>
                    </div>

                    <div className="section_7-card">
                        <div className="section_7-card-head">
                            Events
                        </div>
                        <p className="section_7-card-description">
                            Organize memories by time, creating
                            standout moments easy to revisit
                        </p>
                    </div>
                </div>

                <div className="section_7-footer">
                    and so on coming soon... <br/>
                    inside a space.
                </div>

            </div>

            <div className="section_8">
                <div className="head_8">
                    InSpace isn't just about <br/>
                    photo sharing platform
                </div>
                <div className="info_8">
                    Our app is coming soon stay <br/>
                    tuned for updates!
                </div>
            </div>


            <div className="section_9">
                <div className="head_9">
                    Get Started
                </div>

                <div className="body_9">
                    <div className="body_9-container">
                        <div className="body_9-container-nav">
                            <div className="body_9-container-title">
                                <div className="body_9-container-head">
                                    PWA
                                </div>
                                <div className="body_9-container-sub-head">
                                    Progressive <br/> web app
                                </div>
                            </div>
                            <div className="body_9-container-button">
                                <button>Install Pwa</button>
                            </div>
                        </div>

                        <div className="body_9-container-content">
                            <p>
                                delivers an app-like experience
                                directly on your device, bypassing
                                the need for a traditional web browser
                            </p>
                        </div>

                    </div>

                    <div className="body_9-web-button">
                        <button onClick={handleNext}>Continue with Website</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage;