import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "sonner";
import {useFormik} from "formik";
import {emailSchema} from "../schemas/authSchemas";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {API} from "../service/api/utils";


function ForgetPasswordEmail() {
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (values, action) => {
        setError("");
        setMessage("");

        try {
            const response = await API.post(`auth/forget-password-email/${values.email}`);
            const data = response?.data;

            if (data?.status === 202 && data?.code === "Account Activation") {
                action.resetForm();
                navigate("/signup-verification", { state: values.email });
                toast.success(data?.message);
            }

            if (data.status === 200 && data?.code === "Reset Password") {
                setMessage(data?.message);
            }

        } catch (error) {
            if (error?.response?.status === 500) {
                setError("Something went wrong. Please try again later");
            } else {
                const errMsg = error?.response?.data?.message || "Unexpected error occurred";
                setError(errMsg);
            }
        }
    };


    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: emailSchema,
        onSubmit
    })


    return (
        <section className="fpe__section">
            <button onClick={() => {navigate("/signin")}} className="fpe__back-button">
                <img src="/back.svg" alt="back" className="fpe__back-icon" />
            </button>
            <form onSubmit={handleSubmit} className="fpe__email-form">
                <div className="fpe__form-header">
                    Enter your email used in your account
                </div>

                <div className="fpe__form-input-box">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        required
                        placeholder="example@email.com"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        className="fpe__form-input"
                    />

                    {errors.email && touched.email && (
                        <div className="input-validation__error">
                            <span className="input-validation__error-icon">
                                <FontAwesomeIcon icon={faCircleExclamation}/>
                            </span>
                            {errors.email}
                        </div>
                    )}
                </div>

                <div className="fpe__submit-button-container">
                    <button
                        disabled={!values.email || isSubmitting}
                        type="submit"
                        className={`fpe__submit-button ${isSubmitting ? 'fpe__disabled-btn' : ''}`}
                    >
                        {isSubmitting ? <div className="loading-spinner"></div> : "Enter"}
                    </button>
                </div>
            </form>

            <div className="fpe__message-container">
                {error && <p className="fpe__error-message">{error}</p>}
                {message && <p className="fpe__success-message">{message}</p>}
            </div>

        </section>
    )
}

export default ForgetPasswordEmail;