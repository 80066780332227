import {Outlet} from "react-router-dom";
import MainHeader1 from "./MainHeader1";
import MainFooter1 from "./MainFooter1";

export default function MainLayout1() {

  return (
   <>
      <MainHeader1 />
      <Outlet />
      <MainFooter1 />
   </>
  )
}