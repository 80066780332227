import { Link, useNavigate } from "react-router-dom";
import {useFormik} from "formik";
import {createSpaceSchema} from "../schemas/appSchemas";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {createSpaceAction} from "../service/actions/userActions";
import {toast} from "sonner";
import {API} from "../service/api/utils";
import {useState} from "react";

function CreateSpace() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState("");

    const onSubmit = async (values, actions) => {
        setError("");
        await API.post("in-space/space/create", values)
            .then(async (res) => {
                sessionStorage.removeItem('x-ps');
                await createSpaceAction(res?.data, dispatch);
                toast.success("space created successfully");
                navigate("/spaces");
                actions.resetForm();
            }).catch((err) => {
                let {status, error, validationErrors, message} = err?.response?.data || {};
                if(status === 400 && error === "Validation failed") {
                    toast.error(validationErrors[0]);
                } else {
                    setError(message || "unexpected error occurred")
                }
            })
    }

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            spaceId: "",
            spaceName: ""
        },
        validationSchema: createSpaceSchema,
        onSubmit,
    })


    return (
        <section className="register-page">
            <div className="register-body">
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="register-form"
                >
                    <div className="create-box">
                        <input
                            type="text"
                            id="spaceId"
                            name="spaceId"
                            placeholder="Space Id"
                            value={values.spaceId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="register-space-id"
                            autoComplete="off"
                            required
                        />
                        {errors.spaceId && touched.spaceId
                            && <p className="input-validation__error">
                                <span>
                                    <FontAwesomeIcon icon={faCircleExclamation}/>
                                </span>
                                {errors.spaceId}</p>
                        }
                    </div>

                    <div className="create-box">
                        <input
                            id="spaceName"
                            name="spaceName"
                            type="text"
                            value={values.spaceName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Space Name"
                            className="register-space-name"
                            autoComplete="off"
                            required
                        />
                        {errors.spaceName && touched.spaceName
                            && <p className="input-validation__error">
                                <span>
                                    <FontAwesomeIcon icon={faCircleExclamation}/>
                                </span>
                                {errors.spaceName}</p>
                        }
                    </div>

                    <button
                        type="submit"
                        className="register-btn"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Creating.." : "Create"}
                    </button>
                </form>
            </div>
            {error && <p>{error}</p>}

            <div className="join-link">
                Click to <Link to="../join">Join Space</Link>
            </div>
        </section>
    )
}


export default CreateSpace;

// Todo: After create we invalid the space token and try to regenerate that token. <Complete>
// Todo: After create space then count and spaces are fetched. <Complete>
// Todo: create space button is not shown and hidden in footer. <Tackle later>