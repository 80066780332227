import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {resetPasswordSchema} from "../../schemas/authSchemas";
import {API} from "../../service/api/utils";
import {toast} from "sonner";


function ResetPassword({token, previous}) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");


    const onSubmit = async (values, actions) => {
        const { password, confirmationPassword } = values;
        const request = {
            code: token,
            password: password,
            confirmPassword: confirmationPassword,
        };

        try {
            const res = await API.post("/auth/reset-password", request);
            actions.resetForm();
            toast.success(res?.data);

            navigate("/signin", { replace: true });
        } catch (err) {
            const res = err?.response?.data;
            if (res?.error === "Validation failed" && res?.status === 400) {
                setError(res?.validationErrors[0]);
            } else if (res?.error === "Invalid Credentials" && res?.status === 400) {
                actions.resetForm();
                previous();
            } else {
                setError("unexpected error occurred. Please try again later.");
            }
        }
    };

    const handleTrimChange = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim();
        handleChange({
            target: { name, value: trimmedValue },
        });
    };

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            password: "",
            confirmationPassword: ""
        },
        validationSchema: resetPasswordSchema,
        onSubmit
    })


    return (
        <section className="reset-password__section">

            <h2 className="reset-password__heading">Reset Your Password</h2>

            <form autoComplete="off"
                onSubmit={handleSubmit}
                className="reset-password__form"
            >
                <div className="reset-password__input-box">
                    <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter new password"
                        value={values.password}
                        onChange={handleTrimChange}
                        onBlur={handleBlur}
                        required
                        autoComplete="off"
                        className="reset-password__input"
                    />

                    {errors.password && touched.password && (
                        <div className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation}/>
                            </span>
                            {errors.password}
                        </div>
                    )}
                </div>

                <div className="reset-password__input-box">
                    <input
                        id="confirmationPassword"
                        name="confirmationPassword"
                        type={showPassword ? "text" : "password"}
                        placeholder="confirm password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        autoComplete="off"
                        className="reset-password__input"
                    />

                    {errors.confirmationPassword && touched.confirmationPassword
                        && <div className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation}/>
                            </span>
                            {errors.confirmationPassword}</div>
                    }

                </div>

                <div className="reset-password__toggle">
                    <input id="togglePassword"
                           className="reset-password__checkbox"
                           type="checkbox"
                           onChange={() => setShowPassword(!showPassword)}
                           checked={showPassword}
                    />
                    <label htmlFor="togglePassword" className="reset-password__toggle-label">
                        {showPassword ? "Hide Password" : "Show Password"}
                    </label>
                </div>

                <div className="reset-password__button-wrapper">
                    <button className="reset-password__button"
                        type="submit"
                        disabled={isSubmitting ||
                            values.confirmationPassword !== values.password ||
                            !values.password
                        }
                    >{isSubmitting
                        ? <div className="loading-spinner"></div>
                        : "Change"
                    }</button>
                </div>
            </form>


            {error && (
                <div className="signup-email__error-message">
                    {error}
                </div>
            )}

        </section>
    )
}

export default ResetPassword;