import {
    ADD_SPACE,
    REMOVE_SPACE,
    UPDATE_SPACE,
    ADD_MEMBER,
    REMOVE_MEMBER,
    REMOVE_REQUEST,
    ADD_POST,
    REMOVE_POST,
    SHIFT_ADMIN,
    ADD_EMOJI,
    REMOVE_EMOJI,
    SET_MEMBER,
    SET_CURRENT_SPACE,
    SET_MEMBERS,
    SET_ACTIVITIES,
    SET_FEED,
    SET_MEMBER_POSTS,
    SET_EMOJIS,
    SET_POST_COUNT,
    SET_MEMBER_EMOJI,
    CLEAR_CURRENT_SPACE,
    SET_MEMBER_PAGE,
    SET_REQUESTS,
    ADD_POST_IN_FEED,
    ADD_POST_IN_MEMBER_PAGE,
    REMOVE_POST_IN_MEMBER_PAGE,
    REMOVE_POST_IN_SPACE, ADD_ACTIVITY
} from '../constants/spaceConstants';
import {LOGOUT, UPDATE_USER_DATA, UPDATE_USERNAME} from "../constants/authConstants";
import {DELETE_SPACE} from "../constants/userConstants";

const initialState = {
    currentSpaceId: null,
    spaces: {},
    members: {},
    posts: {},
    emojis: {}
}


const setMemberEmoji = (state, payload) => ({
    ...state,
    posts: {
        ...state.posts,
        [payload.postId]: {
            ...state.posts[payload.postId],
            memberEmoji: payload.emoji.emojiId
        }
    },
    emojis: {
        ...state.emojis,
        [payload.emoji.emojiId]: payload.emoji
    }
})

const setEmptyMemberEmoji = (state, payload) => ({
    ...state,
    posts: {
        ...state.posts,
        [payload.postId]: {
            ...state.posts[payload.postId],
            memberEmoji: null
        }
    },
})

const spacesReducer = (state = initialState, action) => {
    switch (action.type) {

        case LOGOUT: {
            return {
                ...state,
                currentSpaceId: null,
                spaces: {},
                members: {},
                posts: {},
                emojis: {}
            }
        }

        case SET_CURRENT_SPACE: {
            const spaceId = action.payload;
            return {
                ...state,
                currentSpaceId: spaceId
            };
        }

        case CLEAR_CURRENT_SPACE: {
            return {
                ...state,
                currentSpaceId: null
            };
        }

        case ADD_SPACE: {
            const { space, member } = action.payload;
            const {spaceId} = space;
            return {
                ...state,
                currentSpaceId: spaceId,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        spaceId: spaceId,
                        spaceData: space,
                        currentMember: member,
                        todayPosts: null,
                        feed: null,
                        members: [],
                        requests: [],
                        activities: {
                            page: null,
                            body: []
                        },
                        memberPages: {}
                    }
                },
                members: {
                    ...state.members,
                    [member.memberId]: member
                }
            };
        }

        case SET_MEMBERS: {
            const members = action.payload;
            const newMembers = members.reduce((acc, member) => ({
                ...acc,
                [member.memberId]: member
            }), state.members);
            const memberIds = members.map(member => member.memberId);
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [state.currentSpaceId]: {
                        ...state.spaces[state.currentSpaceId],
                        members: memberIds
                    }
                },
                members: newMembers
            };
        }

        case SET_POST_COUNT: {
            const count = action.payload;
            const spaceId = state.currentSpaceId;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        todayPosts: count
                    }
                }
            };
        }

        // check if res is null then we not set in posts and some changes 🚩
        case SET_FEED: {
            const posts = action.payload;
            const spaceId = state.currentSpaceId;

            const newPosts = posts.reduce((acc, post) => ({
                ...acc,
                [post.postId]: {...post, isEmojisFetched: false}
            }), state.posts)
            const postIds = posts.map(post => post.postId);

            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        feed: postIds
                    }
                },
                posts: newPosts
            };
        }

        // check if res is null then we not set in emojis
        case SET_MEMBER_EMOJI: {
            const {payload} = action;
            const {emoji} = payload;
            if(!emoji) {
                return setEmptyMemberEmoji(state, payload)
            }
            else {
                return setMemberEmoji(state, payload)
            }
        }

        case SET_ACTIVITIES: {
            const { page, body } = action.payload;
            const spaceId = state.currentSpaceId;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        activities: {
                            page: page,
                            body: [...state.spaces[spaceId].activities.body, ...body]
                        }
                    }
                }
            };
        }

        case SET_REQUESTS: {
            const requests = action.payload;
            const spaceId = state.currentSpaceId;
            return {
                ...state,
                spaces: {
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        requests: requests
                    }
                }
            }
        }

        case REMOVE_REQUEST: {
            const requestId = action.payload;
            const spaceId = state.currentSpaceId;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        requests: state.spaces[spaceId].requests.length>1
                            ? state.spaces[spaceId].requests
                                .filter(request => request.requestId !== requestId)
                            : null
                    }
                }
            };
        }

        // before store in members check if members is existing on members on space
        case ADD_MEMBER: {
            const { member, activity } = action.payload;
            const spaceId = state.currentSpaceId;
            const space = state.spaces[spaceId];

            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...space,
                        members: space.members?.length
                            ? [member.memberId, ...space.members]
                            : [],
                        activities: {
                            ...space.activities,
                            body: [activity, ...space.activities.body]
                        }
                    }
                },
                members: {
                    ...state.members,
                    [member.memberId]: member
                }
            };
        }

        case SET_MEMBER: {
            const member = action.payload;
            return {
                ...state,
                members: {
                    ...state.members,
                    [member.memberId]: member
                }
            }
        }

        case SET_MEMBER_PAGE: {
            const member = action.payload;
            const spaceId = state.currentSpaceId;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        memberPages: {
                            ...state.spaces[spaceId].memberPages,
                            [member.member]: member
                        }
                    }
                }
            };
        }

        case SET_MEMBER_POSTS: {
            const { posts, memberId, page } = action.payload;
            const spaceId = state.currentSpaceId;

            // Create a new posts object that contains all existing posts
            const updatedPosts = { ...state.posts };

            // Iterate over each post in the payload
            posts.forEach(post => {
                // Only add the post if it doesn't already exist in the state
                if (!updatedPosts[post.postId]) {
                    updatedPosts[post.postId] = {
                        ...post,
                        isEmojisFetched: false // or you can omit this if you don't want it initially
                    };
                }
            });

            // Create the array of postIds to add to the memberPages
            const postIds = posts.map(post => post.postId);

            return {
                ...state,
                posts: updatedPosts,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        memberPages: {
                            ...state.spaces[spaceId].memberPages,
                            [memberId]: {
                                ...state.spaces[spaceId].memberPages[memberId],
                                page: page,
                                posts: [...state.spaces[spaceId].memberPages[memberId].posts, ...postIds]
                            }
                        }
                    }
                }
            };
        }

        case SET_EMOJIS: {
            const {emojis, postId} = action.payload;
            if(!emojis) {
                return {
                    ...state,
                    posts: {
                        ...state.posts,
                        [postId] : {...state.posts[postId], isEmojisFetched: true}
                    }
                }
            }
            return {
                ...state,
                posts: {
                    ...state.posts,
                    [postId] : {...state.posts[postId], isEmojisFetched: true}
                },
                emojis: emojis.reduce((acc, emoji) => ({
                    ...acc,
                    [emoji.emojiId]: emoji
                }), state.emojis)
            };
        }

        case ADD_POST: {
            const { spaceId, postId } = action.payload;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        todayPosts: state.spaces[spaceId].todayPosts + 1
                    }
                },
                posts: {
                    ...state.posts,
                    [postId]: { ...action.payload, isEmojisFetched: true, memberEmoji: null }
                }
            };
        }

        case ADD_POST_IN_FEED: {
            const { spaceId, postId } = action.payload;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        feed: [postId, ...state.spaces[spaceId].feed]
                    }
                }
            };
        }

        case ADD_POST_IN_MEMBER_PAGE: {
            const { spaceId, postId, memberId } = action.payload;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        memberPages: {
                            ...state.spaces[spaceId].memberPages,
                            [memberId]: {
                                ...state.spaces[spaceId].memberPages[memberId],
                                posts: [postId, ...state.spaces[spaceId].memberPages[memberId].posts]
                            }
                        }
                    }
                }
            };
        }

        case REMOVE_POST_IN_MEMBER_PAGE: {
            const { currentSpaceId, postId, memberId } = action.payload;
            const memberPage = state.spaces[currentSpaceId]?.memberPages[memberId];
            if (!memberPage) return state;

            const updatedPosts = memberPage.posts.filter(id => id !== postId);
            return {
                ...state,
                spaces: {
                    [currentSpaceId]: {
                        ...state.spaces[currentSpaceId],
                        memberPages: {
                            ...state.spaces[currentSpaceId].memberPages,
                            [memberId]: {
                                ...memberPage,
                                posts: updatedPosts
                            }
                        }
                    }
                }
            };
        }

        case REMOVE_POST_IN_SPACE: {
            const postId = action.payload;
            const space = state.spaces[state.currentSpaceId];
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [state.currentSpaceId]: {
                        ...state.spaces[state.currentSpaceId],
                        todayPosts: space.todayPosts !== null ? space.todayPosts - 1 : null,
                        feed: space.feed ? space.feed?.filter(id => id !== postId) : null
                    }
                }
            };
        }

        case REMOVE_POST: {
            const postId = action.payload;
            const { [postId]: removedPost, ...remainingPosts } = state.posts;
            const updatedEmojis = Object.keys(state.emojis)
                .filter(emojiId => state.emojis[emojiId].postId !== postId)
                .reduce((acc, emojiId) => ({
                    ...acc,
                    [emojiId]: state.emojis[emojiId]
                }), {});
            return {
                ...state,
                posts: remainingPosts,
                emojis: updatedEmojis
            };
        }

        // add in emojis and change member emoji in that post
        case ADD_EMOJI: {
            const { postId, emoji } = action.payload;
            return {
                ...state,
                posts: {
                    ...state.posts,
                    [postId]: {...state.posts[postId], memberEmoji: emoji.emojiId}
                },
                emojis: {
                    ...state.emojis,
                    [emoji.emojiId]: emoji
                },
            };
        }

        // remove on emojis and change to member emojis in that post
        case REMOVE_EMOJI: {
            const { postId, emojiId } = action.payload;
            const {[emojiId]:removedEmoji, ...remainingEmojis} = state.emojis
            return {
                ...state,
                posts: {
                    ...state.posts,
                    [postId]: {...state.posts[postId], memberEmoji: null}
                },
                emojis: remainingEmojis
            };
        }


        case DELETE_SPACE:
        case REMOVE_SPACE: {
            const spaceId = action.payload;

            if(!state.spaces[spaceId])
                return state;

            // Remove the space
            const { [spaceId]: removedSpace, ...restSpaces } = state.spaces;

            // Remove posts related to the spaceId
            const postsBySpaceId = Object?.keys(state.posts)?.filter(postId => state.posts[postId].spaceId === spaceId);
            const postsWithoutSpace = Object?.keys(state.posts).reduce((acc, postId) => {
                if (!postsBySpaceId.includes(postId)) {
                    acc[postId] = state.posts[postId];
                }
                return acc;
            }, {});

            // Remove emojis related to the posts of the spaceId
            const emojisByPosts = Object?.keys(state.emojis)?.filter(emojiId => !postsBySpaceId?.includes(state.emojis[emojiId]?.postId));
            const emojisWithoutPosts = Object?.keys(state.emojis).reduce((acc, emojiId) => {
                if (!emojisByPosts.includes(emojiId)) {
                    acc[emojiId] = state.emojis[emojiId];
                }
                return acc;
            }, {});

            // Remove members related to the spaceId
            const membersWithoutSpace = Object?.keys(state.members).reduce((acc, memberId) => {
                const member = state.members[memberId]
                if (member.spaceId !== spaceId) {
                    acc[memberId] = state.members[memberId];
                }
                return acc;
            }, {});

            return {
                ...state,
                currentSpaceId: null,
                spaces: restSpaces,
                posts: postsWithoutSpace,
                emojis: emojisWithoutPosts,
                members: membersWithoutSpace
            };
        }


        case UPDATE_SPACE: {
            const { spaceId:id, spacename } = action.payload;
            const updatedSpaces = Object.keys(state.spaces).reduce((acc, spaceId) => {
                const space = state.spaces[spaceId];
                if(spaceId === id.toString()) {
                    acc[spaceId] = {
                        ...space,
                        spaceData: {...space.spaceData, spacename}
                    }
                } else {
                    acc[spaceId] = space;
                }
                return acc;
            }, {});
            return {
                ...state,
                spaces: updatedSpaces || {}
            }
        }

        case REMOVE_MEMBER: {
            const { memberId, spaceId } = action.payload;
            const space = state.spaces[spaceId];

            const { [memberId]: removedMember, ...remainingMembers } = state.members;
            const { [memberId]: removedMemberPage, ...remainingMemberPage } = space.memberPages;

            // Get the postIds associated with the removed member
            const postsToRemove = Object.values(state.posts)
                .filter(post => post.memberId === memberId)
                .map(post => post.postId);

            // Remove the posts from the state
            const updatedPosts = Object.keys(state.posts).reduce((acc, key) => {
                if (!postsToRemove.includes(parseInt(key))) {
                    acc[key] = state.posts[key];
                }
                return acc;
            }, {});

            // Remove emojis associated with the removed member's posts or the member themselves
            const updatedEmojis = Object.keys(state.emojis).reduce((acc, emojiId) => {
                const emoji = state.emojis[emojiId];
                if (emoji.emojiMember !== memberId && !postsToRemove.includes(emoji.postId)) {
                    acc[emojiId] = emoji;
                }
                return acc;
            }, {});

            // Update the space feed and members
            const updatedFeed = !!space?.feed ? space.feed.filter(postId => !postsToRemove.includes(postId)) : null;
            const updatedMembers = !!space?.members ? space.members.filter(m => m !== memberId) : null;

            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...space,
                        feed: updatedFeed,
                        memberPages: remainingMemberPage,
                        members: updatedMembers,
                    },
                },
                members: remainingMembers,
                posts: updatedPosts,
                emojis: updatedEmojis
            };
        }


        case ADD_ACTIVITY: {
            const activity = action.payload;
            const spaceId = state.currentSpaceId;
            const space = state.spaces[spaceId];

            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...space,
                        activities: {
                            ...space.activities,
                            body: [activity, ...space.activities.body],
                        }
                    },
                },
            }
        }


        case SHIFT_ADMIN: {
            const { spaceId, currentMemberId, user, targetUserId } = action.payload;
            return {
                ...state,
                spaces: {
                    ...state.spaces,
                    [spaceId]: {
                        ...state.spaces[spaceId],
                        spaceData: {
                            ...state.spaces[spaceId].spaceData,
                            admin: user
                        },
                        currentMember: {
                            ...state.spaces[spaceId].currentMember,
                            admin: false
                        },
                        requests: [],
                    }
                },
                members: {
                    ...state.members,
                    [currentMemberId]: {...state.members[currentMemberId], admin:false},
                    [targetUserId]: {...state.members[targetUserId], admin:true}
                }
            };
        }
        case UPDATE_USERNAME:
        case UPDATE_USER_DATA: {
            const user = action.payload.user;
            const updatedMembers = Object.keys(state.members).reduce((acc, memberId) => {
                const member = state.members[memberId];
                if (member.user.userId === user.userId) {
                    acc[memberId] = {
                        ...member,
                        user: user
                    };
                } else {
                    acc[memberId] = member;
                }
                return acc;
            }, {});
            const updatedSpaces = Object.keys(state.spaces).reduce((acc, spaceId) => {
                const space = state.spaces[spaceId];
                return {
                    ...acc,
                    [spaceId]: {
                        ...space,
                        currentMember: {...space.currentMember, user: user}
                    }
                };
            }, {});

            return {
                ...state,
                members: updatedMembers,
                spaces: updatedSpaces
            };
        }

        default:
            return state;
    }
};

export default spacesReducer;