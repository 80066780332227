import { useState } from "react";

const DisplayImage = ({ image, altText, aspectRatio, className }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const handleLoad = () => {
        setLoading(false);
    }

    const handleError = () => {
        setLoading(false);
        setError(true);
    }

    return (
        <div>
            {loading && !error && (
                <div className="image-event">
                    <div className="spinner"></div>
                </div>
            )}

            {!error ? (
                <img
                    src={image}
                    alt={altText}
                    onLoad={handleLoad}
                    onError={handleError}
                    style={{ display: loading ? 'none' : 'block', aspectRatio }}
                    className={className}
                />
            ) : (
                <div className="image-event">
                    <p>Failed to load image</p>
                </div>
            )}
        </div>
    );
}

export default DisplayImage;
