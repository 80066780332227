import {useCallback, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {API} from "../service/api/utils";
import {useSelector} from "react-redux";

// Todo: This will also update before do after auth
// Todo: We also change this for updating email and we want know user valid email
// Todo: This will do after token management.
function NewEmailVerify() {
    const {userData} = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");


    const handleVerify = useCallback(() => {
        setLoading(true);
        const request = new FormData();
        request.append("code", code)
        API.post("/users/verify-email", request)
            .then(() => {
                // update the email on user to entire application
                navigate("../", { replace: true })
            })
            .catch(() => {
                setError(true)
            });
        setLoading(false);
    }, [setLoading, navigate])

    useEffect(() => {
        if (code) {
            handleVerify();
        } else {
            setError(true);
        }
    }, [code, handleVerify]);

    return (
        <section className="change-email">
            {loading && <p>Loading...</p>}
            {error && (
                <div className="invalid-link">
                    <p>Invalid Link Back to
                        <span>
                            <Link to="/settings" replace={true}>
                                Home
                            </Link>
                        </span>
                    </p>
                </div>
            )}
        </section>
    )

}

export default NewEmailVerify;