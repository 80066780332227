import {memo, useEffect, useState} from "react";
import { useInView } from 'react-intersection-observer';
import SuggestedUser from "./SuggestedUser";
import {useDispatch, useSelector} from "react-redux";
import {getSuggestedUsersAction, updateSuggestedUsers} from "../../service/actions/userActions";
import ResponseLoading from "../loader/ResponseLoading";
import {FriendSuggestionModal} from "../modal/FriendSuggestionModal";
import {ButtonLoading} from "../loader/ButtonLoading";


const AddFriend = () => {
    const dispatch = useDispatch();
    const {page, users} = useSelector(state => state.user)?.suggestedUsers || {};

    const [loading, setLoading] = useState(false);
    const { ref, inView } = useInView({threshold: 0.9});

    useEffect(() => {
        const fetchSuggestedUsers = async () => {
            if (!page || (page?.hasNext && inView && !loading)) {
                setLoading(true);
                const fetchPage = page ? page.currentPage + 1 : 0;
                await dispatch(getSuggestedUsersAction(fetchPage));
                setLoading(false);
            }
        }
        fetchSuggestedUsers();
        return () => {
            dispatch(updateSuggestedUsers());
        }
    }, [inView, page, dispatch]);


    // Initial Loading...
    if(!page && loading) {
        return <ResponseLoading />
    }


    return (
        <div className="suggest-users">
            {users?.length !== 0
                ? (users?.map((request, index) => (
                    <div
                        key={index}
                        ref={users.length === index + 1 ? ref : null}
                    >
                        <SuggestedUser request={request} />
                    </div>
                )))
                : <FriendSuggestionModal />
            }
            <div className="suggested-users_fetching">
              {loading && <ButtonLoading size={"13px"} /> }
            </div>
        </div>
    )
}

export default memo(AddFriend);

// Todo: Every render it updates if the suggested users or not added line 28. <Completed>