import React, {useState, useEffect} from "react";
import {Provider} from "react-redux";
import createAppStore from "./service/store";
import App from "./App";
import LandingPage from "./pages/LandingPage";
import {ErrorComponent} from "./components/loader/ErrorComponent";


const AppContainer = () => {
    const [store, setStore] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const oldUser = (localStorage.getItem("initialize") === "true");

    useEffect(() => {
        const initializeStore = async () => {
            try {
                const appStore = await createAppStore();
                setStore(appStore);
            } catch (err) {
                setError(`Error initializing the app: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };
        initializeStore();
    }, []);


    if (loading) {
        return (
            <div className="app-loading-title">
                <h1>InSpace</h1>
            </div>
        );
    }

    if (error) {
        return <ErrorComponent errorMessage={error} />;
    }

    if (!oldUser) {
        return <LandingPage />;
    }

    return (
        <Provider store={store}>
            <App />
        </Provider>
    );
};

export default AppContainer;