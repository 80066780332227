import {memo, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {getUserSpacesAction} from "../service/actions/userActions";
import ResponseLoading from "../components/loader/ResponseLoading";
import {SPACES_ERROR} from "../service/constants/userConstants";

import Space from "../components/main/Space";
const MemoizedSpace = memo(Space)


export default function Spaces() {
    const dispatch = useDispatch();
    const {userSpaces, spaces} = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const {errors} = useSelector(state => state.app)
    const spacesError = errors["spaces"]?.error === SPACES_ERROR || false;

    useEffect(() => {
        setLoading(true);
        dispatch(getUserSpacesAction())
            .finally(() => setLoading(false));
    }, [dispatch])


    return (
        <section className="spaces-page">
            <div className="spaces">
                {spacesError ? <div>unable to get the spaces</div>
                    : userSpaces?.length > 0 &&
                    (userSpaces.map(userSpace => {
                        const space = spaces[userSpace.spaceId];
                        return (
                            <MemoizedSpace
                                userSpace={{
                                    ...space,
                                    members:userSpace.members,
                                    admin:userSpace.admin
                                }}
                                key={space.spaceId}
                            />
                        )}
                    ))
                }
            </div>

            {loading && (
                <ResponseLoading />
            )}

            <Link to="../space/create" className="add-space">
                <FontAwesomeIcon icon={faPlus} />
            </Link>
        </section>
    )
}
// Todo: there is a glitch happened on first render to this component and having spaces in redux
// Todo: space are retrieve again after the create space succeed