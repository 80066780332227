import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {unFriendAction} from "../../service/actions/userActions";


const UnFriendModal = ({user, friendsId, onClose, dispatch}) => {
    const [loading, setLoading] = useState(false);

    const handleUnFriend = async () => {
        let {userId, username} = user;
        setLoading(true);
        await dispatch(unFriendAction(userId, friendsId, username));
        setLoading(false);
        onClose();
    }

    return (
        <div className="modal-card">
            <button className="modal-close" onClick={onClose}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
            <div className="modal-content">
                Would like to unfriend with {user?.username}
            </div>
            <button className="modal-confirm" onClick={handleUnFriend}>
                {loading ? "loading": "I like to unfriend"}
            </button>
        </div>
    )
}

export default UnFriendModal;