import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

export const BASEURL = "http://inspaces.link/backend";

export const listOfDates = (lastPost) => {
    const lastPostDate = new Date(lastPost);
    lastPostDate.setHours(0, 0, 0, 0);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    let list = [];
    let organizedDates = {};


    for (currentDate; currentDate >= lastPostDate; currentDate.setDate(currentDate.getDate() - 1)) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const day = currentDate.getDate();
        const formatedDate =
            year + "-" + (month < 9 ? '0' : '') + (month + 1) + "-" + (day < 10 ? '0' : '') + day;
        list.push(formatedDate);
    }

    list.forEach(dateString => {
        let yearMonth = formDate(dateString);

        if(!organizedDates[yearMonth]) organizedDates[yearMonth] = [];
        organizedDates[yearMonth].push(dateString);
    })
    return organizedDates;
}

function formDate(dateString) {
    let date = new Date(dateString);
    let options = { month: 'long', year: 'numeric' };

    return date.toLocaleDateString('en-US', options);
}

export const isSharedToday = (sharedOn) => {
    const sharedDate = new Date(sharedOn);
    const now = new Date();
    const startOfToday = new Date(now.setHours(0, 0, 0, 0));
    const endOfToday = new Date(now.setHours(23, 59, 59, 999));
    return sharedDate >= startOfToday && sharedDate <= endOfToday;
};

// TODO: Better emojis
export const useDisplayEmojis = ({ showEmojis, setShowEmojis, onChange }) => {

    if(showEmojis) {
        return (
            <div className="list-emojis">
                <button onClick={() => onChange("WISHES")}>
                    😴
                </button>
                <button onClick={() => onChange("ANGER")}>
                    👍
                </button>
                <button onClick={() => onChange("LAUGH_TEAR")}>
                    🤣
                </button>
                <button onClick={() => onChange("LIKE")}>
                    😍
                </button>
                <button onClick={() => onChange("SMILE")}>
                    😁
                </button>
                <button onClick={() => setShowEmojis(false)} className="emoji-icon">
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </div>
        )
    } else {
        return (
            <button
                onClick={() => setShowEmojis(true)}
                className="select-emoji"
            >
                😊
            </button>
        )
    }
}

export const useFilterPostEmojiByType = (type, postEmojis) => {
    const filter = postEmojis?.filter(emoji => emoji.emojis === type);
    if(filter?.length === 0)
        return [];
    return filter;
}

export const convertDateFormat = (dateStr) => {
    const [month, year] = dateStr?.split(" ");
    const monthNumber = new Date(`${month} 1, ${year}`).getMonth() + 1;
    const formattedMonth = monthNumber.toString().padStart(2, "0");
    return `${year}-${formattedMonth}`;
}

export const emojisInput = {
    LIKE: '😍',
    SMILE: '😁',
    ANGER: '👍',
    LAUGH_TEAR: '🤣',
    WISHES: '😴'
}