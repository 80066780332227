import {useDispatch} from "react-redux";
import {leaveSpaceAction} from "../../service/actions/spaceActions";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {toast} from "sonner";


const LeaveSpaceModal = ({onClose, spaceId, spacename, memberId, feedback, navigate}) => {
    const [loading, setLoading] = useState(false);
    const [inputUsername, setInputUsername] = useState("");
    const dispatch = useDispatch();

    const handleLeaveAction = async () => {
        if(!feedback) {
            toast.error("Please give the reason for leave");
        } else if(inputUsername !== spacename) {
            toast.error("Your input of spaceId is invalid.")
        } else {
            setLoading(true)
            await dispatch(leaveSpaceAction(memberId, feedback, spaceId, navigate, onClose));
            setLoading(false);
        }
    }


    return (
        <div className="modal-card">
            <button
                onClick={() => onClose()}
                disabled={loading}
                className="modal-close"
            >
                <FontAwesomeIcon icon={faXmark}/>
            </button>
            <div className="modal-content">
                Want to Leave this space?
                before go head enter the {spacename} in below input.
                <input
                    type="text"
                    onChange={e => setInputUsername(e.target.value)}
                    placeholder="Enter your spaceId"
                    value={inputUsername}
                />
            </div>
            <button
                disabled={loading || inputUsername!==spacename}
                onClick={handleLeaveAction}
                className="modal-confirm"
            >
                {loading ? "Leaving.." : "Leave"}
            </button>
        </div>
    )
}

export default LeaveSpaceModal;