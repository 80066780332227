
const getActionLoadings = (state) => state.app?.actionLoading;
const getHistory = (state) => state.app?.history;


export const getLoadingState = (state, key, value) => {
    const loadings = getActionLoadings(state);
    return (loadings[key] && loadings[key] === value) || false;
}

export const getCurrentLocation = (state) => {
    const history = getHistory(state);
    return history[history.length-1];
}