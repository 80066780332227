import {useMemo, useEffect} from "react";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setInitialAuthState} from "./service/actions/authActions";
import {pushHistoryAction} from "./service/actions/appActions";

const PrivateRoute = () => {
    const {userData} = useSelector(state => state.auth);
    const location = useLocation();

    const checkAuth = useMemo(() => {
        return (userData, accessToken) => {
            return userData && accessToken;
        };
    }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("profile");
    const accessToken = JSON.parse(token)?.access_token;

    useEffect(() => {
        if (!checkAuth(userData, accessToken)) {
            localStorage.removeItem("profile");
            dispatch(setInitialAuthState(navigate));
        }
        else {
            const url = location.pathname;
            if(url.startsWith("/profile") && url.endsWith("/friends")) return;
            dispatch(pushHistoryAction(location));
        }
    }, [location, navigate, accessToken, userData]);


    return (
        checkAuth(userData, accessToken)
            ? <Outlet /> :  <Navigate to="/signin" />
    );
};

export default PrivateRoute;