import * as yup from 'yup';

const username = /^(?=.*[a-zA-Z])[a-zA-Z0-9._]{6,20}$/;
const password = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;
const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const name = /^[a-zA-Z0-9_\s]{5,15}$/;

export const usernameSchema = yup.object().shape({
    username: yup.string()
        .trim()
        .required('Username is required')
        .min(6, 'Username must be at least 6 characters')
        .max(20, 'Username must be at most 20 characters')
        .matches(username, {message: "Username must have at least one letter and can include letters, numbers, dots, or underscores"}),
})

export const nameSchema = yup.object().shape({
    name: yup.string()
        .trim()
        .min(5, "Name must be at least 5 characters")
        .max(25, "Maximum exceeded")
        .required("Required"),
})

export const statusSchema = yup.object().shape({
    status: yup.string()
        .trim()
        .min(5, "status must be at least 5 characters")
        .max(100, "Maximum exceeded")
        .required("Required")
})

export const signInSchema = yup.object().shape({
    username: yup.string()
        .trim()
        .required('Username is required')
        .min(6, 'Username must be at least 6 characters')
        .max(20, 'Username must be at most 20 characters')
        .matches(username, {message: "Username must have at least one letter and can include letters, numbers, dots, or underscores"}),
    password: yup.string()
        .trim()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"})
})

export const emailSchema = yup.object().shape({
    email: yup.string()
        .email("Please enter a valid email")
        .matches(email, {message: "Please enter a valid email" })
        .required("Required"),
})

export const signupVerification = yup.object().shape({
    code: yup.string()
        .matches(/^\d{7}$/, {message: "Must be exactly 7 digits"})
        .required('This field is required')
});

export const passwordSchema = yup.object().shape({
    password: yup.string()
        .trim()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"})
})


// Todo: We focus later on name
// Todo: use trim properly
export const signUpSchema = yup.object().shape({
    email: yup.string()
        .email("Please enter a valid email")
        .matches(email, {message: "Please enter a valid email" })
        .required("Required"),
    username: yup.string()
        .trim()
        .required('Username is required')
        .min(6, 'Username must be at least 6 characters')
        .max(20, 'Username must be at most 20 characters')
        .matches(username, {message: "Username must have at least one letter and can include letters, numbers, dots, or underscores"}),
    name: yup.string()
        .trim()
        .min(5, "Name must be at least 5 characters")
        .max(15, "Maximum exceeded")
        .matches(name, {message: "Name can only contain alphanumeric characters, spaces, and underscores, and must be between 5 and 15 characters long."})
        .required("Required"),
    password: yup.string()
        .trim()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"})
})

export const editSpaceSchema =  yup.object().shape({
    username: yup.string()
        .trim()
        .required('Username is required')
        .min(6, 'Username must be at least 6 characters')
        .max(20, 'Username must be at most 20 characters')
        .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9._]{6,20}$/, {message: "Username must have at least one letter and can include letters, numbers, dots, or underscores"}),
    name: yup.string()
        .trim()
        .min(5, "Name must be at least 5 characters")
        .max(15, "Maximum exceeded")
        .required("Required"),
    status: yup.string()
        .trim()
        .min(5, "status must be at least 5 characters")
        .max(15, "Maximum exceeded")
        .required("Required")
})

export const resetPasswordSchema = yup.object().shape({
    password: yup.string()
        .trim()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"}),
    confirmationPassword: yup.string()
        .trim()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"})
        .oneOf([yup.ref("password"),null], "Passwords must match"),
})


export const changePasswordSchema = yup.object().shape({
    currentPassword: yup.string()
        .trim()
        .required('Current Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"}),
    newPassword: yup.string()
        .trim()
        .required('New Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"}),
    confirmationPassword: yup.string()
        .trim()
        .required('Confirm Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(50, 'Password must be at most 50 characters')
        .matches(password, {message: "Password must contain at least one digit, one lowercase letter and one uppercase letter"})
        .oneOf([yup.ref("newPassword"),null], "Passwords must match"),
})