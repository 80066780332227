import { Fragment } from "react";
import MainHeader2 from "./MainHeader2";
import { Outlet } from "react-router-dom";

function MainLayout2() {

  return (
    <Fragment>
      <MainHeader2 />
      <Outlet />
    </Fragment>
  )
}

export default MainLayout2;