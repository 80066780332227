import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import rootReducer from "./reducers/root";
import { tokenMiddleware } from "../middlewares/tokenMiddleware";
import { initializeAuth } from "./actions/authActions";


let store;
const createAppStore = async () => {
    try {
         store = configureStore({
            reducer: rootReducer,
            middleware: (getDefaultMiddleware) =>
                getDefaultMiddleware().concat(thunk, tokenMiddleware),
        });

        await store.dispatch(initializeAuth());
        return store;
    } catch (err) {
        throw new Error(err.message);
    }
};

export default createAppStore;

export const getStore = async () => {
    return await store;
};