import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {toast} from "sonner";
import {removeMemberAction} from "../../service/actions/spaceActions";


const RemoveMemberModal = ({memberId, onClose, dispatch}) => {
    const [feedback, setFeedback] = useState("");
    const [loading, setLoading] = useState(false);
    const feedbackRegex = /^[a-zA-Z0-9\s.,!?'"()\-]*$/;


    const handleSubmit = async () => {
        if(feedback.length < 50) {
            toast.error("Please enter at least 50 characters");
        } else if(!feedbackRegex.test(feedback)) {
            toast.error("Your feedback contains invalid characters");
        } else {
            setLoading(true)
            await dispatch(removeMemberAction(memberId, feedback, onClose));
            setLoading(false);
        }
    }


    return (
        <div className="modal-card">
            <button className="modal-close" onClick={onClose}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
            <div className="modal-content">
                Some text related to remove member from space
                <textarea
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                    maxLength="100"
                    rows="8"
                    style={{width: '100%', padding: '10px', boxSizing: 'border-box'}}
                    placeholder="Enter your feedback here..."
                />
                <div style={{marginTop: '10px', fontSize: '14px', color: '#888'}}>
                    Note: Your feedback should be concise and informative. Maximum 100 characters allowed.
                </div>
                <div style={{marginTop: '5px', fontSize: '12px', color: '#666'}}>
                    {feedback.length}/100 characters used.
                </div>
            </div>
            <button
                className="modal-confirm"
                onClick={handleSubmit}
                disabled={loading || !feedback}
            >
                {loading ? "loading" : "I like to remove the member"}
            </button>
        </div>
    )
}

export default RemoveMemberModal;