import Request from "./Request";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSpaceRequestsAction} from "../../service/actions/spaceActions";
import {getRequestsInSpace} from "../../service/selectors/spaceSelectors";
import {ButtonLoading} from "../loader/ButtonLoading";
import {REQUESTS_FAILED} from "../../service/constants/spaceConstants";

function SpaceRequests({spaceId, loading, setLoading}) {
    const dispatch = useDispatch();
    const requests = useSelector(state => getRequestsInSpace(state, spaceId))
    const {errors} = useSelector(state => state.app);
    const requestError = errors[spaceId]?.error === REQUESTS_FAILED || false;

    useEffect(() => {
        const fetchRequests = async () => {
            if(requestError) return;
            if(requests !== null && requests?.length === 0 && !loading) {
                setLoading(true);
                await dispatch(getSpaceRequestsAction(spaceId));
                setLoading(false);
            }
        }
        fetchRequests();
    }, [dispatch, requests]);


    return (
        <div className="request-dropdown-menu">
            {loading
              ? (
                  <div className="request-load">
                    <ButtonLoading size={"17px"} />
                  </div>
              )
              : requestError
                  ? (
                      <div className="no-request-found">
                          Unable to retrieve the space requests
                      </div>
                  )

              : (requests ? requests?.map
                  (request =>
                      <Request request={request} key={request.requestId} />
                  ) : <p className="no-request-found">No Requests Found</p>
              )
            }
      </div>
    )
}

export default SpaceRequests;