import {
    getActivities,
    getCurrentSpace,
    getMemberById,
    getSpaceMembers,
    getSpaceRequests,
    handleSpaceRequest,
    leaveSpace,
    removeMember,
    shiftAdmin
} from "../api/spaceAPI";
import {
    ADD_ACTIVITY,
    ADD_EMOJI,
    ADD_MEMBER,
    ADD_POST,
    ADD_POST_IN_FEED,
    ADD_POST_IN_MEMBER_PAGE,
    ADD_SPACE,
    CLEAR_CURRENT_SPACE,
    MEMBER_EMOJI_FAILED,
    MEMBER_PAGE_ERROR, MEMBER_POST_ERROR,
    POST_COUNT_ERROR,
    POST_EMOJIS_ERROR,
    REMOVE_EMOJI,
    REMOVE_MEMBER,
    REMOVE_POST,
    REMOVE_POST_IN_MEMBER_PAGE,
    REMOVE_POST_IN_SPACE,
    REMOVE_REQUEST,
    REMOVE_SPACE, REQUESTS_FAILED,
    SET_ACTIVITIES,
    SET_CURRENT_SPACE,
    SET_EMOJIS,
    SET_FEED,
    SET_MEMBER,
    SET_MEMBER_EMOJI,
    SET_MEMBER_PAGE,
    SET_MEMBER_POSTS,
    SET_MEMBERS,
    SET_POST_COUNT,
    SET_REQUESTS,
    SHIFT_ADMIN,
    SPACE_ERROR
} from "../constants/spaceConstants";
import {
    addEmoji,
    deletePost,
    getFeed,
    getMemberEmoji,
    getMemberPosts,
    getPostCount,
    getPostEmojis,
    removeEmoji,
    uploadPost
} from "../api/postAPI";
import {isSharedToday} from "../../utils";
import {toast} from "sonner";
import {setError} from "./appActions";


// Todo: Duplicate keys with same error.
export const clearCurrentSpaceIdAction = () => async (dispatch) => {
    dispatch({ type: CLEAR_CURRENT_SPACE });
};


// Todo: Over
export const addSpaceAction = (targetId, navigate) => async (dispatch, getState) => {
    const {spaces} = getState().space;
    const space = Object.values(spaces).find(space =>
        space.spaceData.uniqueId === targetId
    );
    if (space) {
        dispatch({type:SET_CURRENT_SPACE, payload: space.spaceId})
        return;
    }

    try {
        const { error, data } = await getCurrentSpace(targetId);
        if (error) {
            if(error?.status === 404) {
                toast.error(targetId + " space not found");
                navigate("/not-found", {replace: true})
            }
            else if(error?.status === 403) {
                toast.error("You does not have permission to access " + targetId + " space.")
                navigate("/access-denied", {replace:true})
            }
            else {
                dispatch(setError(targetId, SPACE_ERROR));
            }
        }
        else {
            const {admin, spaceId, spacename, uniqueId, member} = data;
            dispatch({
                type: ADD_SPACE,
                payload: {space: {admin, spaceId, spacename, uniqueId}, member},
                meta: {
                    requiresAuth: true
                }
            });
        }
    } catch (error) {
        dispatch(setError(targetId, SPACE_ERROR));
    }
};


// Todo: Over
export const getSpaceMembersAction = () => async (dispatch, getState) => {
    const { spaces, currentSpaceId } = getState().space;
    if (spaces[currentSpaceId]?.members.length !== 0) return;
    try {
        const { error, data } = await getSpaceMembers(currentSpaceId);
        if (error) {
            dispatch(setError(currentSpaceId, SPACE_ERROR));
            return;
        }
        await dispatch({
            type: SET_MEMBERS,
            payload: data,
            meta: {
                requiresAuth: true
            }
        });
    } catch (error) {
        dispatch(setError(currentSpaceId, SPACE_ERROR));
    }
};


// Todo: Over
export const getPostsCountAction = (spaceId) => async (dispatch, getState) => {
    const { spaces } = getState().space;
    if (spaces[spaceId]?.todayPosts !== null) return;

    try {
        const {error, data} = await getPostCount();
        if (error) {
            dispatch(setError(spaceId, POST_COUNT_ERROR));
            return;
        }
        dispatch({
            type: SET_POST_COUNT,
            payload: data,
            meta: {
                requiresAuth: true
            }
        })
    } catch (error) {
        dispatch(setError(spaceId, POST_COUNT_ERROR));
    }
};


// Todo: Over
export const getFeedAction = (spaceId) => async (dispatch, getState) => {
    const { spaces } = getState().space;
    if (spaces[spaceId]?.feed !== null) return;
    try {
        const {error, data} = await getFeed(spaceId);
        if (error) {
            dispatch(setError(spaceId, SPACE_ERROR));
            return;
        }
        dispatch({
            type: SET_FEED,
            payload: data,
            meta: {
                requiresAuth: true
            }
        });
    } catch (error) {
        dispatch(setError(spaceId, SPACE_ERROR));
    }
};


// Todo: Over
export const getActivityAction = (fetchPage, spaceId) => async (dispatch, getState) => {
    const { spaces } = getState().space;
    const page = spaces[spaceId]?.activities?.page;
    if (page && fetchPage === page.totalPages) return;


    try {
        const {error, data} = await getActivities({ pageParam: fetchPage, spaceId });
        if (error) {
            toast.error(error);
            return;
        }
        let { content, currentPage, totalElements, totalPages, first, last, hasNext, hasPrevious } = data;
        dispatch({
            type: SET_ACTIVITIES,
            payload: {
                page: {
                    currentPage,
                    totalElements,
                    totalPages,
                    first,
                    last,
                    hasNext,
                    hasPrevious
                },
                body: content
            },
            meta: {
                requiresAuth: true
            }
        })
    } catch (error) {
        toast.error("Unexpected error occurred. Please try again later.");
    }
};


// Todo: Over
export const getSpaceRequestsAction = (spaceId) => async (dispatch) => {
    try {
        const { error, data } = await getSpaceRequests();
        if (error) {
            dispatch(setError(spaceId, REQUESTS_FAILED));
            return;
        }
        dispatch({
            type: SET_REQUESTS,
            payload: data.length ? data : null,
            meta: {
                requiresAuth: true
            }
        })
    } catch (error) {
        dispatch(setError(spaceId, REQUESTS_FAILED));
    }
};


// Todo: Over
export const getMemberPageAction = (memberId) => async (dispatch, getState) => {
    const {currentSpaceId, spaces, members} = getState().space;
    if (spaces[currentSpaceId]?.memberPages[memberId]) return;
    if(members[memberId]) {
        const payload = {member:memberId, posts:[], page:null}
        await dispatch({type: SET_MEMBER_PAGE, payload: payload});
        return;
    }

    try {
        const { error, data } = await getMemberById(memberId);
        if (error) {
            dispatch(setError(memberId, MEMBER_PAGE_ERROR));
            return;
        }
        const payload = {member: data.memberId, posts: [], page: null}
        await dispatch({type:SET_MEMBER, payload:data})
        await dispatch({
            type: SET_MEMBER_PAGE,
            payload: payload,
            meta: {
                requiresAuth: true
            }
        });
    } catch (error) {
        if(error?.message === "Member Not Found") {
            throw new Error();
        } else {
            dispatch(setError(memberId, MEMBER_PAGE_ERROR));
        }
    }
};

// Todo: Over
export const getMemberPostsAction = (fetchPage, memberId) => async (dispatch, getState) => {
    const { currentSpaceId, spaces } = getState().space;
    const page = spaces[currentSpaceId]?.memberPages[memberId]?.page;

    if (page && fetchPage === page.totalPages) return;

    try {
        const {error, data} = await getMemberPosts(memberId, fetchPage);
        if (error) {
            dispatch(setError(memberId, MEMBER_POST_ERROR, error));
            return;
        }
        let { content, currentPage, totalElements, totalPages, first, last, hasNext, hasPrevious } = data;
        await dispatch({
            type: SET_MEMBER_POSTS,
            payload: {
                page: {
                    currentPage,
                    totalElements,
                    totalPages,
                    first,
                    last,
                    hasNext,
                    hasPrevious
                },
                posts: content,
                memberId: memberId
            },
            meta: {
                requiresAuth: true
            }
        });
    } catch (error) {
        toast.error("Something went wrong. Please try again later.")
    }
};

// Todo: Over
export const handleSpaceRequestAction = (user, formData) => async (dispatch, getState) => {
    const spaceId = getState().space.currentSpaceId;
    const { status, requestId } = formData;
    try {
        const {
            error=null,
            data=null,
            requestUsed=false,
            requestNotFound=false,
            unAuthorizedAccess=false
        } = await handleSpaceRequest(formData, user?.username);

        if (error) {
            toast.error(error);
        }
        else if(requestUsed || requestNotFound || unAuthorizedAccess) {
            dispatchRemoveSpaceRequest(
                dispatch,
                REMOVE_REQUEST,
                requestId
            );
        }
        else {
            dispatchRemoveSpaceRequest(
                dispatch,
                REMOVE_REQUEST,
                requestId
            );
            if (status === "ACCEPT") {
                const {memberId, activityId, joinedOn} = data;
                const activity = {activityId, user, prompt: "JOINED", timestamp: joinedOn};
                const member = {memberId, spaceId, joinedOn, user, admin: false};
                dispatch({
                    type: ADD_MEMBER, payload: {activity: activity, member: member, spaceId}
                })
            }
        }
    } catch (error) {
        toast.error("Unexpected error occurred");
    }
};

const dispatchRemoveSpaceRequest = (dispatch, type, payload) => {
    dispatch({
        type,
        payload,
        meta: {
            requiresAuth: true
        }
    })
}


// Todo: Over
export const addEmojiAction = (postId, payload, memberId) => async (dispatch, getState) => {
    const {posts} = getState().space;
    if(posts[postId]?.memberEmoji) return;

    try {
        const {
            error=null,
            data=null,
            unAuthorizedAccess=false,
            emojiFound=false
        } = await addEmoji(payload);

        if (error) {
            toast.error(error);
        }
        else if(emojiFound) {
            toast.error("You already set the to that post ");
            dispatchAddEmoji(
                dispatch,
                ADD_EMOJI,
                {postId, emoji: data}
            )
        }
        else if(unAuthorizedAccess) {
            toast.error("You does not have permission to add emoji");
        }
        else {
            const emoji = {emojiId: data, emojiMember: memberId, ...payload}
            dispatchAddEmoji(
                dispatch,
                ADD_EMOJI,
                {postId, emoji: emoji}
            )
        }
    } catch (err) {
        toast.error("Unexpected error occurred");
    }
};

const dispatchAddEmoji = (dispatch, type, payload) => {
    dispatch({
        type,
        payload,
        meta: {
            requiresAuth: true
        }
    })
}


// Todo: Over
export const removeEmojiAction = (postId, emojiId) => async (dispatch, getState) => {
    const {posts, emojis} = getState().space;
    if(!posts[postId]?.memberEmoji || !emojis[emojiId]) {
        return;
    }
    try {
        const { error } = await removeEmoji(emojiId);
        if (error) {
            toast.error(error);
        }
        dispatch({
            type: REMOVE_EMOJI,
            payload:{postId, emojiId},
            meta: {
                requiresAuth: true
            }
        });
    } catch (error) {
        toast.error("Unexpected error occurred");
    }
};


// Todo: Over
export const getMemberEmojiAction = (postId) => async (dispatch, getState) => {
    const { posts } = getState().space;
    if (posts[postId]?.memberEmoji === null || posts[postId]?.memberEmoji) return;

    // 🚩checking on emojis by post id and member id if exists set emoji id to this post
    // before the get member emoji

    try {
        const {error, data} = await getMemberEmoji(postId);
        if (error) {
            dispatch(setError(postId, MEMBER_EMOJI_FAILED));
            return;
        }
        dispatch({
            type: SET_MEMBER_EMOJI,
            payload: { postId, emoji: data },
            meta: {
                requiresAuth: true
            }
        });
    } catch (error) {
        dispatch(setError(postId, MEMBER_EMOJI_FAILED));
    }
};


// Todo: Over
export const getEmojisAction = (postId) => async (dispatch, getState) => {
    const {posts} = getState().space;
    if(posts[postId]?.isEmojisFetched) return;

    // 🚩 cause check post view when reload the page is post is empty

    try {
        const {error, data} = await getPostEmojis(postId);
        if (error) {
            dispatch(setError(postId, POST_EMOJIS_ERROR));
            return;
        }
        dispatch({
            type: SET_EMOJIS,
            payload: { postId, emojis: data },
            meta: {
                requiresAuth: true
            }
        });
    } catch (error) {
        dispatch(setError(postId, POST_EMOJIS_ERROR));
    }
};

// Todo: Over
export const shiftAdminAction = (targetUserId, onClose) => async (dispatch, getState) => {
    const {currentSpaceId, spaces, members} = getState().space;
    try {
        const { error } = await shiftAdmin(targetUserId);
        if (error) {
            toast.error(error);
            return;
        }
        const user = members[targetUserId]?.user;
        const currentMemberId = spaces[currentSpaceId]?.currentMember?.memberId;
        await dispatch({
            type: SHIFT_ADMIN,
            payload: { targetUserId, user, spaceId: currentSpaceId, currentMemberId },
            meta: {
                requiresAuth: true
            }
        });
        toast.success("change admin to " + user?.username);
        onClose();
    } catch (error) {
        toast.error("Some thing went wrong");
    }
};


// Todo: Over
export const removeMemberAction = (memberId, feedback, onClose) => async (dispatch, getState) => {
    const {currentSpaceId, members} = getState().space;
    const {user} = members[memberId];

    try {
        const formData = new FormData();
        formData.append("memberId", memberId);
        formData.append("feedback", feedback);
        const {
            operationFailed = false,
            error=null,
            data=null
        } = await removeMember(formData);

        if (error) {
            toast.error(error);
        }
        else if(operationFailed) {
            await dispatch({
                type: REMOVE_MEMBER,
                payload: {
                    userId: user?.userId,
                    memberId: memberId,
                    spaceId: currentSpaceId
                }
            })
        } else {
            const activity = {activityId: data?.activityId, user, prompt: "REMOVE", timestamp: data?.joinedOn};
            await dispatch({
                type: REMOVE_MEMBER,
                payload: {
                    userId: user?.userId,
                    memberId: memberId,
                    spaceId: currentSpaceId

                }
            })
            await dispatch({type: ADD_ACTIVITY, payload: activity})
            toast.success("Member removed successfully. It will take time to update after 24 hrs.");
            onClose();
        }
    } catch (error) {
        toast.error("Unexpected error occurred");
    }
};

// Todo: Emojis not removed properly
export const leaveSpaceAction = (memberId, feedback, spaceId, navigate, onClose) => async (dispatch) => {
    try {
        const { error } = await leaveSpace(spaceId, memberId, feedback);
        if (error) {
            toast.error(error)
        } else {
            await dispatch({
                type: REMOVE_SPACE,
                payload: spaceId,
                meta: {
                    requiresAuth: true
                }
            });
            navigate("/spaces", {replace: true});
            onClose();
        }
    } catch (error) {
        toast.error("Unexpected error occurred");
    }
};



// Todo: later.
export const addPostAction = (spaceId, memberId, formData) => async (dispatch, getState) => {
    const { currentSpaceId, spaces } = getState().space;
    const space = spaces[currentSpaceId];
    if (space && space.todayPosts >= 3) return;

    try {
        const {error, data} = await uploadPost(spaceId, memberId, formData);
        if (error) {
            throw new Error(error);
        }
        const postId = data.postId;
        addPost(dispatch, data)
        if (space?.memberPages[memberId]) {
            addPostInMemberPage(dispatch, spaceId, postId, memberId)
        }
        if (space?.feed?.length !== undefined) {
            addPostInSpace(dispatch, spaceId, postId);
        }
        toast.success("Post uploaded successfully");

    } catch (error) {
        throw new Error(error?.message || "Unexpected error occurred");
    }
};

const addPost = (dispatch, post) => {
    dispatch({
        type: ADD_POST,
        payload: post,
        meta: {
            requiresAuth: true
        }
    })
};

const addPostInMemberPage = (dispatch, spaceId, postId, memberId) => {
    dispatch({
        type: ADD_POST_IN_MEMBER_PAGE,
        payload: { spaceId, postId, memberId }
    });
}

const addPostInSpace = (dispatch, spaceId, postId) => {
    dispatch({
        type: ADD_POST_IN_FEED, payload: { spaceId, postId }
    });
}


// worked
// Todo: later.
export const removePostAction = (memberId, post) => async (dispatch, getState) => {
    const { postId, sharedOn } = post;
    const { currentSpaceId, spaces } = getState().space;
    try {
        const { error } = await deletePost(postId);
        if (error) {
            console.log("Expected error occurred");
            return;
        }
        if (isSharedToday(sharedOn)) {
            dispatch(removePostInSpace(postId));
        }
        if (spaces[currentSpaceId]?.memberPages[memberId]) {
            dispatch(removePostInMemberPage(currentSpaceId, postId, memberId));
        }
        dispatch(removePost(postId));
    } catch (error) {
        console.log("Unexpected error occurred");
    }
};

const removePost = postId => ({
    type: REMOVE_POST,
    payload: postId,
    meta: {
        requiresAuth: true
    }
});

const removePostInMemberPage = (currentSpaceId, postId, memberId) => ({
    type: REMOVE_POST_IN_MEMBER_PAGE,
    payload: { currentSpaceId, postId, memberId }
});

const removePostInSpace = postId => ({
    type: REMOVE_POST_IN_SPACE,
    payload: postId
});
