import React, {useEffect, useMemo, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import UserBanner from "../components/shared/UserBanner";
import {API} from "../service/api/utils";
import {useDispatch, useSelector} from "react-redux";
import {getFriendsAction, getUserSpacesAction} from "../service/actions/userActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {useHandleBack} from "../hooks/useHandleBack";
import {SET_CURRENT_SPACE} from "../service/constants/spaceConstants";
import ResponseLoading from "../components/loader/ResponseLoading";
import {toast} from "sonner";
import {FRIENDS_ERROR, SPACES_ERROR} from "../service/constants/userConstants";


export default function Account() {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const account = useSelector(state => state.user) || {};
    const {userData:user, userSpaces, friends, spaces, users} = account;
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const handleBack = useHandleBack();
    const {errors} = useSelector(state => state.app)
    const friendsError = errors["friends"]?.error === FRIENDS_ERROR || false;
    const spacesError = errors["spaces"]?.error === SPACES_ERROR || false;
    const initialValues = () => {
        setError("");
        setLoading(false);
    }
    const feedbackRegex = /^[a-zA-Z0-9\s.,!?'"()-_\-]*$/;

    useEffect(() => {
        const getAccount = async() => {
            await dispatch(getFriendsAction());
            await dispatch(getUserSpacesAction());
        }
        getAccount().finally(() => {
            setLoading(false);
        })
    }, [dispatch]);


    const friend = useMemo(() => {
        return friends?.map(id => users[id])
    }, [friends])


    const handleSubmit = async (e) => {
        e.preventDefault();
        initialValues();
        if(feedback === "") {
            toast.error("Please provide feedback.")
        } else if(!feedbackRegex.test(feedback)) {
            toast.error("Your feedback contains invalid characters");
        } else {
        const formData = new FormData();
        formData.append("content", feedback);
        await API.post("users/feedback", formData)
            .then(() => {
                toast.success("Thank you for feedback from inspace.")
                setFeedback("");
            })
            .catch(() => {
                setError("Something wrong to send feedback to inspace")
            });
        }
    }

    const navigateSpace = (spaceId, uniqueId) => {
        dispatch({type: SET_CURRENT_SPACE, payload: spaceId})
            .finally(() => {
                navigate(`../space/${uniqueId}`)
            })
    }

    if(loading) {
        return <ResponseLoading />
    }

    return (
        <main>
            <header className="account_header">
                <div className="account_bar">
                    <button onClick={handleBack} className="button">
                        <img src="/back.svg" alt="back"/>
                    </button>
                    <div className="account_title">Profile</div>
                </div>
                <div className="gear">
                    <Link to="/settings">
                        <FontAwesomeIcon icon={faGear} style={{color: "#ffffff"}}/>
                    </Link>
                </div>
            </header>
            <section className="account">
                <div className="profile-card">
                    <div className="profile">
                        <div className="profile-info">
                            <img src={user.profile} alt={user?.username} />
                            <div className="profile-content">
                                <p className="name">{user?.username}</p>
                                <p className="username">{user?.name}</p>
                            </div>
                        </div>
                        <div className="user-bio">
                            {user?.status && user?.status}
                        </div>
                    </div>
                </div>

                <div className="account-spaces">
                    <div className="spaces-head">Spaces</div>
                    <div className="spaces-list">
                        {spacesError ? <div>Unable get the spaces</div>
                            : userSpaces?.length ?
                            ( userSpaces?.map(userSpace => {
                                const space = spaces[userSpace.spaceId]
                                return (
                                    <div
                                        key={space.spaceId}
                                        onClick={() => navigateSpace(space.spaceId, space.uniqueId)}
                                    >
                                        <div className="space">
                                            <div className="spacename">{space?.spacename}</div>
                                            <div className="spaceid">{space?.uniqueId}</div>
                                        </div>
                                    </div>
                                )
                            }))
                            : <div className="no-spaces">No spaces</div>
                        }
                    </div>
                </div>

                <div className="friends">
                    <div className="friends-head">Friends</div>
                    <div className="friends-list">
                        {friendsError ? <div>unable to get the friends</div>
                            : friends?.length !== 0 &&
                            friend?.slice(0, 5)?.map((user) => (
                                <div key={user.userId} className="friend">
                                    <UserBanner user={user}/>
                                    <Link
                                        relative="path"
                                        to={`/profile/${user.username}`}
                                        className="view"
                                        state={{from: pathname}}
                                    >
                                        <div className="view-btn">View</div>
                                    </Link>
                                </div>
                            ))
                        }
                    </div>

                    {(!friendsError && friends?.length >= 5) && (
                        <div className="btn">
                            <button
                                className="see-btn"
                                onClick={() => navigate("friends")}
                            >
                                See all
                            </button>
                        </div>
                    )}
                </div>

                <div className="contact">
                    <div className="contact-head">Contact</div>
                    <form onSubmit={handleSubmit}>
                        <textarea
                            className="contact-field"
                            onChange={e => setFeedback(e.target.value)}
                            disabled={loading}
                            value={feedback}
                            maxLength="150"
                            rows="8"
                            placeholder="Any feedback you consider. it helps make it better."
                        />
                        <button type="submit" disabled={loading || !feedback}>
                            Submit
                        </button>
                    </form>
                    {error && <div>{error}</div>}
                </div>

            </section>
        </main>
    )

}