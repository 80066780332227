import {Route, Routes} from "react-router-dom";
import InSpaceHome from "./pages/InSpaceHome";
import Spaces from "./pages/Spaces";
import JoinSpace from "./pages/JoinSpace";
import InSpaceActivity from "./pages/InSpaceActivity";
import InSpaceMemberPage from "./pages/InSpaceMemberPage";
import InSpaceUploadPage from "./pages/InSpaceUploadPage";
import InSpaceMembers from "./pages/InSpaceMembers";
import Explore from "./pages/Explore";
import ProfilePage from "./pages/ProfilePage";
import FriendSpaces from "./components/profile/FriendSpaces";
import FriendOfFriends from "./components/profile/FriendOfFriends";
import InSpaceLeave from "./pages/InSpaceLeave";
import Account from "./pages/Account";
import Settings from "./pages/Settings";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import LogIn from "./pages/LogIn";
import ForgetPasswordEmail from "./pages/ForgetPasswordEmail";
import ForgetPasswordVerification from "./pages/ForgetPasswordVerification";
import SignUpVerification from "./pages/SignUpVerification";
import AccessDenied from "./pages/AccessDenied";
import SignUpEmail from "./pages/SignUpEmail";
import NotFound from "./pages/NotFound";
import ChangePassword from "./pages/ChangePassword";
import ChangeEmail from "./pages/ChangeEmail";
import NewEmailVerify from "./pages/NewEmailVerify";
import MainLayout1 from "./components/layout/main/MainLayout1";
import MainLayout2 from "./components/layout/main/MainLayout2";
import PsLayout1 from "./components/layout/inspace/PsLayout1";
import PsLayout2 from "./components/layout/inspace/PsLayout2";
import Search from "./pages/Search";
import CreateSpace from "./pages/CreateSpace";
import UserFriends from "./pages/UserFriends";
import InSpace from "./InSpace";
import InSpaceUserPage from "./pages/InSpaceUserPage";
import {lazy} from "react";

const EditAccount = lazy(() => import("./pages/EditAccount"));
const InSpacePostView = lazy(() => import("./pages/InSpacePostView"));


const AppRoutes = () => (
    <Routes>
        <Route path="/">
            <Route element={<PrivateRoute />}>
                <Route element={<MainLayout1 />}>
                    <Route index element={<Explore />} />
                    <Route path="explore" element={<Explore />} />
                    <Route path="spaces" element={<Spaces />} />
                </Route>
                <Route element={<MainLayout2 />}>
                    <Route path="/space">
                        <Route path="join" element={<JoinSpace />} />
                        <Route path="create" element={<CreateSpace />} />
                    </Route>
                    <Route path="search" element={<Search />} />
                    <Route path="account">
                        <Route index element={<Account />} />
                        <Route path="friends" element={<UserFriends />} />
                    </Route>
                    <Route path="settings">
                        <Route index element={<Settings />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="edit-account" element={<EditAccount />} />
                        <Route path="change-email" element={<ChangeEmail />} />
                        <Route path="verify-email" element={<NewEmailVerify />} />
                    </Route>
                    <Route path="profile/:username" element={<ProfilePage />}>
                        <Route index element={<FriendSpaces />} />
                        <Route path="spaces" element={<FriendSpaces />} />
                        <Route path="friends" element={<FriendOfFriends />} />
                    </Route>
                    <Route path="access-denied" element={<AccessDenied />} />
                </Route>
                <Route path="space/:uniqueId" element={<InSpace />}>
                    <Route element={<PsLayout1 />}>
                        <Route index element={<InSpaceHome />} />
                        <Route path="home" element={<InSpaceHome />} />
                        <Route path="your-page" element={<InSpaceUserPage />} />
                    </Route>
                    <Route element={<PsLayout2 />}>
                        <Route path="upload" element={<InSpaceUploadPage />} />
                        <Route path="activity" element={<InSpaceActivity />} />
                        <Route path="members" element={<InSpaceMembers />} />
                        <Route path="member" element={<InSpaceMemberPage />} />
                        <Route path="post" element={<InSpacePostView />} />
                        <Route path="leave" element={<InSpaceLeave />} />
                    </Route>
                </Route>
            </Route>

            <Route element={<PublicRoute />}>
                <Route path="signin" element={<LogIn />} />
                <Route path="signup" element={<SignUpEmail />} />
                <Route path="forget-password-email" element={<ForgetPasswordEmail />} />
                <Route path="forget-password-verification" element={<ForgetPasswordVerification />} />
                <Route path="signup-verification" element={<SignUpVerification />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Route>
    </Routes>
);

export default AppRoutes;