import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearCurrentSpaceIdAction} from "../../../service/actions/spaceActions";

export default function PsHeader1() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDropdownActive, setDropdownActive] = useState(false);

    const toggleDropdown = () => {
        setDropdownActive(!isDropdownActive);
    };

    const handleButton = async () => {
        dispatch(clearCurrentSpaceIdAction())
            .finally(() => {
                navigate("/spaces")
            })
    }

    return (
        <header
            onClick={() => setDropdownActive(false)}
            className="ps-header"
        >
            <button
                onClick={handleButton}
                className="ps-header-button"
            >
                <img src="/Arrow%201.svg" alt="back"/>
            </button>

            <div
                className={`ps-header-button dropdown ${isDropdownActive ? "active" : ""}`}
                onClick={(e) => {
                    e.stopPropagation(); // Prevent closing dropdown when clicking inside
                    toggleDropdown()
                }}
            >
                <img src="/Frame%20104.svg" alt="bar" className="menu-button"/>
                <div className="dropdown-menu">
                    <div className="dropdown-link">
                        <Link to='activity'>Activity</Link>
                        <Link to='members'>Members</Link>
                        <Link to='leave'>Leave</Link>
                    </div>
                </div>
            </div>
        </header>
    );
}