import {useEffect} from "react";
import {popHistoryAction, resetHistoryAction} from "../service/actions/appActions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {POP_HISTORY} from "../service/constants/appConstants";

export const useHandleBack = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const history = useSelector(state => state.app).history;

    useEffect(() => {
        window.addEventListener('popstate', handleBack);
        return () => {
            window.removeEventListener('popstate', handleBack);
        }
    }, []);


    const handleBack = async () => {
        const currentUrl = history[history.length-1];
        if(history.length === 0) {
            navigate("/")
        }
        else if(currentUrl.pathname.startsWith("/profile")) {
            dispatch({type:POP_HISTORY})
            const backUrl = currentUrl.state?.from || "/";
            navigate(backUrl);
        }
        else if(history[history.length-1].pathname==="/space/join" ||
            history[history.length-1].pathname==="/space/create"
        ) {
            await dispatch(resetHistoryAction())
            navigate("/spaces");
        } else if (history.length > 1) {
            await dispatch(popHistoryAction(navigate));
        } else if(history[0].pathname.startsWith("/space")) {
            const basePath = history[0].pathname.split('/').slice(0, 3).join('/');
            await dispatch(resetHistoryAction())
            navigate(basePath);
        } else {
            navigate("/");
        }
    };

    return handleBack;

}