import React, {useEffect, useMemo, useState} from 'react';
import UserBanner from "../components/shared/UserBanner";
import {Link, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getFriendsAction} from "../service/actions/userActions";
import ResponseLoading from "../components/loader/ResponseLoading";
import {FRIENDS_ERROR} from "../service/constants/userConstants";

const UserFriends = () => {
    const {friends, users} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const {pathname} = useLocation();
    const {errors} = useSelector(state => state.app)
    const friendsError = errors["friends"]?.error === FRIENDS_ERROR || false;

    useEffect(() => {
        dispatch(getFriendsAction()).finally(
            () => {
                setLoading(false)
            });
    }, [dispatch])

    const friend = useMemo(() => {
        if(friendsError) return;
        return friends?.map(id => users[id])
    }, [friends])


    return (
        <div className="user-friends">
            <div className="friends">
                {loading && <ResponseLoading />}
                <div className="friends-list">
                    {friendsError ? <div>unable to get the friends</div>
                        : friends?.length !== 0 &&
                        friend?.map((user) => (
                            <div key={user.userId} className="friend">
                                <UserBanner user={user}/>
                                <Link
                                    relative="path"
                                    to={`/profile/${user.username}`}
                                    state={{from: pathname}}
                                    className="view"
                                >
                                    <div className="view-btn">View</div>
                                </Link>
                            </div>
                        ))
                    }
                </div>
        </div>
        </div>
    );
};

export default UserFriends;