import {useState, useEffect, useMemo} from 'react';
import MemberPost from '../shared/MemberPost';
import { useInView } from 'react-intersection-observer';
import {convertDateFormat, listOfDates} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {getMemberPostsAction} from "../../service/actions/spaceActions";
import {getCurrentSpace} from "../../service/selectors/spaceSelectors";
import {resetScrollAction, setShowPostAction} from "../../service/actions/appActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone} from "@fortawesome/free-solid-svg-icons";
import {MEMBER_POST_ERROR} from "../../service/constants/spaceConstants";


const MemberPosts = ({memberId}) => {
  const dispatch = useDispatch();
  const memberPage = useSelector(getCurrentSpace)?.memberPages[memberId] || {};
  let {page, posts:ids} = memberPage;
  const posts = useSelector(state => state.space.posts);
  const showPosts = useSelector(state => state.app.showPost)[memberId] || false;
  const [loading, setLoading] = useState(false);
  const { ref, inView } = useInView();
  const scrollPosition = useSelector(state => state.app.scrollPosition);
  const scroll = scrollPosition[memberId] || 0;
  const {errors} = useSelector(state => state.app);
  const error = errors[memberId];
  const memberPostsError = error?.error === MEMBER_POST_ERROR ? error?.message : false;

  useEffect(() => {
    if(scroll) {
      window.scrollTo(0, scroll);
    }
  }, [scroll]);


  useEffect(() => {
    const reset = async () => {
      if(!!scroll)
        await dispatch(resetScrollAction(memberId));
    }

    window.addEventListener('scroll', reset);
    return () => {
      window.removeEventListener('scroll', reset)
    }
  }, [memberId]);


  useEffect(() => {
    if(memberPostsError) {
      return;
    }
    const fetchPosts = async() => {
      if (!page || (page?.hasNext && inView && !loading)) {
        setLoading(true);
        const fetchPage = page ? page.currentPage + 1 : 0;
        await dispatch(getMemberPostsAction(fetchPage, memberId));
        setLoading(false);
      }
    }
    fetchPosts()
  }, [inView, page, dispatch]);



  const dates = useMemo(() => {
    if(!ids || ids.length === 0) return [];
    let lastId = ids[ids.length-1]
    const lastPost = posts[lastId]?.sharedOn;
    return listOfDates(lastPost);
  }, [ids])


  const handleClick = () => {
    if(showPosts) {
      dispatch(setShowPostAction(memberId, false))
    } else {
      dispatch(setShowPostAction(memberId, true))
    }
  }

  const isMonthHavePosts = useMemo(() => {
    return (month) => {
      const formatMonth = convertDateFormat(month);
      return ids.some(id => posts[id]?.sharedOn?.substring(0, 7) === formatMonth);
    };
  }, [ids, posts]);

  return (
      <div className="member-posts">
        <div className="clone-icon" onClick={handleClick}>
          <FontAwesomeIcon icon={faClone} />
        </div>
        {Object.keys(dates).length !== 0 &&
            Object.keys(dates).map((month, index) => (
                <div key={index}>
                  {showPosts ?
                      (
                          isMonthHavePosts(month) && <div className="month">{month}</div>
                      ) :  <div className="month">{month}</div>
                  }
                  <div className="gallery">
                    {dates[month].map((date, index) => {
                      const datePosts = ids.filter(id => posts[id]?.sharedOn?.substring(0, 10) === date);
                      if (showPosts) {
                        return datePosts.length !== 0 && (
                            <div key={index} ref={dates[month].length === index + 1 ? ref : null}>
                              <MemberPost posts={datePosts} showPosts={true}/>
                            </div>
                        );
                      } else {
                        return (
                            <div key={index} ref={dates[month].length === index + 1 ? ref : null}>
                              {datePosts.length !== 0
                                  ? <MemberPost posts={datePosts}/>
                                  : (
                                      <div className="gallery-post">
                                        <div className="empty"></div>
                                        <div className="post-date">{date.substring(8, 10)}</div>
                                      </div>
                                  )
                              }
                            </div>
                        )
                      }
                    })}
                  </div>
                </div>
            ))
        }
        {loading && (
            <div className="fetch loading-spinner"></div>
        )}
        {memberPostsError && (
            <div className="member-posts-error">{memberPostsError}</div>
        )}
      </div>
  )
}

export default MemberPosts;


// Todo: check the id came from url???