import { Link } from "react-router-dom";
import {useSelector} from "react-redux";
import AcceptRejectBtn from "../shared/AcceptRejectBtn";
import RemoveRequest from "../shared/RemoveRequest";


const Requests = () => {
    const {incomingRequests, outgoingRequests} = useSelector(state => state.user);

    if (incomingRequests.length === 0 && outgoingRequests.length === 0) {
        return (
            <div className="no-friend_requests">
                No Requests Found
            </div>
        )
    }

    return (
        <section className="requests-sec">
            {incomingRequests.length !== 0 &&
                <div className="request-section">
                    <div className="request-head">
                        <img src="/leftLine.svg" alt="left-line" />
                        <span className="request-title">Incoming</span>
                        <img src="/right-line.svg" alt="right-line" />
                    </div>
                    {incomingRequests.map(r => (
                        <div key={r.requestId} className="request">
                            <Link to={`/profile/${r.user.username}`} className="request-info">
                                <img src={r.user.profile} alt={r.user.username}/>
                                <div className="request-user">
                                    <div className="requester-name">{r.user.username}</div>
                                    <div className="requester-username">{r.user.name}</div>
                                </div>
                            </Link>
                            <AcceptRejectBtn
                                request={r}
                                text1={"ACCEPT"}
                                className1="request-accept-btn"
                                className2="request-reject-btn"
                                className3="profile-incoming-btn"
                            />
                        </div>
                    ))}
                </div>
            }

            {outgoingRequests.length !== 0 &&
                <div className="request-section">
                    <div className="request-head">
                        <img src="/leftLine.svg" alt="left-line" />
                        <span className="request-title">Outgoing</span>
                        <img src="/right-line.svg" alt="right-line" />
                    </div>
                    {outgoingRequests.map(r => (
                        <div key={r.requestId} className="request">
                            <Link to={`/profile/${r.user.username}`} className="request-info">
                                <img src={r.user.profile} alt={r.user.username}/>
                                <div className="request-user">
                                    <div className="requester-name">{r.user.username}</div>
                                    <div className="requester-username">{r.user.name}</div>
                                </div>
                            </Link>
                            <RemoveRequest
                                request={r}
                                text="ADDED"
                                className1="request-user-added"
                                className2="request-added"
                            />
                        </div>
                    ))}
                </div>
            }
        </section>
    )
}

export default  Requests;

// Todo: use border-line instead left and right images.