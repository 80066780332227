import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {getSpaceMembersAction} from "../service/actions/spaceActions";
import {getCurrentSpace, selectMembersByIds} from "../service/selectors/spaceSelectors";
import ResponseLoading from "../components/loader/ResponseLoading";

// Todo: Admin at first place. Dispatch like that.
// Todo: Members is retrieving two times when navigating one page to other (member to home) in 3g network
// I think use global loading for this scenarios like fetch same http request in different pages.
function InSpaceMembers() {
    const dispatch = useDispatch();
    const {members:ids, currentMember} = useSelector(getCurrentSpace);
    const members = useSelector(state => selectMembersByIds(state, ids));
    const [input, setInput] = useState('');
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const setMembers = () => {
            if(members.length > 0) {
                const initialResult = [
                    currentMember,
                    ...members.filter( member =>
                        member.memberId !== currentMember.memberId
                    )
                ];
                setResult(initialResult);
            }
        };
        setMembers();
    },[members])


    useEffect(() => {
        const fetchMembers = async() => {
            if(!ids?.length) {
                setLoading(true);
                await dispatch(getSpaceMembersAction());
                setLoading(false);
            }
        }
        fetchMembers();
    }, [dispatch])


    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setInput(searchTerm);
        if (loading||members.length === 0) return;
        if (searchTerm === '') {
            const initialResult = [
                currentMember,
                ...members.filter( member =>
                    member.memberId !== currentMember.memberId && !member.admin
                )
            ];
            setResult(initialResult);
        } else {
            const filteredObjects = members.filter(obj =>
                obj?.user?.username.toLowerCase().startsWith(searchTerm?.toLowerCase()) ||
                obj?.user?.name.toLowerCase().startsWith(searchTerm?.toLowerCase())
            );
            setResult(filteredObjects);
        }
    };


    return (
        <section className="members">
            <div className="member-search">
                <input
                    type="text"
                    placeholder="Search"
                    value={input}
                    onChange={handleSearch}
                />
                {!input && (
                    <span className="member-search-icon">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </span>
                )}
            </div>

            {loading
                ? (<ResponseLoading />)
                : (
                    <div className="members-list">
                        {result.length !== 0 &&
                            result?.map((m, i) => (
                                <Link
                                    to={currentMember.memberId === m.memberId
                                        ? '../your-page'
                                        :`../member?id=${m.memberId}`}
                                    state={m.memberId}
                                    key={i}
                                >
                                    <div className="member">
                                        <img src={m?.user?.profile} alt="profile"/>
                                        <div className="member-info">
                                            <div className="member-user">
                                                <p className="member-user-name">{m?.user?.username}</p>
                                                <p className="member-user-username">{m?.user?.name}</p>
                                            </div>
                                        </div>
                                        {m?.admin && (<div className="admin-tag">Admin</div>)}
                                    </div>
                                </Link>
                            ))}
                    </div>
                )
            }
        </section>
    );
}

export default InSpaceMembers;


// austerity, compassion, cleanliness, truthfulness