export const ADD_SPACE = 'ADD_SPACE';
export const REMOVE_SPACE = 'REMOVE_SPACE';
export const ADD_MEMBER = 'ADD_MEMBER';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const REMOVE_REQUEST = 'REMOVE_REQUEST';
export const ADD_POST = 'ADD_POST';
export const REMOVE_POST = 'REMOVE_POST';
export const SHIFT_ADMIN = 'SHIFT_ADMIN';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ADD_EMOJI = 'ADD_EMOJI';
export const REMOVE_EMOJI = 'REMOVE_EMOJI';
export const SET_CURRENT_SPACE = 'SET_CURRENT_SPACE';
export const SET_MEMBERS = 'SET_MEMBERS'
export const SET_ACTIVITIES = 'SET_ACTIVITIES'
export const SET_FEED = 'SET_FEED'
export const SET_MEMBER_POSTS = 'SET_MEMBER_POSTS'
export const SET_EMOJIS = 'SET_EMOJIS'
export const SET_POST_COUNT = 'SET_POST_COUNT'
export const CLEAR_CURRENT_SPACE = 'CLEAR_CURRENT_SPACE'
export const SET_REQUESTS = 'SET_REQUESTS'
export const SET_MEMBER = 'SET_MEMBER'
export const SET_MEMBER_PAGE = 'SET_MEMBER_PAGE'
export const SET_MEMBER_EMOJI = 'SET_MEMBER_EMOJI'
export const ADD_POST_IN_FEED = 'ADD_POST_IN_FEED'
export const ADD_POST_IN_MEMBER_PAGE = 'ADD_POST_IN_MEMBER_PAGE'
export const REMOVE_POST_IN_MEMBER_PAGE = 'REMOVE_POST_IN_MEMBER_PAGE';
export const REMOVE_POST_IN_SPACE = 'REMOVE_POST_IN_SPACE';
export const UPDATE_SPACE = "UPDATE_SPACE";

export const SPACE_ERROR = "something went wrong";
export const REQUESTS_FAILED = "Unable to retrieve the requests.";
export const MEMBER_EMOJI_FAILED = "Member emoji failed to retrieve";
export const POST_EMOJIS_ERROR = "Post emojis failed to retrieve";
export const POST_COUNT_ERROR = "POST_COUNT_ERROR";
export const MEMBER_PAGE_ERROR = "MEMBER_PAGE_ERROR";
export const MEMBER_POST_ERROR = "MEMBER_POST_ERROR";