import {useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useHandleBack} from "../../../hooks/useHandleBack";

const getTitleFromRoute = {
    "/space/create": "Create Space",
    "/space/join": "Join Space",
    "/account/friends": "Friends",
    "/settings": "Settings",
    "/settings/edit-account": "Edit Profile",
};

function MainHeader2() {
    const { pathname } = useLocation();
    const params = useParams();
    const [title, setTitle] = useState("");
    const userProfileRegex = /^\/profile\/([a-zA-Z0-9_]+)(\/friends)?(\/spaces)?$/
    const handleBack = useHandleBack();


    useEffect(() => {
        if (pathname === "/search" || pathname === "/account") return;

        if (userProfileRegex.test(pathname)) {
            setTitle(params?.username);
        } else {
            const title = getTitleFromRoute[pathname];
            setTitle(title);
        }
    }, [pathname, params?.username]);



    return (
        <header className="default_header">
            <button onClick={handleBack} className="back-btn">
                <img src="/back.svg" alt="back" />
            </button>
            {title &&
                <div className="layout-title"
                     style={{
                         color: title === "Friends" ? "#007bff" : "#fff"
                     }}
                >
                    {title}
                </div>
            }
        </header>
    );
}

export default MainHeader2;
