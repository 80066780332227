import axios from "axios";
import {BASEURL} from "../../utils";
import {REFRESH_TOKEN_FAIL, REFRESH_TOKEN_SUCCESS} from "../constants/authConstants";

const API = axios.create({
    baseURL: `${BASEURL}/api/v1`,
    headers: {
        "Content-Type": "application/json",
    },
});

API.interceptors.request.use((req) => {
    const accessToken = JSON.parse(localStorage.getItem("profile"))?.access_token;
    if (accessToken) {
        req.headers.Authorization = `Bearer ${accessToken}`;
    }
    return req;
});

// Todo Over//
export const refreshTokenAction = (refreshToken) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${BASEURL}/api/v1/auth/refresh-token`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${refreshToken}`
                }
        });
        const profile = JSON.parse(localStorage.getItem("profile"));
        const payload = response.data;
        const access_token = response.data.access_token;
        localStorage.setItem("profile", JSON.stringify({ ...profile, access_token}));
        dispatch({
            type: REFRESH_TOKEN_SUCCESS,
            payload: payload,
        });
    } catch (error) {
        localStorage.removeItem("profile");
        dispatch({
            type: REFRESH_TOKEN_FAIL,
            payload: error.response.data,
        });
    }
};