// src/serviceWorkerRegistration.js

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.includes('127.0.0.1')
);

export function register() {
    if ('serviceWorker' in navigator) {
        const swUrl = `http://localhost:3000/service-worker.js`;

        if (isLocalhost) {
            // This is running on localhost. Lets check if a service worker still exists or not.
            checkValidServiceWorker(swUrl);
        } else {
            // Is not localhost, just register the service worker
            registerValidSW(swUrl);
        }
    }
}

function registerValidSW(swUrl) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            console.log('Service Worker registered:', registration);
        })
        .catch(error => {
            console.error('Service Worker registration failed:', error);
        });
}

function checkValidServiceWorker(swUrl) {
    fetch(swUrl)
        .then(response => {
            if (
                response.status === 404 ||
                response.headers.get('content-type')?.indexOf('javascript') === -1
            ) {
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            console.log('No internet connection found. Service worker is not available.');
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister();
            })
            .catch(error => {
                console.error('Error during unregistration:', error);
            });
    }
}
