import * as yup from "yup";

export const createSpaceSchema = yup.object().shape({
    spaceId: yup
        .string()
        .trim()
        .required("Space Id Required")
        .min(8, "SpaceId must  be at least 8 characters")
        .max(20, "Maximum exceeded")
        .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9_-]{8,20}$/,
            {message: "SpaceId must have at least one letter and " +
                    "can include letters, numbers, hyphens, or underscores"}),
    spaceName: yup
        .string()
        .trim()
        .required("Space name Required")
        .min(6, 'SpaceName must be at least 6 characters')
        .max(20, "Maximum exceeded")
        .matches(/^[a-zA-Z0-9\s_-]{6,20}$/,
            {message: "Space name contains only letters, numbers, spaces hyphens, or underscores"}
        ),
})


export const spaceIdSchema = yup.object().shape({
    spaceId: yup
        .string()
        .trim()
        .required("Space Id Required")
        .min(8, "SpaceId must  be at least 8 characters")
        .max(20, "Maximum exceeded")
        .matches(/^(?=.*[a-zA-Z])[a-zA-Z0-9_-]{8,20}$/,
            {message: "SpaceId must have at least one letter and " +
                    "can include letters, numbers, hyphens, or underscores"}),
})

export const spaceNameSchema = yup.object().shape({
    spacename: yup
        .string()
        .trim()
        .required("Space name Required")
        .min(6, 'Space name must be at least 6 characters')
        .max(20, "Maximum exceeded")
        .matches(/^[a-zA-Z0-9\s_-]{6,20}$/,
            {message: "Space name contains only letters, numbers, spaces hyphens, or underscores"}
        ),
})