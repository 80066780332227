import React, {Fragment, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useHandleBack} from "../../hooks/useHandleBack";
import {addPostAction} from "../../service/actions/spaceActions";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentSpace} from "../../service/selectors/spaceSelectors";
import {useNavigate} from "react-router-dom";
import {toast} from "sonner";


export default function UploadPost({url, image, onBack, aspectRatio }) {
    const handleBack = useHandleBack();
    const {currentMember, spaceId}  = useSelector(getCurrentSpace);
    const memberId = currentMember?.memberId;
    const dispatch = useDispatch();
    const [caption, setCaption] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const captionRegex = /^[^'"\\;]+$/;



    const handleSubmit = async() => {
        if(!captionRegex.test(caption)) {
            toast.error("\"Caption contains invalid characters: ' \\\" ; \\\\\";\n");
            return;
        }
        setLoading(true);
        const formData = new FormData();
        try {
            formData.append('file', image, image.name);
            formData.append('caption', caption)
        } catch (err) {
            setLoading(false);
            toast.error("something went wrong. please try again later");
            return;
        }
        dispatch(addPostAction(spaceId, memberId, formData))
            .then(() => {
                navigate('..');
                setCaption('');
            })
            .catch(err => {
                toast.error(err?.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }


    return (
        <Fragment>
            <header className="upload-header">
                <button
                    onClick={handleBack}
                    disabled={loading}
                    className="button"
                >
                    <img src="/line.svg" alt="back"/>
                </button>
                <div>
                    <button
                        className="mark-icon"
                        onClick={onBack}
                    >
                        <FontAwesomeIcon icon={faXmark} className="mark-icone"/>
                    </button>
                </div>
            </header>
            <section className="upload-post-1">
                <div className="add-post">
                    Add Post
                </div>
                <div className="image-container" style={{aspectRatio: aspectRatio}}>
                    {url && (
                        <img src={url} alt="Preview" className="image-preview" />
                    )}
                </div>
                <div className="photo-expression">
                    <input
                        type="text"
                        placeholder="Photo Expression"
                        value={caption}
                        onChange={(e) => setCaption(e.target.value)}
                    />
                    <div className="descr">
                        Express your thoughts on this image
                    </div>
                </div>
                <div className="share">
                    <button
                        className="share-btn"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Sharing" : "Share"}
                    </button>
                </div>
            </section>
        </Fragment>
    )
}

// Todo: Validation on photo expression <Complete>

// TODO: Dispatch the state of loading user upload another image if the prev image is pending
// Todo: Look up some code lines