import React from "react";
import { Outlet } from "react-router-dom";
import PsHeader2 from "./PsHeader2";

export default function PsLayout2() {

  return (
    <>
        <PsHeader2 />
        <Outlet />
    </>
  )
}