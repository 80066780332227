import {useEffect, useRef} from 'react';
import {useFormik} from "formik";
import {toast} from "sonner";
import {addActionLoading, removeActionLoading} from "../../service/actions/appActions";
import {API} from "../../service/api/utils";
import {updateSpaceAction} from "../../service/actions/userActions";
import {SPACE_UPDATING} from "../../service/constants/appConstants";
import {spaceNameSchema} from "../../schemas/appSchemas";

// Todo: Better design.
const EditSpace = ({spaceId, spacename, isEdit, onSuccess, dispatch}) => {
    const inputRef = useRef();

    const onSubmit = async (values, actions) => {
        if(spacename===values.spacename) return;

        const formData = new FormData();
        formData.append("spaceId", spaceId);
        formData.append("spacename", values.spacename);

        dispatch(addActionLoading(spaceId, SPACE_UPDATING));
        API.put(`/in-space/space/update`, formData)
            .then(async (res) => {
                await dispatch(updateSpaceAction(res?.data, spaceId));
                actions.resetForm();
                onSuccess();
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || "unexpected error occurred")
            })
            .finally(async () => {
                await dispatch(removeActionLoading(spaceId));
        })
    };

    useEffect(() => {
        if (isEdit &&  inputRef.current) {
            inputRef.current?.focus();
            resetForm({values: {spacename: spacename}});
        }
        return () => {
            resetForm();
        };
    }, [isEdit]);

    const {
        values,
        resetForm,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useFormik({
        initialValues: {
            spacename: spacename,
        },
        enableReinitialize: true,
        validationSchema: spaceNameSchema,
        onSubmit,
    });


    useEffect(() => {
        if(errors?.spacename && touched?.spacename) {
            let error = errors.spacename?.toString();
            toast.error(error);
        }
    }, [errors, touched]);


    return (
        <div>
            {isEdit ? (
                <form
                    onSubmit={handleSubmit}
                    autoComplete="off">
                    <input
                        id="spacename"
                        name="spacename"
                        type="text"
                        value={values.spacename}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        ref={inputRef}
                        required
                        autoComplete="off"
                    />
                </form>
            ) : (
                <div className="space-card_name">
                    {spacename}
                </div>
            )}
        </div>
    )
};

export default EditSpace;