import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import FriendProfile from "../components/profile/FriendProfile";
import PrivateProfile from "../components/profile/PrivateProfile";
import {useDispatch, useSelector} from "react-redux";
import {getProfileAction} from "../service/actions/userActions";
import ResponseLoading from "../components/loader/ResponseLoading";
import {PROFILE_ERROR} from "../service/constants/userConstants";


function ProfilePage() {
  const dispatch = useDispatch();
  const {profiles} = useSelector(state => state.user) || [];
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const username = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const {errors} = useSelector(state => state.app)
  const profileError = errors[username]?.error === PROFILE_ERROR || false;


  useEffect(() => {
    if(profileError) return;
    setLoading(true);
    dispatch(getProfileAction(username, navigate))
        .then(() => setLoading(false));
  }, [username, dispatch])

  const profile = profiles?.find(profile => profile?.user?.username === username);

  if(loading) {
    return <ResponseLoading />
  }

  if(profileError) {
    return (
        <div className="profile-error">Unexpected error occurred.</div>
    )
  }

  return (
    <section>
      {profile &&
          <div className="profile-page">
              {
                profile?.areFriends
                  ? <FriendProfile profile={profile} />
                  : <PrivateProfile profile={profile} />
              }
          </div>
      }
    </section>
  )
}

export default ProfilePage;