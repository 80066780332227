import { useNavigate, Link } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <section>
            <div>
                <div>
                    <h1>Page not found</h1>
                    <p>
                        The page you are looking for doesn't exist.
                    </p>
                    <div>
                        <button>
                            <span onClick={() => navigate(-1)}>Go back</span>
                        </button>
                        <button>
                            <Link to="/">Take me home</Link>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NotFound;