import { Suspense } from 'react';
import "react-image-crop/dist/ReactCrop.css";
import {BrowserRouter} from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./App.css";
import {Toaster} from "sonner";
import FullScreen from "./utils/FullScreen";
import ResponseLoading from "./components/loader/ResponseLoading";
import {useSelector} from "react-redux";
import {APP_ERROR} from "./service/constants/userConstants";
import {NETWORK_ERROR} from "./service/constants/appConstants";
import {ErrorComponent} from "./components/loader/ErrorComponent";

function App() {
    const {errors} = useSelector(state => state.app)
    const appError = errors["app"]?.error === APP_ERROR || false;
    const networkError = errors["network"]?.error === NETWORK_ERROR || false;


    if(networkError) {
        return (
            <ErrorComponent errorMessage={"Please check your Internet Connection"} />
        )
    }


    if(appError) {
        return (
            <ErrorComponent errorMessage={"There is something happened. Please try again."} />
        )
    }


    return (
        <Suspense fallback={<ResponseLoading />}>
            <BrowserRouter>
                <AppRoutes />
                <Toaster position="top-center" richColors closeButton />
            </BrowserRouter>
            <FullScreen />
        </Suspense>
    );
}

export default App;

// Todo: global error with message. <Completed>
// Todo: After logout check all old things can clean are not. <Completed>
// Todo: Also do network error. <Completed>
// Todo: Some pages too zoom out when screen is not in fullscreen
// Todo: Be aware s3 slow loading lead to set image to another entity