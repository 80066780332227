import React, {Fragment, useEffect, useState} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addSpaceAction} from "./service/actions/spaceActions";
import {getCurrentSpace} from "./service/selectors/spaceSelectors";
import ResponseLoading from "./components/loader/ResponseLoading";
import {SPACE_ERROR} from "./service/constants/spaceConstants";

// Todo: Current SpaceId still in state when outside by view profile
// Todo: current space some times undefined
// Todo: better to way to dispatch the current space
// Todo: check sessions also
// Todo: make better fallback inside space 403 error.
const InSpace = () => {
    const dispatch = useDispatch();
    const currentSpace = useSelector(getCurrentSpace);
    const [loading, setLoading] = useState(false);
    const {uniqueId} = useParams();
    const navigate = useNavigate();
    const {errors} = useSelector(state => state.app)
    const spaceError = errors[currentSpace?.spaceId || uniqueId] || {};

    const isError = spaceError?.error === SPACE_ERROR || false;

    useEffect(() => {
        if(!currentSpace) {
            if(isError) return;
            setLoading(true);
            dispatch(addSpaceAction(uniqueId, navigate))
                .finally(() => {
                    setLoading(false)
                });
        }
        else {
            setLoading(false)
        }
    }, [currentSpace, uniqueId, dispatch])


    if(isError) {
        return (
            <div>Something error occurred in this space.</div>
        )
    }

    if (loading) {
        return <ResponseLoading />
    }


    return (
        <Fragment>
            {currentSpace && <Outlet />}
        </Fragment>
    )
}

export default InSpace;