import {createSelector} from 'reselect';

const getCurrentSpaceId = (state) => state?.space?.currentSpaceId;
const getSpaces = (state) => state?.space?.spaces || {};
const getMembers = (state) => state?.space.members;
const getPosts = (state) => state?.space.posts;
const getEmojis = (state) => state?.space.emojis;

// used
export const getCurrentSpace = createSelector(
    [getCurrentSpaceId, getSpaces],
    (currentSpaceId, spaces) => {
        return currentSpaceId && spaces ? spaces[currentSpaceId] : null;
    }
);


export const getFeedByCurrentSpaceId = createSelector(
    [getCurrentSpaceId, getSpaces],
    (currentSpaceId, spaces) => {
        if (currentSpaceId && spaces[currentSpaceId]) {
            return spaces[currentSpaceId].feed;
        }
        return [];
    }
);

//  used
export const selectMembersByIds = createSelector(
    [getMembers, (state, memberIds) => memberIds || []],
    (membersById, memberIds) => {
        if (!Array.isArray(memberIds)) return [];
        return memberIds.map(id => membersById[id] || null);
    }
);

export const getEmojisByPostId = createSelector(
    [getEmojis, (state, postId) => postId],
    (emojis, postId) => {
        return Object.values(emojis).filter(emoji => emoji.postId === postId);
    }
);

// used
export const getPostById = (state, postId) => {
    const posts = getPosts(state);
    return posts[postId];
};

// used
export const getMemberById = (state, memberId) => {
    const members = getMembers(state);
    return members[memberId];
};


// Create a selector to get a member page by memberId
export const getMemberPage = (memberId) => createSelector(
    [getCurrentSpaceId, getSpaces],
    (currentSpaceId, spaces) => {
        const currentSpace = spaces[currentSpaceId];
        return currentSpace?.memberPages[memberId];
    }
);

export const getActivities = (state, spaceId) => {
    const space = getSpaceById(state, spaceId);
    return space ? space.activities : null;
};

// Selector to get requests in a space
export const getRequestsInSpace = (state, spaceId) => {
    const space = getSpaceById(state, spaceId);
    return space ? space.requests : null;
};


export const getCurrentSpaceMembers = createSelector(
    [getCurrentSpace, getMembers],
    (currentSpace, members) => currentSpace?.members.map(memberId => members[memberId]) || []
);

export const getCurrentSpaceFeed = createSelector(
    [getCurrentSpace, getPosts],
    (currentSpace, posts) => currentSpace?.feed.map(postId => posts[postId]) || []
);


export const getEmojiById = (state, emojiId) => {
    const emojis = getEmojis(state);
    return emojis[emojiId];
};

export const getMemberPosts = createSelector(
    [getCurrentSpace, getPosts],
    (currentSpace, posts) => {
        if (!currentSpace?.memberPages) return {};
        return Object.keys(currentSpace.memberPages).reduce((acc, memberId) => {
            const memberPage = currentSpace.memberPages[memberId];
            acc[memberId] = memberPage.posts.map(postId => posts[postId]);
            return acc;
        }, {});
    }
);


// Selector to get all spaces
export const getAllSpaces = createSelector(
    [getSpaces],
    (spaces) => Object.values(spaces)
);


// Selector to get a space by ID
export const getSpaceById = (state, spaceId) => {
    const spaces = getSpaces(state);
    return spaces[spaceId];
};

// Selector to get members in a space by ID
export const getMembersInSpaceById = (state, spaceId) => {
    const space = getSpaceById(state, spaceId);
    if (!space) return [];
    const members = getMembers(state);
    return space.members.map(memberId => members[memberId]);
};


// Selector to get all activities in a space
export const getActivitiesInSpace = (state, spaceId) => {
    const space = getSpaceById(state, spaceId);
    return space ? space.activities.body : [];
};


// Selector to get a member's posts in a space
export const getMemberPostsInSpace = (state, spaceId, memberId) => {
    const space = getSpaceById(state, spaceId);
    if (!space || !space.memberPages[memberId]) return [];
    const posts = getPosts(state);
    return space.memberPages[memberId].posts.map(postId => posts[postId]);
};

// Selector to get a member's data and their posts
export const getMemberDataAndPosts = (state, memberId) => {
    const member = getMembers(state)[memberId];
    if (!member) return { member: null, posts: [] };
    const spaces = getAllSpaces(state);
    const posts = spaces.reduce((acc, space) => {
        const memberPosts = getMemberPostsInSpace(state, space.spaceId, memberId);
        return [...acc, ...memberPosts];
    }, []);
    return { member, posts };
};



// Selector to get all posts of a specific member
export const getAllPostsByMember = (state, memberId) => {
    const spaces = getSpaces(state);
    const posts = getPosts(state);
    return Object.values(spaces).reduce((acc, space) => {
        const memberPosts = space.feed
            .map(postId => posts[postId])
            .filter(post => post.memberId === memberId);
        return [...acc, ...memberPosts];
    }, []);
};



// Selector to get posts with emojis
export const getPostsWithEmojis = createSelector(
    [getPosts, getEmojis],
    (posts, emojis) => {
        return Object.values(posts).map(post => ({
            ...post,
            emojis: Object.values(emojis).filter(emoji => emoji.postId === post.postId)
        }));
    }
);


// Selector to get spaces with members count
export const getSpacesWithMemberCount = createSelector(
    [getSpaces],
    (spaces) => {
        return Object.values(spaces).map(space => ({
            ...space,
            memberCount: space.members.length
        }));
    }
);
