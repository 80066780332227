import {
    ADD_ACTION_LOADING,
    POP_HISTORY,
    PUSH_HISTORY, REMOVE_ACTION_LOADING, REMOVE_ERROR, RESET_HISTORY,
    RESET_SCROLL_POSITION, SET_ERROR, SET_ERROR_RETRIES,
    SET_SCROLL_POSITION, SET_SHOW_POST
} from "../constants/appConstants";


// Todo: error on navigate the member to post and member by emoji then back previous page is null
export const pushHistoryAction = (location) => async (dispatch, getState) => {
    let {pathname, search, state} = location;
    const history = getState().app?.history || [];
    const length = history?.length;
    if(history[length-1]?.pathname === pathname) return;
    await dispatch({type:PUSH_HISTORY, payload: {pathname, search, state}})
}

export const popHistoryAction = (navigate) => async (dispatch, getState) => {
    const history = getState().app?.history;
    await dispatch({type:POP_HISTORY})
    let {pathname, search, state} = history[history.length-2];
    const path = `${pathname}${search}`;
    navigate(path, {state});
}

export const resetHistoryAction = () => async (dispatch) => {
    await dispatch({type:RESET_HISTORY})
}

export const setScrollAction = (memberId, position) => async (dispatch) => {
    await dispatch(
        { type: SET_SCROLL_POSITION, payload: {memberId, position} }
    )
}

export const resetScrollAction = (memberId) => async (dispatch) => {
    await dispatch(
        {type: RESET_SCROLL_POSITION, payload: memberId}
    )
}

export const addActionLoading = (key, action) => async (dispatch) => {
    await dispatch(
        {type:ADD_ACTION_LOADING, payload: {key, action}}
    )
}

export const removeActionLoading = (key) => async (dispatch) => {
    await dispatch(
        {type: REMOVE_ACTION_LOADING, payload: key}
    )
}

export const setShowPostAction = (key, value) => async (dispatch) => {
    await dispatch(
        {type: SET_SHOW_POST, payload: {key, value}}
    )
}


export const setError = (key, error, message) => async (dispatch) => {
    await dispatch({
        type: SET_ERROR,
        payload: {key, error, message}
    })
}

export const removeError = (key) => async (dispatch) => {
    await dispatch({type: REMOVE_ERROR, payload: key})
}