import {useEffect, useState} from "react";
import Requests from "../components/main/Requests";
import AddFriend from "../components/main/AddFriend";
import {useDispatch} from "react-redux";
import {getMutualRequestAction} from "../service/actions/userActions";
import {RESET_HISTORY} from "../service/constants/appConstants";
import ResponseLoading from "../components/loader/ResponseLoading";


export default function Explore() {
    const dispatch = useDispatch();

    const [isViewingRequests, setIsViewingRequests] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        dispatch(getMutualRequestAction())
            .finally(() => setLoading(false));
        return () => {
            dispatch({type: RESET_HISTORY})
        }
    }, [dispatch]);



    return (
        <section className="explore-page">
            <div className="explore-menu">
                <button
                    onClick={() => setIsViewingRequests(false)}
                    className={`${isViewingRequests 
                        ? "explore-menu-active" : "explore-menu-inactive"}`
                    }
                >Add Friends</button>

                <button
                    onClick={() => setIsViewingRequests(true)}
                    className={`${!isViewingRequests 
                        ? "explore-menu-active" : "explore-menu-inactive"}`
                    }
                >Requests</button>
            </div>

            {loading ? (
                <ResponseLoading />
            ) : isViewingRequests ? (
                <Requests />
            ) : (
                <AddFriend />
            )}
        </section>
    )
}