import React, {useMemo} from 'react';
import {useOutletContext} from 'react-router-dom';
import JoinSpaceModal from "../shared/JoinSpaceRequest";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

const FriendSpaces = () => {
    const friendsId = useOutletContext();
    const {friendSpaces, spaces:allSpaces} = useSelector(state => state.user);

    const spaces = useMemo(() => {
        const spaceIds = friendSpaces[friendsId]?.spaces;
        return spaceIds?.map(id => allSpaces[id]);
    }, [friendSpaces, allSpaces])

    return (
        <section className="friend-spaces">

            <div className="head">
                Spaces <span> <FontAwesomeIcon icon={faChevronRight}/> </span>
            </div>

            <div className="friend-spaces-list">
                {spaces?.length !== 0 ? spaces?.map(space => {
                    const {spaceId, spacename, uniqueId} = space;
                    return (
                        <div key={spaceId} className="friend-space">
                            <div className="space-info">
                                <div className="friend-space-name">{spacename}</div>
                                <div className="friend-spaceid">{uniqueId}</div>
                            </div>
                            <div className="join-space-modal">
                                <JoinSpaceModal
                                    space={space}
                                    className1="friend-space-added"
                                    className2="friend-space-add"
                                />
                            </div>
                        </div>
                    )
                }) : <div className="no-spaces">No spaces</div>
                }
            </div>
        </section>
    )
}

export default FriendSpaces;

// Todo: use memo is redundant coz all spaces changed
//  this also changed and request status is want <Tackle in optimizing>