import {useState} from "react";
import SpaceRequests from "../components/activity/SpaceRequests";
import Activities from "../components/activity/Activities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {getCurrentSpace} from "../service/selectors/spaceSelectors";


function InSpaceActivity() {
    const [modalOpen, setModalOpen] = useState(false);
    const {currentMember, spaceId} = useSelector(getCurrentSpace);
    const [requestsLoading, setRequestsLoading] = useState(false);

    return (
        <section className="activity">
            <div className="activity-body">
                <div className={`request-dropdown ${modalOpen ? "request-btn-active" : ""}`}>
                    {currentMember?.admin
                        &&
                        <button
                            className="requests-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalOpen(!modalOpen)
                            }}
                        >
                            Requests
                            <span className="requests-toggle">
                                <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                        </button>
                    }
                    {modalOpen && (
                        <SpaceRequests
                            spaceId={spaceId}
                            loading={requestsLoading}
                            setLoading={setRequestsLoading}
                        />
                    )}
                </div>

              <Activities spaceId={spaceId}/>
            </div>
        </section>
    )
}


export default InSpaceActivity;