import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {useFormik} from "formik";
import {spaceIdSchema} from "../schemas/appSchemas";
import {useDispatch, useSelector} from "react-redux";
import {getSpaceAction} from "../service/actions/userActions";
import React, {useState} from "react";
import JoinSpaceModal from "../components/shared/JoinSpaceRequest";
import ResponseLoading from "../components/loader/ResponseLoading";


// Todo: error occurred on search space(spaceName)
//  if is in store(spacename) so use one for consistency
export default function JoinSpace() {
    const dispatch = useDispatch();
    const {spaces} = useSelector(state => state.user);
    const [uniqueId, setUniqueId] = useState("");
    const space = Object.values(spaces).find(space => space.uniqueId === uniqueId);


    const onSubmit = async (values) => {
        if(values.spaceId === space?.uniqueId) return;
        setUniqueId(values.spaceId);
        await dispatch(getSpaceAction(values.spaceId));
    }

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            spaceId: ""
        },
        validationSchema: spaceIdSchema,
        onSubmit
    })


    return (
        <section className="join-space-page">
            <div className="join-div">
                <form onSubmit={handleSubmit}>
                    <div className="space-search">
                        <input
                            id="spaceId"
                            name="spaceId"
                            type="text"
                            placeholder="Enter Space Id"
                            value={values.spaceId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="off"
                        />
                        <span className={`space-search-icon ${values.spaceId ? "active" : ""}`}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} style={{fontSize: "17px"}}/>
                        </span>
                    </div>
                    {errors.spaceId && touched.spaceId
                        && <p className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation} />
                            </span>
                                {errors.spaceId}</p>
                        }
                </form>

                {isSubmitting && (
                    <ResponseLoading />
                )}

                <div className={`result-space ${space ? 'active-space' : "" } `}>
                    { space && (
                        <>
                            <p className="result-space-name">
                                {space?.spacename}
                            </p>
                            <p className="result-space-id">
                                {space?.uniqueId}
                            </p>
                            <JoinSpaceModal
                                space={space}
                                className1="requested-btn"
                                className2="join-btn"
                            />
                        </>
                    )}
                </div>
            </div>

            <div className="register-link">
                <p>or</p>
                <Link to="../create">Create Space</Link>
            </div>

        </section>
      )
}

// Todo: change space request status response it cause shift admin problem for admin users
// Todo: navigation came from 403 space error then it does not go back
// Todo: state is not replaced so we do later