import AccountSection from "../components/settings/AccountSection";
import AboutSection from "../components/settings/AboutSection";
import {logoutAction} from "../service/actions/authActions";
import {useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

// Todo: error occurred after logout clicked
// Todo: Remove network in inspect content after logout is succeed
// Todo: logout modal
export default function Settings() {
    const dispatch = useDispatch();
    const {userData} = useSelector(state => state.user);
    const {profile, name, username} = userData;
    const [loggingOut, setLoggingOut] = useState(false);


    const handleLogout = async () => {
        setLoggingOut(true);
        await dispatch(logoutAction(dispatch));
        setLoggingOut(false);
    }

    return (
        <section className="settings">

            <div className="app">
                <Link to="edit-account">
                    <div className="account-profile">
                        <img src={profile} alt={username}/>
                        <div className="account-profile-info">
                            <p className="account-name">{username}</p>
                            <p className="account-username">{name}</p>
                        </div>
                    </div>
                </Link>
            </div>

            <div className="account-head">
                <p className="setting-heading">Account</p>
                <AccountSection/>
            </div>

            <div className="about-head">
                <div className="setting-heading">About</div>
                <AboutSection />
            </div>

            <div
                className="setting logout"
                onClick={handleLogout}
            >
                <button className="setting-name logout-btn">
                    {loggingOut ? "LoggingOut..." : "Logout"}
                </button>
            </div>
        </section>
    )
}