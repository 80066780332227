import {isValidToken} from "../../utils/authUtils";
import {refreshTokenAction} from "./refreshTokenAction";
import {
    CLEAR_MESSAGE,
    ERROR_MESSAGE,
    LOGOUT,
    SET_ACCESS_TOKEN,
    SET_REFRESH_TOKEN,
    SIGN_IN_SUCCESS,
    SIGNIN_FAIL,
} from "../constants/authConstants";
import {logout, signIn, signUp} from "../api/authAPI";
import {getUserAction} from "./userActions";
import {toast} from "sonner";

export const initializeAuth = () => async (dispatch) => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const accessToken = profile?.access_token;
    const refreshToken = profile?.refresh_token;
    if (accessToken && refreshToken) {
        if (isValidToken(accessToken)) {
            dispatch(setAccessToken(accessToken));
            dispatch(setRefreshToken(refreshToken));
            await dispatch(getUserAction());
        } else {
            await dispatch(refreshTokenAction(refreshToken));
        }
    }
};

export const setAccessToken = (accessToken) => async (dispatch) => {
    dispatch({ type: SET_ACCESS_TOKEN, payload: accessToken });
};

export const setRefreshToken = (refreshToken) => async (dispatch) => {
    dispatch({ type: SET_REFRESH_TOKEN, payload: refreshToken });
};

export const setInitialAuthState = (navigate) => async (dispatch) => {
    await dispatch({ type: LOGOUT });
    navigate("/signin");
};

export const clearMessage = () => async (dispatch) => {
    dispatch({ type: CLEAR_MESSAGE });
};

export const logoutAction = () => async (dispatch) => {
    try {
        const { data } = await logout();
        localStorage.removeItem("profile");
        sessionStorage.removeItem("x-ps");
        window.location.reload();
        dispatch({ type: LOGOUT, payload: data });
    } catch (error) {
        dispatch({ type: LOGOUT, payload: ERROR_MESSAGE });
    }
};

export const signUpAction = (userData, navigate, email) => async (dispatch) => {
    dispatch({type:CLEAR_MESSAGE})
    try {
        localStorage.removeItem("profile");
        const response = await signUp(userData);
        const { error } = response;
        if (error) {
            if(error?.code === "Validation failed") {
                toast.zerror(error?.message);
            }
            else {
                toast.error(error);
            }
        }
        else {
            toast.success("You have successfully created an account. Please verify your email.")
            navigate("/auth/verify", { state: email });
        }
    } catch (error) {
        toast.error("Something went wrong");
    }
};

export const signInAction = (formData, navigate) => async (dispatch) => {
    try {
        const response = await signIn(formData);
        const { error, data } = response;
        if (error) {
            if(error?.code === "Validation failed") {
                toast.error(error?.message);
            } else {
                dispatch({type: SIGNIN_FAIL, payload: error});
            }
        } else {
            const { user, access_token, refresh_token } = data;
            const profile = {
                user,
                access_token,
                refresh_token,
                isAuthenticated: true
            };
            localStorage.setItem("profile", JSON.stringify({...data, user:user.userId}));
            dispatch({type: SIGN_IN_SUCCESS, payload: profile});
            navigate("/");
        }
    } catch (error) {
        await dispatch({type: SIGNIN_FAIL, payload: ERROR_MESSAGE,});
        navigate("/signin");
    }
};