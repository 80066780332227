import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getCurrentSpace} from "../service/selectors/spaceSelectors";
import {useNavigate} from "react-router-dom";
import LeaveSpaceModal from "../components/modal/LeaveSpaceModal";
import {toast} from "sonner";


// Todo: some other things do i tried to think to do this.
// TODO: Is Maintaining loading across space it leads to not access this space
//  I think when user is try to leave, user get out and could not access leave space loading users stops
//  regenerate new space token
// Todo: text area design and try to find how much maximum lines we can use in text area but 150 still
export default function InSpaceLeave() {
    const {currentMember, spaceId, spaceData} = useSelector(getCurrentSpace);
    const spacename = spaceData.uniqueId;
    const {memberId, admin} = currentMember;
    const navigate = useNavigate();

    const [feedback, setFeedback] = useState("");
    const [onModalOpen, setModalOpen] = useState(false);
    const feedbackRegex = /^[a-zA-Z0-9\s.,!?'"()-_\-]*$/;


    if(admin) return (
        <div className="message-admin-shift">
            Please shift your admin to other members.
            And better message for this scenario
        </div>
    )

    const handleOpenModal = () => {
        if(feedback.length < 50) {
            toast.error("Please enter at least 50 characters");
        } else if(!feedbackRegex.test(feedback)) {
            toast.error("Your feedback contains invalid characters");
        } else {
            setModalOpen(true);
        }
    }


    return (
        <section className="leave-space">
            <div className="leave-space-title">
                Leave Space
            </div>

            <div className="note">

                <div className="point">
                    <p className="point-index">1</p>
                    <p className="point-text">
                        When you leave this space, all your posts, memories will be permanently
                        deleted.
                    </p>
                </div>

                <div className="point">
                    <p className="point-index">2</p>
                    <p className="point-text">
                        You can't access the space or see what's inside the space
                    </p>
                </div>

                <div className="point">
                    <p className="point-index">3</p>
                    <p className="point-text">
                        Rejoining this space means starting new with all your content erased.
                    </p>
                </div>

                <div className="point">
                    <p className="point-index">4</p>
                    <p className="point-text">
                        Leave means missing out on what's happening inside this space.
                    </p>
                </div>
            </div>

            <div className="feedback">
                <textarea
                    value={feedback}
                    onChange={e => setFeedback(e.target.value)}
                    maxLength="150"
                    rows="8"
                    placeholder="Reason to leave"
                />
            </div>

            <div className="leave-space-btn">
                <button
                    className="leave-btn"
                    disabled={onModalOpen || (!feedback)}
                    onClick={handleOpenModal}
                >
                    Leave
                </button>

                <div className="stay-btn">
                    <p>Back to
                        <button
                            onClick={() => {
                                navigate(-1)
                            }}
                        >Stay</button>
                    </p>
                </div>
            </div>

            {onModalOpen && (
                <LeaveSpaceModal
                    onClose={() => setModalOpen(false)}
                    spaceId={spaceId}
                    memberId={memberId}
                    feedback={feedback}
                    spacename={spacename}
                    navigate={navigate}
                />
            )}
        </section>
    )
}