import {useDispatch, useSelector} from "react-redux";
import {handleFriendRequestAction} from "../../service/actions/userActions";
import {addActionLoading, removeActionLoading} from "../../service/actions/appActions";
import {ButtonLoading} from "../loader/ButtonLoading";
import {Fragment} from "react";
import {HANDLE_FRIEND_REQUEST_ACTION} from "../../service/constants/appConstants";


const AcceptRejectBtn = (
    {request, text1, text2, className1, className2, className3}
) => {
    const {requestId, user} = request;
    const dispatch = useDispatch();

    const actionLoading = useSelector(state => state.app.actionLoading);
    const loading = actionLoading[user?.userId] || false;

    const handleRequest = async(action) => {
        if(action !== "accept" && action !== "decline") return;
        const userId = user?.userId;
        await dispatch(addActionLoading(userId, HANDLE_FRIEND_REQUEST_ACTION));
        const formData = new FormData();
        formData.append("action", action);
        formData.append("targetId", requestId);
        dispatch(handleFriendRequestAction(user, formData))
            .finally(() => {
                dispatch(removeActionLoading(userId))
            });
    }

    return (
        <div className={className3}>
            {
                loading === HANDLE_FRIEND_REQUEST_ACTION
                    ? <ButtonLoading size={"16px"} />
                    : (
                        <Fragment>
                            <button
                                onClick={() => handleRequest("accept")}
                                className={className1}
                                disabled={loading}
                            >
                                {text1}
                            </button>
                            <button
                                onClick={() => handleRequest("decline")}
                                className={className2}
                                disabled={loading}
                            >
                                {text2 ? text2 : <img src="/wrong.svg" alt="back"/>}
                            </button>
                        </Fragment>
                    )
            }
        </div>
    )
}

export default AcceptRejectBtn;