export const CLEAR_USER_DATA = "CLEAR_USER_DATA";

export const ADD_SUGGESTED_USERS = 'ADD_SUGGESTED_USERS';
export const SET_SUGGESTED_USER = 'SET_SUGGESTED_USER';
export const UPDATE_SUGGESTED_USERS = "UPDATE_SUGGESTED_USERS";
export const REMOVE_SUGGESTED_USER = "REMOVE_SUGGESTED_USER";


export const ADD_MUTUAL_REQUESTS = "ADD_MUTUAL_REQUESTS";

export const ADD_INCOMING_REQUEST = "ADD_INCOMING_REQUEST";
export const REMOVE_INCOMING_REQUEST = 'REMOVE_INCOMING_REQUEST';

export const ADD_OUTGOING_REQUEST = "ADD_OUTGOING_REQUEST";
export const REMOVE_OUTGOING_REQUEST = "REMOVE_OUTGOING_REQUEST";

export const ADD_SPACES = "ADD_SPACES";
export const SET_SPACE = "SET_SPACE";
export const SET_MEMBERS_COUNT = "SET_MEMBERS_COUNT";
export const DELETE_SPACE = "DELETE_SPACE";

export const ADD_PROFILE = "ADD_PROFILE";
export const SET_PROFILE_STATUS = "SET_PROFILE_STATUS"

export const ADD_FRIENDS = "ADD_FRIENDS";
export const ADD_FRIEND = "ADD_FRIEND";
export const ADD_FRIEND_INFO = "ADD_FRIEND_INFO";
export const SET_UNFRIEND_PROFILE = "SET_UNFRIEND_PROFILE"

export const ADD_SEARCHED_SPACE = "ADD_SEARCHED_SPACE"
export const SET_SPACE_REQUEST = "SET_SPACE_REQUEST"

export const APP_ERROR = "APP_ERROR";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const PROFILE_STATUS_ERROR = "PROFILE_STATUS_ERROR";
export const PROFILE_INFO_ERROR = "PROFILE_INFO_ERROR";
export const FRIENDS_ERROR = "FRIENDS_ERROR";
export const SPACES_ERROR = "SPACES_ERROR";
export const MEMBER_COUNT_ERROR = "MEMBER_COUNT_ERROR"
export const SPACE_REQUEST_STATUS_ERROR = "SPACE_REQUEST_STATUS_ERROR";