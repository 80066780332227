import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SignUpProfile from "../components/auth/SignUpProfile.jsx";
import axios from "axios";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {useFormik} from "formik";
import {Link} from "react-router-dom";
import {emailSchema} from "../schemas/authSchemas";
import {emailAvailabilityApi} from "../service/api/utils";


export default function SignUpEmail() {
    const [error, setError] = useState("");
    const [isEmailAvailable, setIsEmailAvailable] = useState(false);

    const clearState = () => {
        setError("")
        setIsEmailAvailable(false)
    }

    const onSubmit = async (values) => {
        clearState();
        try {
            const res = await axios.get(`${emailAvailabilityApi}?email=${values.email}`);
            if (res.status === 200) {
                setIsEmailAvailable(true);
            }
        } catch (err) {
            const errorMessage = err.response?.data?.message || "Unexpected error occurred";
            setError(errorMessage);
        }
    }

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: emailSchema,
        onSubmit
    })

    if(isEmailAvailable) {
        return (
            <SignUpProfile
                email={values.email}
                onBack={() => setIsEmailAvailable(false)}
            />
        )
    }

    return (
        <section className="signup-email__section">
            <form onSubmit={handleSubmit} className="signup-email__form">
                <div className="signup-email__heading">Enter your email</div>
                <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    placeholder="example@email.com"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    className="signup-email__input"
                />
                {errors.email && touched.email && (
                    <div className="input-validation__error">
                        <span className="input-validation__error-icon">
                            <FontAwesomeIcon icon={faCircleExclamation}/>
                        </span>
                        {errors.email}
                    </div>
                )}
                <div className="signup-email__button-container">
                    <button
                        disabled={isSubmitting}
                        type="submit"
                        className="signup-email__button"
                    >
                        {isSubmitting ? "Loading..." : "Next"}
                    </button>
                </div>
            </form>

            {error && (
                <div className="signup-email__error-message">
                    {error}
                </div>
            )}

            <div className="signup-email__links">
                Back to <Link to="/signin" className="signup-email__signin">Login</Link>
            </div>

        </section>
    )
}