import {useState} from "react";
import {API} from "../../service/api/utils";
import {useFormik} from "formik";
import {emailSchema} from "../../schemas/authSchemas";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";

const AddNewEmail = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const clearState = () => {
        setSuccess(false)
        setError("");
        setMessage("");
    }


    const onSubmit = async (values) => {
        clearState();
        const request = new FormData();
        request.append("email", values.email);
        await API.put("users/change-email", request)
            .then((res) => {
                setSuccess(true);
                setMessage(res?.data);
            }).catch((res) => {
                setError(res?.response?.data?.message || "unexpected error occurred");
            })
    }

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: emailSchema,
        onSubmit
    })


    return (
        <section className="change-email">
            <div className="change-email-heading">Add New Email</div>

            <form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <div className="input-field">
                    <input
                        className="input-2"
                        id="email"
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        autoComplete="off"
                    />
                    {errors.email && touched.email
                        && <p className="input-validation__error">
                                <span>
                                    <FontAwesomeIcon icon={faCircleExclamation}/>
                                </span>
                            {errors.email}</p>
                    }
                </div>
                <div className="change-email-btn">
                    <button
                        type="submit"
                        className="send-btn"
                        disabled={isSubmitting}
                    >
                        {isSubmitting
                            ? <div className="loading-spinner"></div>
                            : "Send Code"
                        }
                    </button>
                </div>
            </form>

            <div className="email-msg">
                <p>{success && message}</p>
                <p>{error && error}</p>
            </div>

        </section>
    )
}

export default AddNewEmail;