import { Link } from "react-router-dom";


// Todo: Design.
const AccessDenied = () => {
    return (
        <section>
            <div>
                <h1>
                    Access Denied
                </h1>
                <p>
                    You do not have permission to access this page.
                </p>
                <div>
                    <button>
                        <Link to="/">Take me home</Link>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default AccessDenied;