import React, { useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompress, faExpand} from "@fortawesome/free-solid-svg-icons";

const FullScreen = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleFullScreenToggle = () => {
        try {
            if (!isFullScreen) {
                const element = document.documentElement;
                if (element.requestFullscreen) {
                    element.requestFullscreen()
                        .catch(err => console.log(err))
                } else if (element.mozRequestFullScreen) { // Firefox
                    element.mozRequestFullScreen();
                } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
                    element.webkitRequestFullscreen();
                } else if (element.msRequestFullscreen) { // IE/Edge
                    element.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                        .catch(err => console.log(err))
                } else if (document.mozCancelFullScreen) { // Firefox
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { // IE/Edge
                    document.msExitFullscreen();
                }
            }
            setIsFullScreen(!isFullScreen);
        } catch (error) {
            console.error('Error toggling full screen:', error);
        }
    };

    return (
        <button onClick={handleFullScreenToggle} className="fullScreen">
            {isFullScreen ?
                <FontAwesomeIcon icon={faCompress} />:
                <FontAwesomeIcon icon={faExpand} />}
        </button>
    );
};

export default FullScreen;
