import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";

export default function MainHeader1() {
    const {userData} = useSelector(state => state.user);
    const {pathname} = useLocation();

    return (
        <header className="main-header">
            <Link to="search" state={pathname} className="search-icon">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </Link>
            <div className="title">InSpace</div>
            <Link to="account" className="profile-pic">
                <img src={userData?.profile} alt="profile"/>
            </Link>
        </header>
    )
}

// Todo: use svg images and get all from cdn cloud.