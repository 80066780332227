import {useEffect, useState} from "react";
import {emojisInput, useDisplayEmojis} from "../../utils";
import { memo } from "react";
import {useDispatch, useSelector} from "react-redux";
import {addEmojiAction, getMemberEmojiAction, removeEmojiAction} from "../../service/actions/spaceActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamation, faXmark} from "@fortawesome/free-solid-svg-icons";
import {ButtonLoading} from "../loader/ButtonLoading";
import {MEMBER_EMOJI_FAILED} from "../../service/constants/spaceConstants";

const MemberEmoji = ({postId, memberId, emojiId}) => {
    const dispatch = useDispatch();
    const [showEmojis, setShowEmojis] = useState(false);
    const [loading, setLoading] = useState(true);
    const emoji = useSelector(state => state.space.emojis);
    const memberEmoji = emoji[emojiId] || null;
    const {errors} = useSelector(state => state.app);
    const emojiError = errors[postId]?.error === MEMBER_EMOJI_FAILED || false;


    useEffect(() => {
        const fetchMemberEmoji = async () => {
            if(emojiError) return;
            setLoading(true);
            await dispatch(getMemberEmojiAction(postId, memberId));
            setLoading(false)
        }
        fetchMemberEmoji();
    }, [dispatch, postId, memberId]);



    const onChange = async (emojiType) => {
        const formData = {
            "emojis": emojiType,
            "postId": postId
        }
        setLoading(true);
        dispatch(addEmojiAction(postId, formData, memberId))
            .finally(() => {
                setShowEmojis(false)
                setLoading(false)
            })
    }


    const UiEmojis = useDisplayEmojis({ showEmojis, setShowEmojis, onChange });


    const removeEmoji = async () => {
        setLoading(true);
        dispatch(removeEmojiAction(postId, emojiId))
            .finally(() => setLoading(false))
    }


    return (
        <div className="member-emojis">
            { loading
                ? (
                    <div className="member-emojis-load">
                        <ButtonLoading size={"16px"} />
                    </div>
                )
                : emojiError ? (
                        <div>
                            <FontAwesomeIcon icon={faExclamation} size={"14px"} />
                        </div>
                    )
                : (emojiId ? (
                        <div className="selected">
                            <div onClick={removeEmoji}
                                    disabled={loading}
                                    className="remove-emoji"
                            >
                                <FontAwesomeIcon icon={faXmark}/>
                            </div>
                            <div className="user-emoji">
                                {emojisInput[memberEmoji?.emojis]}
                            </div>
                        </div>
                    ) : UiEmojis)
            }
        </div>
    )
}

export default memo(MemberEmoji);