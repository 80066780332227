export default function AboutSection() {

    const shareContent = async () => {
        const shareData = {
            title: 'InSpace',
            text: 'Share your everything personal photos on spaces' +
                'Connect in Spaces-a small social media for your known relations',
            url: 'https://inspace.website'
        };

        if (navigator.share) {
            try {
                await navigator.share(shareData);
                console.log('Content shared successfully');
            } catch(e) {
                alert("Sharing failed with error: " + e);
            }
        } else {
            try {
                await navigator.clipboard.writeText(shareData.url);
                alert('Link is copied to your clipboard');
            } catch (error) {
                alert("Sharing is failed");
            }
        }
    };

    const mailToLink = () => {
        window.location.href = 'mailto:surendraindian83@gmail.com'
    };


    return (
        <div>
            <div className="setting" onClick={() => mailToLink()}>
                <div className="share-inspace"
                        onClick={() => mailToLink()}
                >
                    Contact Us
                </div>
            </div>
            <div className="setting"
                 onClick={() => shareContent()}
            >
                <div className="share-inspace">
                    Share InSpace
                </div>
            </div>
        </div>
    )

}