import React, {Fragment, useEffect} from "react";
import {Link} from "react-router-dom";
import {getMemberPageAction} from "../service/actions/spaceActions";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentSpace} from "../service/selectors/spaceSelectors";
import MemberPosts from "../components/main/MemberPosts";


export default function InSpaceUserPage() {
    const {currentMember, memberPages} = useSelector(getCurrentSpace);
    const {memberId} = currentMember;
    const {profile, username, name} = currentMember.user;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMemberPageAction(memberId));
    }, [dispatch, memberId]);


    return (
        <>
            <div className="member-profile">
                <img src={profile} alt="profile"/>
                <p className="member-name">{username}</p>
                <p className="member-username">{name}</p>

                <Link to="/account">
                    <button className="view-profile">
                        View profile
                    </button>
                </Link>
            </div>
            {memberPages[memberId] && <MemberPosts memberId={memberId}/>}
        </>
    )
}