import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {shiftAdminAction} from "../../service/actions/spaceActions";


const ShiftAdminModal = ({targetUserId, targetUsername, onClose, dispatch}) => {
    const [loading, setLoading] = useState(false);

    const handleChangeAdmin = async () => {
        setLoading(true);
        await dispatch(shiftAdminAction(targetUserId, onClose));
        setLoading(false);
    }

    return (
        <div className="modal-card">
            <button className="modal-close" onClick={onClose}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
            <div className="modal-content">
                Would like to shift your admin to {targetUsername}
            </div>
            <button className="modal-confirm" onClick={handleChangeAdmin}>
                {loading ? "loading": "I like to shift admin"}
            </button>
        </div>
    )
}

export default ShiftAdminModal;