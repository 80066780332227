import {Fragment, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFormik} from "formik";
import {signUpSchema} from "../../schemas/authSchemas";
import {faArrowLeftLong, faCircleExclamation, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {signUpAction} from "../../service/actions/authActions";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";


export default function SignUpProfile({email, onBack}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = async (values) => {
        await dispatch(signUpAction(values, navigate, values.email));
    }

    const handleTrimChange = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value.trim();
        handleChange({
            target: { name, value: trimmedValue },
        });
    };

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            username: "",
            name: "",
            password: "",
            email: email
        },
        validationSchema: signUpSchema,
        onSubmit
    })




    return (
        <Fragment>
            <header className="signup-profile__header">
                <button onClick={() => onBack()}
                    className="signup-profile__back-button">
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                </button>
            </header>

            <section className="signup-profile__section">
                <div className="signup-profile__heading">
                    Sign up
                </div>

                <form className="signup-profile__form" onSubmit={handleSubmit}>
                    <div className="signup-profile__input-box">
                        <input
                            id="username"
                            name="username"
                            type="text"
                            placeholder="Username"
                            value={values.username}
                            onChange={handleTrimChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="off"
                            className="signup-profile__input"
                        />
                        {errors.username && touched.username && (
                            <div className="input-validation__error">
                                <span className="input-validation__error-icon">
                                    <FontAwesomeIcon icon={faCircleExclamation}/>
                                </span>
                                {errors.username}
                            </div>
                        )}
                    </div>
                    <div className="signup-profile__input-box">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="off"
                            className="signup-profile__input"
                        />
                        {errors.name && touched.name && (
                            <div className="input-validation__error">
                                <span className="input-validation__error-icon">
                                    <FontAwesomeIcon icon={faCircleExclamation}/>
                                </span>
                                {errors.name}
                            </div>
                        )}
                    </div>
                        <div className="signup-profile__input-box">
                            <div className="signup-profile__password-field">
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    autoComplete="off"
                                    className="signup-profile__input"
                                />
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="signup-profile__eye-icon"
                                    style={{color: showPassword ? "#fff" : "#6f6f6f"}}
                                />
                            </div>
                            {errors.password && touched.password && (
                                <div className="input-validation__error">
                                <span className="input-validation__error-icon">
                                    <FontAwesomeIcon icon={faCircleExclamation}/>
                                </span>
                                    {errors.password}
                                </div>
                            )}
                        </div>
                    <div className="signup-profile__button-wrapper">
                        <button
                            className="signup-profile__submit-button"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Creating..." : "Create Account"}
                        </button>
                    </div>
                </form>
            </section>
        </Fragment>
    )
}