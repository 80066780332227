import React from "react";

const FileUploadButton = ({ label, accept, capture, onChange }) => (
    <>
        <label htmlFor="file-upload" className="select-image-btn">
            {label}
        </label>
        <input
            type="file"
            accept={accept}
            capture={capture}
            id="file-upload"
            onChange={onChange}
        />
    </>
);

export default FileUploadButton;