import { Link } from "react-router-dom";
import {SelectImage} from "../components/upload/SelectImage";
import {useEffect, useState} from "react";
import {getPostsCountAction} from "../service/actions/spaceActions";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentSpace} from "../service/selectors/spaceSelectors";
import ResponseLoading from "../components/loader/ResponseLoading";
import {POST_COUNT_ERROR} from "../service/constants/spaceConstants";


function InSpaceUploadPage() {
  const dispatch =  useDispatch();
  const {spaceId, todayPosts} = useSelector(getCurrentSpace);
  const [loading, setLoading] = useState(false);
  const {errors} = useSelector(state => state.app);
  const postsCountError = errors[spaceId]?.error === POST_COUNT_ERROR || false;


  useEffect(() => {
    if(postsCountError) return;
    setLoading(true);
    dispatch(getPostsCountAction(spaceId))
        .finally(() => {
          setLoading(false)
        })
  }, [dispatch, spaceId]);



  if(loading) {
    return (
        <ResponseLoading />
    )
  }

  if(postsCountError) {
    return (
        <div className="member-not-found">
          Post Count Error.
        </div>
    )
  }

  return (
    <div>
      {(todayPosts >= 0 && todayPosts <= 2) ?
          <div>
            <SelectImage count={todayPosts}/>
          </div>
      : <div>
          <p>You reached today limit</p>
          <Link to={"../home"}>Back to home</Link>
        </div>
      }
    </div>
  )
}

export default InSpaceUploadPage;