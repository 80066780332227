import React, {Fragment, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getMemberPageAction} from "../service/actions/spaceActions";
import {getCurrentSpace} from "../service/selectors/spaceSelectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faCircleUp} from "@fortawesome/free-solid-svg-icons";
import {useHandleBack} from "../hooks/useHandleBack";
import ShiftAdminModal from "../components/modal/ShiftAdminModal";
import RemoveMemberModal from "../components/modal/RemoveMemberModal";
import MemberPosts from "../components/main/MemberPosts";
import {MEMBER_PAGE_ERROR} from "../service/constants/spaceConstants";


// Todo: get id from params if not in state then get member from backend or context
// Todo: Please look up some changes happened.
export default function InSpaceMemberPage() {
    const {currentMember, memberPages} = useSelector(getCurrentSpace);
    const isAdmin = currentMember?.admin;
    const {search, state} = useLocation();
    const params = new URLSearchParams(search);
    const memberId = state ? state : Number(params.get("id"));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const member = useSelector(state => state.space.members)[memberId];
    const [loading, setLoading] = useState(false);
    const [adminModalOpen, setAdminModalOpen] = useState(false);
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [memberNotFound, setMemberNotFound] = useState(false);
    const {errors} = useSelector(state => state.app);
    const memberPageError = errors[memberId]?.error === MEMBER_PAGE_ERROR || false;
    const handleBack = useHandleBack();


    useEffect(() => {
        if(currentMember.memberId === memberId) {
            navigate("../your-page");
            return;
        }
        const fetchData = async () => {
            if(memberPageError) return;
            setLoading(true);
            try {
                if (memberId) {
                    await dispatch(getMemberPageAction(memberId));
                } else {
                    setMemberNotFound(true);
                }
            } catch(err) {
                setMemberNotFound(true);
            }
        };
        fetchData()
            .finally(() => setLoading(false));
    }, [dispatch, memberId, member]);


    if(loading) {
        return <div className="center">Loading...</div>
    }


    if(memberNotFound || memberPageError) {
        return (
            <Fragment>
                <header className="member_header">
                    <div className="member_sub-header">
                        <button onClick={handleBack} className="button">
                            <img src="/line.svg" alt="back"/>
                        </button>
                        <div className="setting_title">
                            Member
                        </div>
                    </div>
                </header>
                <section className="member-not-found">
                    {memberPageError ? "Unexpected Error occurred" : "Member Not Found"}
                </section>

            </Fragment>
        )
    }


    return (
        <Fragment>
            <header className="member_header">
                <div className="member_sub-header">
                    <button onClick={handleBack} className="button">
                        <img src="/line.svg" alt="back"/>
                    </button>
                    <div className="setting_title">
                        Member
                    </div>
                </div>
                {isAdmin && (
                    <div className="icons">
                        <button
                            onClick={() => setAdminModalOpen(true)}
                            disabled={adminModalOpen}
                        >
                            <FontAwesomeIcon icon={faCircleUp}/>
                        </button>
                        <button
                            onClick={() => setRemoveModalOpen(true)}
                            disabled={removeModalOpen}
                        >
                            <FontAwesomeIcon icon={faArrowRightFromBracket}/>
                        </button>
                    </div>
                )}
            </header>
            <section className="member-page">
                <div className="member-profile">
                    <img src={member?.user?.profile} alt="profile"/>
                    <p className="member-name">{member?.user?.username}</p>
                    <p className="member-username">{member?.user?.name}</p>

                    <Link
                        to={`/profile/${member?.user?.username}`}
                        state={{from: "/"}}
                    >
                        <button
                            className="view-profile"
                        >
                            View profile
                        </button>
                    </Link>
                </div>
                {memberPages[memberId] && <MemberPosts memberId={memberId}/>}
            </section>

            {adminModalOpen && (
                <ShiftAdminModal
                    targetUserId={memberId}
                    targetUsername={member?.user?.username}
                    onClose={() => setAdminModalOpen(false)}
                    dispatch={dispatch}
                />
            )}

            {removeModalOpen && (
                <RemoveMemberModal
                    memberId={memberId}
                    onClose={() => setRemoveModalOpen(false)}
                    dispatch={dispatch}
                />
            )}
        </Fragment>
    )
}