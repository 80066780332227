import {useEffect, useState} from "react";
import { useInView } from "react-intersection-observer";
import {useDispatch, useSelector} from "react-redux";
import {getActivityAction} from "../../service/actions/spaceActions";
import {getActivities} from "../../service/selectors/spaceSelectors";
import {ButtonLoading} from "../loader/ButtonLoading";

export default function Activities({spaceId}) {
  const dispatch = useDispatch();
  const { ref, inView } = useInView();
  const [loading, setLoading] = useState(false);
  const activities = useSelector(state => getActivities(state, spaceId)) || {};
  let {page, body} = activities;


  useEffect(() => {
    const fetchActivities = async () => {
      if (!page || (page?.hasNext && inView && !loading)) {
        setLoading(true)
        const fetchPage = page ? page.currentPage + 1 : 0;
        await dispatch(getActivityAction(fetchPage, spaceId))
        setLoading(false);
      }
    }
    fetchActivities();
  }, [inView, page, dispatch]);


  return (
    <div className="joined-list">
      {body?.length !== 0 && (body?.map((a, index) => (
          <div key={index} className="joined-item">
            <div className="joined-info">
              <img src={a.user?.profile} alt={a?.user?.username}/>
              <div className="activity-user-info">
                <div className="prompt-member-name">
                  {a.user?.username}
                </div>
                <div className="prompt-member-username">
                  {a.user?.name}
                </div>
              </div>
            </div>
            <div className="status">
              <div
                  ref={body?.length === index + 1 ? ref : null}
                  className={`status-div ${a.prompt?.toLowerCase()}`}
              >
                {a.prompt === 'JOINED' ? "Joined" : a.prompt === 'LEFT' ? "Left"
                    : a.prompt === 'REMOVE' && "Removed"
                }
              </div>
            </div>
          </div>
      )))}
      {
        loading && (
              <div className="activities-load">
                <ButtonLoading size={"16px"} />
              </div>
          )
      }
    </div>
  )
}

// Todo: I found an error on fetch activities
//  screen up and down when page is loading it cause same page load again <Completed>
// I think it solves by add loading condition in useEffect check other components also using this scenario