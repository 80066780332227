import {useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import ResetPassword from "../components/auth/ResetPassword";
import * as Yup from "yup";
import {toast} from "sonner";
import {API} from "../service/api/utils";

function ForgetPasswordVerification() {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("link");


    const validationSchema = Yup.object().shape({
        token: Yup.string()
            .required("Please provide the verification link to proceed.")
            .matches(/^\d{50}$/, "link must be exactly 50 digits."),
    });

    const handleVerify = useCallback(async () => {
        setLoading(true);
        setSuccess(false);
        setError("");

        try {
            const res = await API.get(`auth/forget-password-verification?code=${code}`);
            setSuccess(true);
            const resMsg = res?.data || "Your account has been successfully verified! You can now reset your password.";
            toast.success(resMsg);
        } catch (err) {
            const errMsg = err?.response?.data?.message || "An unexpected error occurred. Please try again later.";
            setError(errMsg);
        } finally {
            setLoading(false);
        }
    }, [setLoading, setSuccess, code]);



    useEffect(() => {
        const verifyToken = async () => {
            if (code) {
                try {
                    await validationSchema.validate({ token: code });
                    await handleVerify();
                } catch {
                    setError("The link you provided is either expired or invalid. Please check your email for a new verification link.");
                    setSuccess(false);
                }
            } else {
                setSuccess(false);
                setError("It seems the verification link is missing. Please try to click the link from your email again.");
            }
        };

        verifyToken();
    }, [code]);



    const handleBack = () => {
        setSuccess(false);
        setError("Page does not work or Invalid token");
    }


    if (success && code) {
        return (
            <ResetPassword
                token={code}
                previous={handleBack}
            />
        )
    }

    return (
        <section className="fpv__section">
            <h1 className="fpv__heading">Password Reset Verification</h1>
            {loading && (
                <div className="fpv__loading-message">
                    Your verification is being processed. Please wait...
                </div>
            )}

            {error && (
                <div className="signup-email__error-message">
                    {error} <br/>
                    <Link to="/signin" replace={true} className="fpv__login-link">
                        Back to login
                    </Link>
                </div>
            )}
        </section>
    )

}

export default ForgetPasswordVerification;