import {useNavigate} from "react-router-dom";

// Todo: Design.
export const FriendSuggestionModal = () => {
    const navigate = useNavigate();

    const handleNavigateToSearch = () => {
        navigate("/search");
    }

    return (
        <div className="friend-suggestion-modal">
            <h3>Start making friends!</h3>
            <p>Search for people below, and we’ll suggest more friends once you’ve added some!</p>
            <div onClick={handleNavigateToSearch} className="explore-button">
                Explore your friends
            </div>
        </div>
    )

}