import React, { useState } from "react";
import UploadPost from "./UploadPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons";
import { useHandleBack } from "../../hooks/useHandleBack";
import imageCompression from "browser-image-compression";
import { toast } from "sonner";
import FileUploadButton from "../shared/FileUploadButton";
import {ButtonLoading} from "../loader/ButtonLoading";

// TODO: Some changes want. <Tackle later>
export const SelectImage = ({ count }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [aspectRatio, setAspectRatio] = useState("1");
    const [mode, setMode] = useState("photo");
    const handleBack = useHandleBack();
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
    const compressionOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        exifOrientation: true,
        initialQuality: 0.75,
    };

    const handleSelectImage = (e) => {
        setLoading(true);
        handleFileSelection(e)
            .finally(() => {
                setLoading(false)
            });
    }

    const handleFileSelection = async (e) => {
        const file = e.target?.files[0];
        if (!file) {
            toast.error("Image is not valid");
            return;
        }

        if (!validImageTypes.includes(file.type)) {
            toast.error("Invalid file type. Only JPG, PNG, and JPEG are allowed.");
            return;
        }

        try {
            const compressedFile = await compressImage(file);
            if (!compressedFile) {
                toast.error("Image can be corrupted");
                return;
            }
            const imageUrl = URL.createObjectURL(compressedFile);
            setImageSrc(imageUrl);
            setImage(compressedFile);
            const dimensions = await getImageDimensions(file);
            setAspectRatio(enforceAspectRatio(dimensions.width, dimensions.height));
        } catch (error) {
            toast.error("Something went wrong during image processing.");
        }
    };

    const compressImage = async (file) => {
        try {
            const compressedFile = await imageCompression(file, compressionOptions);
            const fileSizeInMB = compressedFile.size / (1024 * 1024);
            if (fileSizeInMB > 10) {
                toast.error("Image size exceeds 10MB after compression.");
                return null;
            }

            return compressedFile;
        } catch (error) {
            toast.error("Image compression failed.");
            return null;
        }
    };

    const getImageDimensions = (file) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve({ width: img.width, height: img.height });
            img.src = URL.createObjectURL(file);
        });
    };

    function enforceAspectRatio(width, height) {
        const aspectRatio = width / height;

        let result;
        if (aspectRatio === 1) {
            result = "1 / 1";
        } else if (aspectRatio === 4 / 5) {
            result = "4 / 5";
        } else if (aspectRatio === 4 / 3) {
            result = "4 / 3";
        } else {
            result = "3 /4";
        }

        return result;
    }

    if (imageSrc && image) {
        return (
            <UploadPost
                image={image}
                url={imageSrc}
                onBack={() => setImageSrc(null)}
                aspectRatio={aspectRatio}
            />
        );
    }

    return (
        <>
            <header className="activity_header">
                <button onClick={handleBack} className="button">
                    <img src="/line.svg" alt="back" />
                </button>
            </header>
            <section className="upload-post-1">
                <div className="add-post">Add Post</div>
                <div className="upload-post-bar">
                    {loading ?
                        (
                            <div className="upload-post-load">
                                <ButtonLoading size={"16px"} />
                            </div>
                        ) : (
                            mode === "photo" ? (
                                <FileUploadButton
                                    label="Open Camera"
                                    accept="image/*"
                                    capture="camera"
                                    onChange={handleSelectImage}
                                />
                            ) : (
                                <FileUploadButton
                                    label="Choose Photo"
                                    accept="image/*"
                                    onChange={handleSelectImage}
                                />
                            )
                        )
                    }
                    <div className="post-limit">
                        You have {3 - count} posts left in your space.
                    </div>
                </div>
                <div className="upload-post-icons">
                    <IconButton
                        icon={faCamera}
                        mode="photo"
                        currentMode={mode}
                        setMode={setMode}
                    />
                    <IconButton
                        icon={faImage}
                        mode="gallery"
                        currentMode={mode}
                        setMode={setMode}
                    />
                </div>
            </section>
        </>
    );
};

const IconButton = ({ icon, mode, currentMode, setMode }) => (
    <button
        onClick={() => setMode(mode)}
        className={`image-mode ${currentMode === mode ? "active-mode" : ""}`}
    >
        <FontAwesomeIcon icon={icon} className="image-icon" />
    </button>
);