import {useMemo} from "react";
import { Navigate, Outlet } from "react-router-dom";
import {useSelector} from "react-redux";


const PublicRoute = () => {
    const {isAuthenticated, userData} = useSelector(state => state.auth);


    const checkAuth = useMemo(() => {
        return (isAuthenticated, userData, accessToken) => {
            return isAuthenticated && accessToken && userData;
        };
    }, []);
    const token = localStorage.getItem("profile");
    const accessToken = JSON.parse(token)?.access_token;

    return checkAuth(isAuthenticated, userData, accessToken) ?
        <Navigate to="/explore" /> : <Outlet />
};

export default PublicRoute;