import React, { useState, useEffect, useMemo } from "react";
import {useNavigate} from "react-router-dom";
import debounce from "lodash/debounce";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {useHandleBack} from "../hooks/useHandleBack";
import UserBanner from "../components/shared/UserBanner";
import {ButtonLoading} from "../components/loader/ButtonLoading";
import {API} from "../service/api/utils";


const Search = () => {
    const {userData} = useSelector(state => state.user);
    const userId = userData?.userId;
    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState("");
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const accessToken = JSON.parse(localStorage.getItem("profile"))?.access_token;
    const setInitialValue = () => {
        setUsers([]);
        setLoading(false);
    };
    const handleBack = useHandleBack();


    const debouncedHandleSearch = useMemo(
        () =>
            debounce((q) => {
                setLoading(true);
                const encodedQuery = encodeURIComponent(q);
                setUsers([]);
                API.get(`/users/search?query=${encodedQuery}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        const users = res.data;
                        setUsers(users);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }, 1000),
        [accessToken]
    );


    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value === "") {
            setInitialValue();
            return;
        }

        debouncedHandleSearch(value);
    };

    const clearValues = () => {
        setInitialValue();
        setInputValue("");
    };

    useEffect(() => {
        return () => {
            setInitialValue();
        };
    }, []);


    return (
        <section>
            <header className="search_header">
                <button onClick={handleBack} className="button">
                    <img src="/back.svg" alt="back-button" style={{color: "#007bff"}}/>
                </button>
                <div className="search-bar">
                    <input
                        className="search-input"
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="find your friends"
                    />
                    <span className={`search-user-icon ${inputValue ? "active" : ""}`}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} style={{fontSize: "17px"}}/>
                    </span>
                    {inputValue !== "" && (
                        <div className="clear-btn">
                            {loading
                                ? ( <ButtonLoading size={"11px"} />)
                                : (
                                    <button onClick={clearValues}>
                                        <FontAwesomeIcon
                                            icon={faCircleXmark}
                                            style={{color: "#6f6f6f", fontSize: "16px"}}
                                        />
                                    </button>
                                )
                            }
                        </div>
                    )}
                </div>
            </header>

            {users.length > 0 && (
                <div className="searched-users">
                    {users.map((user) => (
                        <div key={user.userId} className="friend">
                            <UserBanner user={user}/>
                            <div
                                className="view"
                                onClick={() => {
                                    navigate(userId === user.userId
                                            ? "/account"
                                            : `/profile/${user.username}`
                                    );
                                    clearValues();
                                }}
                            >
                                <div className="view-btn">View</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </section>
    )
};

export default Search;
