import {
    ADD_ACTION_LOADING,
    POP_HISTORY,
    PUSH_HISTORY, REMOVE_ACTION_LOADING, REMOVE_ERROR,
    RESET_HISTORY, RESET_SCROLL_POSITION, SET_ERROR, SET_ERROR_RETRIES,
    SET_SCROLL_POSITION, SET_SHOW_POST
} from "../constants/appConstants";
import {LOGOUT} from "../constants/authConstants";
import {CLEAR_CURRENT_SPACE} from "../constants/spaceConstants";

const initialState = {
    history: [],
    scrollPosition: {},
    showPost: {},
    appLoading: {},
    actionLoading: {},
    errors: {},
    responseMessages: {},
    validationErrors: {}
}


export default function appReducer(state=initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case LOGOUT: {
            return {
                ...state,
                history: [],
                showPost: {},
                scrollPosition: {},
                appLoading: {},
                actionLoading: {},
                errors: {},
                responseMessages: {},
                validationErrors: {}
            }
        }
        case PUSH_HISTORY: {
            return {
                ...state,
                history:  [...state.history, payload]
            }
        }
        case POP_HISTORY: {
            return {
                ...state,
                history: state.history.slice(0,-1)
            }
        }
        case CLEAR_CURRENT_SPACE:
        case RESET_HISTORY: {
            return {
                ...state,
                history: []
            }
        }
        case SET_SCROLL_POSITION: {
            const {memberId, position} = payload;
            return {
                ...state,
                scrollPosition: {
                    ...state.scrollPosition,
                    [memberId]: position
                }
            }
        }
        case RESET_SCROLL_POSITION: {
            return {
                ...state,
                scrollPosition: {
                    ...state.scrollPosition,
                    [payload]: 0
                }
            }
        }
        case ADD_ACTION_LOADING: {
            const {key, action} = payload;
            return {
                ...state,
                actionLoading: {
                    ...state.actionLoading,
                    [key]: action
                }
            }
        }
        case REMOVE_ACTION_LOADING: {
            const {[payload]: removedAction, ...remainingLoadings} = state.actionLoading
            return {
                ...state,
                actionLoading: remainingLoadings
            }
        }
        case SET_SHOW_POST: {
            const {key, value} = payload;
            return {
                ...state,
                showPost: {
                    ...state.showPost,
                    [key]: value
                }
            }
        }
        case SET_ERROR: {
            const {key, error} = payload;
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [key]: {error, message: payload?.message}
                }
            }
        }
        case REMOVE_ERROR: {
            const {[payload]: removedError, ...remainingErrors} = state.errors
            return {
                ...state,
                errors: remainingErrors
            }
        }
        default:
            return state;
    }
}