import React, {Fragment} from "react";
import { Outlet } from "react-router-dom";
import PsFooter1 from "./PsFooter1";
import PsHeader1 from "./PsHeader1";

export default function PsLayout1() {
  return (
    <Fragment>
        <PsHeader1 />
        <Outlet />
        <PsFooter1 />
    </Fragment>
  )
}