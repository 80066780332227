import * as types from "../constants/authConstants";
import {SIGN_IN_SUCCESS, SIGNIN_FAIL, UPDATE_USERNAME} from "../constants/authConstants";

const initialState = {
    isAuthenticated: false,
    userData: null,
    refreshToken: null,
    accessToken: null,
    loading: false,
    signInError: null,
    signUpError: [],
    successMessage: null,
}


export default function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.SET_USER_DATA:
            return {
                ...state,
                userData: payload ? payload.user : null,
                isAuthenticated: payload ? payload.isAuthenticated : false
            };
        case types.UPDATE_USER_DATA:
            return {
                ...state,
                userData: payload.user
            }
        case types.SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: payload ? payload : null,
            };
        case types.SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: payload ? payload : null,
            };
        case types.SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload ? payload : null,
            };

        case types.SIGNUP_SUCCESS:
            return {
                ...state,
                signInError: null,
                signUpError: [],
                successMessage: payload ? payload : null,
            };

        case types.SIGNUP_FAIL:
            return {
                ...state,
                successMessage: null,
                signInError: null,
                signUpError: payload ? payload : [],
            };

        case UPDATE_USERNAME:
        case SIGN_IN_SUCCESS:
            return {
                ...state,
                userData: payload ? payload.user : null,
                accessToken: payload ? payload.access_token : null,
                refreshToken: payload ? payload.refresh_token : null,
                isAuthenticated: payload ? payload.isAuthenticated : false,
                signInError: null
            };

        case SIGNIN_FAIL:
            return {
                ...state,
                successMessage: null,
                signUpError: [],
                signInError: payload ? payload : null,
            };

        case types.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                userData: null,
                refreshToken: null,
                accessToken: null,
                signInError: null,
                signUpError: [],
                successMessage: null
            };

        case types.REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                isAuthenticated: !!payload,
                userData: payload ? payload.user : null,
                accessToken: payload ? payload.access_token : null,
                refreshToken: payload ? payload.refresh_token : null,
            };

        case types.REFRESH_TOKEN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                userData: null,
                refreshToken: null,
                accessToken: null,
                signUpError: [],
                signInError: null,
                successMessage: null
            };

        case types.CLEAR_MESSAGE:
            return {
                ...state,
                successMessage: null,
                signInError: null,
                signUpError: [],
            };

        default:
            return state;
    }
}