import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {removePostAction} from "../../service/actions/spaceActions";
import {toast} from "sonner";

const DeletePostModal = ({post, navigate, onClose, dispatch}) => {
    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        setLoading(true);
        await dispatch(removePostAction(post?.memberId, post))
            .then(() => {
                if(navigate) {
                    navigate(-1);
                }
                onClose();
            }).catch(() => {
                toast.error("Failed to delete the post try again later")
            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="modal-card">
            <button className="modal-close" onClick={onClose}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
            <div className="modal-content">
                Would like to delete your post
            </div>
            <button className="modal-confirm" onClick={handleDelete}>
                {loading ? "loading": "I like to delete the post"}
            </button>
        </div>
    )
}

export default DeletePostModal;