export const PUSH_HISTORY = "PUSH_HISTORY";
export const POP_HISTORY = "POP_HISTORY";
export const RESET_HISTORY = "RESET_HISTORY";

export const SET_SCROLL_POSITION = "SET_SCROLL_POSITION";
export const RESET_SCROLL_POSITION = "RESET_SCROLL_POSITION";

export const ADD_ACTION_LOADING = "ADD_ACTION_LOADING";
export const REMOVE_ACTION_LOADING = "REMOVE_ACTION_LOADING";

export const SET_ERROR = "SET_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const SET_ERROR_RETRIES = "SET_ERROR_RETRIES";

export const SET_SHOW_POST = "SET_SHOW_POST";

// headers
export const AUTH_HEADER = 'Authorization';
export const SPACE_AUTH_HEADER = "x-space-token";
export const MEMBER_ID_HEADER = 'X-Member-Id';
export const SPACE_ID_HEADER = 'X-Space-Id';


// space request status
export const OUTGOING_REQUEST = "PROCESS";
export const NO_REQUEST = "DECLINE";


// Shared Loading Actions.
export const SPACE_UPDATING = "space updating";
export const ADD_FRIEND_REQUEST_ACTION = "Add Friend Request Loading";
export const HANDLE_FRIEND_REQUEST_ACTION = "Handle Friend Request Loading";
export const REMOVE_FRIEND_REQUEST_ACTION = "Remove Friend Request Loading";
export const SPACE_REQUEST_ACTION = "Add or Remove Space Request Loading";

export const NETWORK_ERROR = "Network error";
