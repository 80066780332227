import {memo, Suspense} from "react";

const UserDiv = ({user}) => {
    const {profile, name, username} = user;

    return (
        <Suspense fallback={"loading.."}>
            <div className="post-user-info">
                <div className="post-user">
                    <img src={profile} alt={`${name} profile image`}/>
                    <div className="post-user-content">
                        <div className="post-user-name">{username}</div>
                        <div className="post-user-username">{name}</div>
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

export default memo(UserDiv);