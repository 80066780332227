import {Fragment, useEffect, useMemo, useState} from "react"
import {NavLink, Outlet, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getProfileInfoAction} from "../../service/actions/userActions";
import {ButtonLoading} from "../loader/ButtonLoading";
import UnFriendModal from "../modal/UnFriendModal";
import {PROFILE_INFO_ERROR} from "../../service/constants/userConstants";


const FriendProfile = ({profile}) => {
    const {user, friendsId} = profile;
    const dispatch = useDispatch();
    const {friendsOfFriends, friendSpaces} = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const {errors} = useSelector(state => state.app)
    const profileInfoError = errors[friendsId]?.error === PROFILE_INFO_ERROR || false;
    const location = useLocation();

    const isSpacesActive =(location.pathname.startsWith("/profile") && !location.pathname.endsWith("/friends"));

    const info = useMemo(() => {
        const friend = friendsOfFriends[friendsId]?.friends?.length;
        const space = friendSpaces[friendsId]?.spaces?.length;
        return {friend, space};
    }, [friendsOfFriends, friendSpaces, friendsId])




    useEffect(() => {
        setLoading(true);
        dispatch(getProfileInfoAction(friendsId, user?.userId))
            .finally(() => setLoading(false))
    }, [dispatch, user]);


    return (
        <Fragment>
            <div className="profile-card">

                <div className="profile">
                    <div className="profile-info">
                        <img src={user.profile} alt={user?.username}/>
                        <div className="profile-content">
                            <p className="name">{user?.name}</p>
                            <p className="username">{user?.username}</p>
                        </div>
                    </div>
                    <div className="user-bio">
                        {user.status && user.status}
                    </div>
                </div>
                <div className="btn">
                    <button
                        className="unfriend-btn"
                        onClick={() => setModalOpen(true)}
                        disabled={modalOpen}
                    >
                        UnFriend
                    </button>
                </div>

                {modalOpen && (
                    <UnFriendModal
                        dispatch={dispatch}
                        user={user}
                        friendsId={friendsId}
                        onClose={() => setModalOpen(false)}
                    />
                )}


                <div className="profile-index">
                    <NavLink to="spaces">
                        <div className="index">
                            <div className="option-count">
                                {loading
                                    ? <ButtonLoading size={"10px"} />
                                    : profileInfoError ? <div>unexpected error occurred</div>
                                    : info?.space > 9 || info?.space < 1 ? info?.space : `0${info?.space}`
                                }
                            </div>
                            <div className="option-name" style={isSpacesActive ? {color: "#3083FFFF"} : null}>Spaces</div>
                        </div>
                    </NavLink>
                    <NavLink to="friends">
                        <div className="index">
                            <div className="option-count">
                                {loading
                                    ? <ButtonLoading size={"10px"} />
                                    : profileInfoError ? <div>unexpected error occurred</div>
                                    : info?.friend > 9 || info?.friend < 1 ? info?.friend : `0${info?.friend}`
                                }
                            </div>
                            <div className="option-name" style={!isSpacesActive ? {color: "#3083FFFF"} : null}>Friends</div>
                        </div>
                    </NavLink>
                </div>
            </div>

            {loading
                ? ( <div className="profile-loading">
                        Loading...
                    </div>
                )
                : profileInfoError
                    ? (
                        <div>
                            Unexpected error occurred
                        </div>
                    )
                : <Outlet context={friendsId}/>
            }
        </Fragment>
  )
}

export default FriendProfile;


// Todo: space request is remained because the joinSpaceModal shared. <complete>
// Todo: use dialog for unfriend. <complete>