import {useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {toast} from "sonner";
import {useFormik} from "formik";
import {signupVerification} from "../schemas/authSchemas";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {API} from "../service/api/utils";


const SignUpVerification = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const email = location.state ? location.state : null;
    const [error, setError] = useState("");


    const onSubmit = async (values, actions) => {
        if(!email) {
            setError("Page is expired. Try again later");
            return;
        }
        setError("");
        const formData = new FormData();

        formData.append("token", values.code);
        formData.append("email", email);

        try {
            const res = await API.post("/auth/signup-verification", formData);
            console.log(res);
            toast.success(res?.data || "Verification successful!");
            navigate("/signin", { replace: true });
            actions.resetForm();
        } catch (error) {
            const response = error?.response?.data;
            if (response?.message === "Email is already verified" && response?.status === 409) {
                navigate("/signin", { replace: true });
            } else if (response?.message === "Validation failed") {
                toast.error(response?.message[0] || "Validation error");
            } else {
                const errMsg = response?.message || "Unexpected error occurred";
                setError(errMsg);
            }
        }
    };


    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            code: ""
        },
        validationSchema: signupVerification,
        onSubmit
    })

    return (
        <section className="signup-verification">
            <div className="signup-verification__header">Verify Your Email Address</div>
            <div className="signup-verification__message">
                A verification code has been sent to your email address. Please enter the 7-digit verification code
                below to complete your registration:
            </div>


            <form onSubmit={handleSubmit} className="signup-verification__form">
                <div className="signup-verification__input-box">
                    <input
                        id="code"
                        name="code"
                        type="text"
                        required
                        placeholder="Enter your 7 digit code."
                        className="signup-verification__input"
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoComplete="off"
                        aria-label="Verification Code"
                        maxLength={7}
                    />
                    {errors.code && touched.code && (
                        <div className="input-validation__error">
                            <span className="input-validation__error-icon">
                                <FontAwesomeIcon icon={faCircleExclamation}/>
                            </span>
                            {errors.code}
                        </div>
                    )}
                </div>
                <div className="signup-verification__buttons">
                    <button
                        disabled={isSubmitting}
                        type="submit"
                        className="signup-verification__submit-button"
                    >
                        {isSubmitting ? "Verifying..." : "Verify"}
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            navigate("/signup", {replace: true});
                        }}
                        className="signup-verification__cancel-button"
                    >
                        Cancel
                    </button>
                </div>
            </form>


            {error && (
                <div className="signup-email__error-message">
                    {error}
                </div>
            )}
        </section>
    );
};

export default SignUpVerification;