import {Fragment, useEffect, useMemo, useState} from "react"
import AddRequest from "../shared/AddRequest";
import RemoveRequest from "../shared/RemoveRequest";
import AcceptRejectBtn from "../shared/AcceptRejectBtn";
import {getProfileStatusAction} from "../../service/actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoading} from "../loader/ButtonLoading";
import {PROFILE_STATUS_ERROR} from "../../service/constants/userConstants";

const PrivateProfile = ({profile}) => {
    const {user, request} = profile;
    const dispatch = useDispatch();
    const {incomingRequests, outgoingRequests} = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const {errors} = useSelector(state => state.app)
    const requestError = errors[user?.userId]?.error === PROFILE_STATUS_ERROR || false;


    const incomingRequest = useMemo(() => {
        return incomingRequests?.find(request =>
            request?.user.userId === user.userId
        )
    }, [user, incomingRequests]);

    const outgoingRequest = useMemo(() => {
        return outgoingRequests?.find(request =>
            request?.user.userId === user.userId
        )
    }, [user, outgoingRequests]);


    useEffect(() => {
        if(!request && !requestError) {
            setLoading(true);
            dispatch(getProfileStatusAction(user.userId))
                .finally(() => setLoading(false));
        }
    }, [request, dispatch, user])

    return (
        <Fragment>
            <div className="profile-card">
                <div className="profile">
                    <div className="profile-info">
                        <img src={user.profile} alt={user?.username}/>
                        <div className="profile-content">
                            <p className="name">{user?.name}</p>
                            <p className="username">{user?.username}</p>
                        </div>
                    </div>
                    <div className="user-bio">
                        {user?.status && user?.status}
                    </div>
                </div>
            </div>

            <div className="prompt">
                  <div className="icon">
                      <img src="/Lock.svg" alt="lock" />
                  </div>
                  <div className="text">
                      Friends only allow to see <br/>
                      this profile.
                  </div>
              </div>

            <div className="private-profile-btn">
                {loading
                    ? <ButtonLoading size={"16px"} />
                    : requestError
                        ? <div>Unexpected error occurred.</div>
                    : (request || incomingRequest || outgoingRequest)
                        && (incomingRequest
                                ? <AcceptRejectBtn
                                        request={incomingRequest}
                                        text1="Accept" text2="Decline"
                                        className1="accept-btn"
                                        className2="reject-btn"
                                        className3="profile-accept-btn"
                                  />
                                :
                                (outgoingRequest
                                    ? <RemoveRequest
                                        request={outgoingRequest}
                                        text="Request Added"
                                        className2="added-btn"
                                      />
                                    : <AddRequest
                                        user={user}
                                        text="Add Request"
                                        className1="add-btn"
                                        className2="profile-add-btn"
                                       />
                                )
                        )
                }
            </div>

        </Fragment>
    )
}

export default PrivateProfile;