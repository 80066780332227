import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {changePasswordSchema} from "../schemas/authSchemas";
import {toast} from "sonner";
import {API} from "../service/api/utils";
import {useDispatch} from "react-redux";
import {POP_HISTORY} from "../service/constants/appConstants";


function ChangePassword() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async (values, actions) => {
        await API.put("users/change-password", values)
            .then(() => {
                actions.resetForm();
                toast.success("Password changed successfully");
                dispatch({type: POP_HISTORY})
                navigate("/settings");
            }).catch((err) => {
                let {status, error, validationErrors, message} = err?.response?.data || {};
                if(status === 400 && error === "Validation failed") {
                    toast.error(validationErrors[0]);
                } else {
                    toast.error(message || "unexpected error occurred")
                }
            });
    };

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmationPassword: ""
        },
        validationSchema: changePasswordSchema,
        onSubmit
    })



    return (
        <section className="change-password">
            <div className="change-password-heading">
                Change Password
            </div>

            <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className="change-password-form">

                <div className="input">
                    <input
                        id="currentPassword"
                        name="currentPassword"
                        type={showPassword ? "text" : "password"}
                        placeholder="old password"
                        value={values.currentPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        autoComplete="off"
                    />
                {errors.currentPassword && touched.currentPassword
                    && <p className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation} />
                            </span>
                        {errors.currentPassword}</p>
                }
                </div>

                <div className="input">
                        <input
                            id="newPassword"
                            name="newPassword"
                            type={showPassword ? "text" : "password"}
                            placeholder="new password"
                            value={values.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="off"
                            className="password-input"
                        />

                        {errors.newPassword && touched.newPassword
                            && <p className="input-validation__error">
                                <span>
                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                </span>
                                {errors.newPassword}</p>
                        }
                </div>

                <div className="input">
                    <input
                        id="confirmationPassword"
                        name="confirmationPassword"
                        type={showPassword ? "text" : "password"}
                        placeholder="confirm password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        autoComplete="off"
                        className="password-input"
                    />

                    {errors.confirmationPassword && touched.confirmationPassword
                        && <p className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation} />
                            </span>
                            {errors.confirmationPassword}</p>
                    }

                </div>

                <div style={{display: "flex", alignItems: "center", gap:"5px"}}>
                    <input
                        style={{width: "20px", height: "20px"}}
                        type="checkbox"
                        onChange={() => setShowPassword(!showPassword)}
                        checked={showPassword}
                    />
                    <p style={{fontSize: "18px"}}>
                        {showPassword ? "Hide Password" : "Show Password"}
                    </p>
                </div>

                <div className="change-password-btn">
                    <button
                        className="change-btn"
                        type="submit"
                        disabled={isSubmitting ||
                            values.confirmationPassword !== values.newPassword ||
                            !values.newPassword
                        }
                    >{isSubmitting
                            ? "loading..."
                            : "Change"
                    }</button>
                </div>

            </form>

            <div className="guides">
                <p>If you not remember old password then</p>
                <div className="step">
                    <div className="step-index">1</div>
                    <p>Logout</p>
                </div>
                <div className="step">
                    <div className="step-index">2</div>
                    <p>Login with wrong credentials</p>
                </div>
                <div className="step">
                    <div className="step-index">3</div>
                    <p>Forget password</p>
                </div>
            </div>
        </section>
    )
}

export default ChangePassword;