import { NavLink, useLocation } from 'react-router-dom';

export default function MainFooter1() {
    const location = useLocation();

    const isExploreActive = location.pathname === '/' || location.pathname === '/explore';

    return (
        <footer className="main-footer">
            <NavLink
                style={isExploreActive ? {color: "#007bff"} : null}
                to="/"
            >
                Explore
            </NavLink>
            <NavLink
                style={({ isActive }) => isActive ? {color: "#007bff"} : null}
                to="/spaces"
            >
                Spaces
            </NavLink>
        </footer>
    );
}
