const UserBanner = ({user}) => {
    const {profile, name, username} = user;

    return (
        <div className="user-banner">
            <img src={profile} alt={username}/>
            <div className="user-banner-content">
                <p className="user-banner-name">
                    {username}
                </p>
                <p className="user-banner-username">
                    {name}
                </p>
            </div>
        </div>
    )

}

export default UserBanner