import {getStore} from "../store";
import {removeError, setError} from "../actions/appActions";
import {NETWORK_ERROR} from "../constants/appConstants";

// Todo: check the 403 forbidden error on spaces.
export const handleApiError = async (error) => {
    const store = await getStore();
    const res = error?.response?.data;
    console.log(error);
    if(error?.message === "Network Error" && error?.name === "AxiosError") {
        await store.dispatch(setError("network", NETWORK_ERROR));
        return;
    } else {
        // Todo: check the error exists in store before remove
        // Todo: I think this is not necessary coz once network failure occur
        //  then refresh page until the app is show the network error msg
        await store.dispatch(removeError("network"));
    }
    try {
        if(res?.status === 400 && res?.error === "Validation failed") {
            return {
                error: {
                    code:  "Validation failed",
                    message: res?.validationErrors[0],
                },
                data: null
            }
        }
        const errorMessage = error.response?.data?.message || "Something went wrong. please try later.";
        return { error: errorMessage, data:null };
    } catch (err) {
        return {error: "unexpected error occurred", data: null}
    }
};
