import React, {memo, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {
  addSpaceRequestAction,
  getSpaceRequestStatusAction,
  removeSpaceRequestAction
} from "../../service/actions/userActions";
import {NO_REQUEST, OUTGOING_REQUEST, SPACE_REQUEST_ACTION} from "../../service/constants/appConstants";
import {ButtonLoading} from "../loader/ButtonLoading";
import {addActionLoading, removeActionLoading} from "../../service/actions/appActions";
import {SPACE_REQUEST_STATUS_ERROR} from "../../service/constants/userConstants";

const JoinSpaceRequest = ({space, className1, className2}) => {
    const dispatch = useDispatch();
    const {uniqueId, spaceId, request} = space;
    const [loading, setLoading] = useState(false);

    const {errors, actionLoading} = useSelector(state => state.app);

    const action = actionLoading[spaceId] || "";
    const statusError = errors[spaceId]?.error === SPACE_REQUEST_STATUS_ERROR || false;

    useEffect(() => {
      const fetchRequestStatus = async () => {
        if(statusError) return;
        setLoading(true);
        await dispatch(getSpaceRequestStatusAction(spaceId))
        setLoading(false);
      }
      fetchRequestStatus();
    }, [dispatch, spaceId]);


    const addRequest = async () => {
      dispatch(addActionLoading(spaceId, SPACE_REQUEST_ACTION));
      dispatch(addSpaceRequestAction(spaceId, uniqueId))
          .finally(() => {
            dispatch(removeActionLoading(spaceId))
          })
    }

    const removeRequest = async () => {
      dispatch(addActionLoading(spaceId, SPACE_REQUEST_ACTION))
      dispatch(removeSpaceRequestAction(spaceId, request?.requestId, uniqueId))
          .finally(() => {
            dispatch(removeActionLoading(spaceId))
          })
    }

    if(loading) return (
        <ButtonLoading size={"10px"} />
    );


    return (
        <div>
            {action === SPACE_REQUEST_ACTION
                ? <ButtonLoading size={"15px"} />
                : statusError ? <div>Unexpected error occurred</div>
                    : (request && (
                        request?.status === OUTGOING_REQUEST ?
                            <button
                                onClick={removeRequest}
                                className={className1}
                            >Request</button>
                            :
                            request?.status === NO_REQUEST &&
                            <button
                                onClick={addRequest}
                                className={className2}
                            >Join</button>
                    ))
            }
        </div>
    )
}

export default memo(JoinSpaceRequest);

// Todo: Better constants for request status. <Complete>
// Todo: Global Loading. <Complete>