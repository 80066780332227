import {Link, useLocation, useOutletContext} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import UserBanner from "../shared/UserBanner";
import {useSelector} from "react-redux";
import {useMemo} from "react";

const FriendOfFriends = () => {
  const {friendsOfFriends, userData, users} = useSelector(state => state.user);
  const friendsId = useOutletContext();
  const {pathname} = useLocation();

  const friends = useMemo(() => {
      const userIds = friendsOfFriends[friendsId]?.friends;
      return userIds?.map(id => users[id]);
  }, [friendsOfFriends, users])

    
  return (
      <div className="friends">
          <div className="head">
            Friends <span> <FontAwesomeIcon icon={faChevronRight} /> </span>
          </div>

          <div className="friends-list">
              {friends?.length > 0 &&
                  friends.map(user => (
                      <div className="friend" key={user.userId}>
                          <UserBanner user={user} />
                          <Link
                            to={
                              userData?.userId === user.userId
                                  ? "/account"
                                  : `/profile/${user.username}`}
                            state={{from: pathname}}
                            className="view"
                          >
                              <div className="view-btn">
                                View
                              </div>
                        </Link>

                      </div>
                  ))
              }
          </div>
    </div>
  )

} 

export default FriendOfFriends;