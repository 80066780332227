import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import Post from './Post';
import UserDiv from "../shared/UserDiv";
import {useDispatch, useSelector} from "react-redux";
import {getFeedAction} from "../../service/actions/spaceActions";
import {getCurrentSpace} from "../../service/selectors/spaceSelectors";
import ResponseLoading from "../loader/ResponseLoading";

const Feed = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {feed, members:ids, spaceId, currentMember} = useSelector(getCurrentSpace);
  const members = useSelector(state => state.space.members);
  const posts = useSelector(state => state.space.posts);
  const [loading, setLoading] = useState(true);

  const basePath = `/space/${location.pathname.split('/')[2]}`;

  useEffect(() => {
    const fetchFeed = async () => {
      if(!feed) {
        setLoading(true);
        await dispatch(getFeedAction(spaceId))
      }
    }
    fetchFeed()
        .finally(() => {
          setLoading(false);
        })
  }, [dispatch, spaceId, feed]);


  const unPostedMembers = useMemo(() => {
    const postedMembers = feed?.map(postId => posts[postId]?.memberId) || [];
    return ids.filter(memberId => !postedMembers.includes(memberId))
              .map(id => members[id]);
  }, [feed, posts, ids, members]);



  if(loading) {
    return <ResponseLoading />
  }

  return (
      <Fragment>
        {(feed && feed?.length > 0) && feed.map(post => <Post postId={post} key={post}/>)}

        {unPostedMembers && unPostedMembers.map(member => {
          const { memberId, user } = member;
          return (
              <div key={memberId} className="no-post-user">
                <Link
                    to={currentMember.memberId === memberId
                        ? `${basePath}/your-page`
                        : `${basePath}/member?id=${memberId}`}
                    state={memberId}
                >
                  <UserDiv user={user} />
                </Link>

                <div className="no-post"></div>
                <div className="post-caption">
                  <p><span>:</span>No Photo</p>
                </div>
              </div>
          );
        })}
      </Fragment>
  )

}

export default Feed;

// Todo: we cannot see the empty post on one member inside a space???