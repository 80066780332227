import {memo} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setScrollAction} from "../../service/actions/appActions";


function MemberPost({posts, showPosts}) {
    const postId = posts[0];
    const post = useSelector(state => state.space.posts)[postId];
    let {sharedOn, image, memberId} = post;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleNavigate = async () => {
        const position = window.scrollY;
        await dispatch(setScrollAction(memberId, position))
        navigate("../post", {
            state: {post:posts, memberId, showPosts}
        })
    }

    return (
        <div className="gallery-post">
            <div onClick={handleNavigate}>
                <img
                    src={image}
                    loading="lazy"
                    alt="Post"
                />
                <div className="post-date">{sharedOn.substring(8, 10)}</div>
            </div>
        </div>
  )
}

export default memo(MemberPost);