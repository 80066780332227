import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getSpaceMembersCountAction} from "../../service/actions/userActions";
import {SET_CURRENT_SPACE} from "../../service/constants/spaceConstants";
import DeleteSpaceModal from "../modal/DeleteSpaceModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import EditSpace from "./EditSpace";
import {getLoadingState} from "../../service/selectors/appSelectors";
import {SPACE_UPDATING} from "../../service/constants/appConstants";
import {ButtonLoading} from "../loader/ButtonLoading";
import {MEMBER_COUNT_ERROR} from "../../service/constants/userConstants";


const Space = ({userSpace}) => {
    const { spacename, uniqueId, admin, spaceId, members } = userSpace;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [membersLoading, setMembersLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const {errors} = useSelector(state => state.app)
    const memberCountError = errors[spaceId]?.error === MEMBER_COUNT_ERROR || false;


    const updating = useSelector(state =>
        getLoadingState(state, spaceId, SPACE_UPDATING)
    );


    useEffect(() => {
        if(!admin || memberCountError) return;
        setMembersLoading(true);
        dispatch(getSpaceMembersCountAction(spaceId)).finally(() => {
                setMembersLoading(false);
            })
    }, [dispatch, spaceId, admin]);


    const handleNavigate = () => {
        if(edit) return;
        dispatch({type: SET_CURRENT_SPACE, payload: spaceId})
            .finally(() => {
                navigate(`../space/${uniqueId}`)
            })
    }


    return (
        <div>
            <div className="space-card">
                <div onClick={handleNavigate}>
                    <EditSpace
                        spaceId={spaceId}
                        spacename={spacename}
                        isEdit={edit}
                        onSuccess={() => setEdit(false)}
                        dispatch={dispatch}
                    />
                    <div className="space-card_id">{uniqueId}</div>
                </div>

                <div className="space-actions">
                    {admin && (
                        <button
                            onClick={() => setEdit(!edit)}
                            className="edit-space"
                            disabled={updating}
                        >
                            {updating ? <div className="loading-spinner"></div>
                                : <FontAwesomeIcon icon={edit ? faXmark: faPen } />
                            }
                        </button>
                    )}

                    {membersLoading
                        ? <ButtonLoading size={"10px"} />
                        : memberCountError ? <div>Unexpected error occurred</div>
                        : ((members && members === 1 && admin) &&
                                <button
                                    onClick={() => setModalOpen(true)}
                                    disabled={modalOpen}
                                    className="delete"
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                        )
                    }
                </div>
            </div>

            {modalOpen && (
                <DeleteSpaceModal
                    onClose={() => setModalOpen(false)}
                    spaceId={spaceId}
                    dispatch={dispatch}
                />
            )}

        </div>
    )
}

export default Space;