import {useState} from "react";
import AddNewEmail from "../components/settings/AddNewEmail";
import {API} from "../service/api/utils";
import {useFormik} from "formik";
import {passwordSchema} from "../schemas/authSchemas";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// Todo: This is also same edit account this things do after auth is done. <Tackle later>
function ChangeEmail() {
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const clearState = () => {
        setIsSuccess(false)
        setError('');
    }

    const onSubmit = async (values, actions) => {
        clearState();
        const formData = new FormData();
        formData.append("password", values.password);
        await API.post("users/validate-password", formData)
            .then(() => {
                actions.resetForm();
                setIsSuccess(true);
            })
            .catch((err) => {
                setError(err?.response?.data?.message || "unexpected error occurred");
            })
    }

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
    } = useFormik({
        initialValues: {
            password: ""
        },
        validationSchema: passwordSchema,
        onSubmit
    })

    if(isSuccess) {
        return <AddNewEmail />
    }


    return (
        <section className="change-email">
            <div className="change-email-heading">Enter your password</div>

            <form
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <div className="input-field">

                    <div className="input-area">
                        <input
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                            autoComplete="off"
                        />
                        <FontAwesomeIcon
                            type="button"
                            icon={showPassword ? faEye : faEyeSlash}
                            onClick={() => setShowPassword(!showPassword)}
                            className="eye-icon"
                            style={{color: showPassword ? "#3083ff" : "#6f6f6f"}}
                        />
                    </div>

                    {errors.password && touched.password
                        && <p className="input-validation__error">
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation} type="button"/>
                            </span>
                            {errors.password}</p>
                    }
                </div>

                <div className="change-email-btn">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="verify-btn"
                    >
                        {isSubmitting
                            ? <div className="loading-spinner"></div>
                            : "Verify"
                        }
                    </button>
                </div>
            </form>

            <p className="email-error">{error && error}</p>

        </section>
    )
}

export default ChangeEmail;