import React, {useEffect, useState} from 'react';
import Feed from "../components/home/Feed";
import {Link, useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {getPostsCountAction, getSpaceMembersAction} from "../service/actions/spaceActions";
import {DateBanner} from "../components/shared/DateBanner";
import {getCurrentSpace} from "../service/selectors/spaceSelectors";
import ResponseLoading from "../components/loader/ResponseLoading";
import {POST_COUNT_ERROR} from "../service/constants/spaceConstants";


export default function InSpaceHome() {
    const dispatch = useDispatch();
    const {todayPosts, members, spaceId} = useSelector(getCurrentSpace);
    const [loading, setLoading] = useState(true);
    const {errors} = useSelector(state => state.app);
    const postsCountError = errors[spaceId]?.error === POST_COUNT_ERROR || false;

    const location = useLocation();
    const basePath = `/space/${location.pathname.split('/')[2]}`;


    useEffect(() => {
        const fetch = async () => {
            setLoading(true);
            await dispatch(getSpaceMembersAction());
            if(postsCountError) return;
            await dispatch(getPostsCountAction(spaceId));
        }
        fetch().finally(() => setLoading(false))
    }, [dispatch, spaceId]);


    if (members.length === 0 && loading) {
        return <ResponseLoading/>
    }


    return (
        <section className="home">
            <div className="today">
                <DateBanner/>
            </div>

            {members.length > 0 && <Feed/>}

            {postsCountError ? <div className="upload-post-btn">Post count error</div>
                : (!loading && todayPosts >= 0 && todayPosts <= 2)
                && (
                    <Link to={`${basePath}/upload`} className="upload-post-btn">
                        <FontAwesomeIcon icon={faCamera} />
                    </Link>
                )
            }
        </section>
    );

}