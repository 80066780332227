import {Section} from "../shared/Section";
import {useLocation} from "react-router-dom";

export default function AccountSection() {
    const {pathname} = useLocation();

    return (
        <>
            <div className="setting">
                <p className="setting-name">Install Web App</p>
                <div className="setting-btn">
                    <div className="toggle"></div>
                </div>
            </div>
            <Section to={"change-email"} state={pathname}>Change Email</Section>
            <Section to={"change-password"} state={pathname}>Change Password</Section>
        </>
    )
}