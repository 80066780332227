import { combineReducers } from "redux";

import authReducer from "./auth";
import userReducer from "./user";
import spacesReducer from "./space";
import appReducer from "./app";

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    space: spacesReducer,
});

export default rootReducer;