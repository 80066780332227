import {Fragment, memo, useState} from "react";
import {useDispatch} from "react-redux";
import {handleSpaceRequestAction} from "../../service/actions/spaceActions";
import {Link, useLocation} from "react-router-dom";
import {ButtonLoading} from "../loader/ButtonLoading";

function Request({request}) {
    const {user, requestId} = request;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {pathname} = useLocation();


    const handleAction = async (action) => {
        setLoading(true)
        const formData = {requestId: requestId, status: action};
        await dispatch(handleSpaceRequestAction(user, formData));
        setLoading(false);
    }


    return (
        <div className="request-item">
            <Link
                to={`/profile/${user.username}`}
                state={{from: pathname}}
                className="space-request-info"
            >
                <img src={user.profile} alt={user.name} className="requester-img"/>
                <div className="requester-info">
                    <div className="prompt-member-name">
                        {user.name}
                    </div>
                    <div className="prompt-member-username">
                        {user.username}
                    </div>
                </div>
            </Link>
            <div className="request-actions">

                { loading ? (
                    <ButtonLoading size={"15px"} />
                ) : (
                    <Fragment>
                        <button
                            className="space-request-accept-btn"
                            onClick={() => handleAction("ACCEPT")}
                            disabled={loading}
                        >
                            <img src="/correct.svg" alt="correct"/>
                        </button>

                        <button
                            className="space-request-reject-btn"
                            onClick={() => handleAction("DECLINE")}
                            disabled={loading}
                        >
                            <img src="/wrong.svg" alt="wrong" className="decline"/>
                        </button>
                    </Fragment>
                )
            }
        </div>
    </div>
  )
}

export default memo(Request);