import {memo} from "react";
import {Link} from "react-router-dom";
import AddRequest from "../shared/AddRequest";
import RemoveRequest from "../shared/RemoveRequest";

const SuggestedUser = ({request}) => {
    const {user, requestId} = request;

    return (
        <div className="suggest-user">
            <Link to={`/profile/${user?.username}`}
                className="suggest-user-info"
            >
                <img src={user?.profile} alt={user?.username}/>
                <div>
                    <div className="suggest-name">{user?.username}</div>
                    <div className="suggest-username">{user?.name}</div>
                </div>
            </Link>
            <div className="suggest-user-btn">
                {requestId === null
                    ? (
                        <AddRequest
                            user={user}
                            text="ADD"
                            className1="suggest-user-add"
                            className2="suggest-user-add-btn"
                        />
                    ):(
                        <RemoveRequest
                            request={request}
                            text="ADDED"
                            className1="suggest-user-added"
                            className2="suggest-user-added-btn"
                        />
                    )
                }
            </div>
        </div>
    )
}

export default memo(SuggestedUser);