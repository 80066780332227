import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useHandleBack} from "../../../hooks/useHandleBack";

const getTitleByPath = {
    "post": "Post",
    "members": "Members",
    "activity": "Activity",
}

export default function PsHeader2() {
    const {pathname} = useLocation();
    const path = pathname.split("/").pop();
    const [title, setTitle]  = useState("");
    const handleBack = useHandleBack();

    useEffect(() => {
        if(path === "member") return;
        const title = getTitleByPath[path]
        setTitle(title);

        return ()=>{setTitle("")}
    }, [title, path]);

    if(path === "member" || path==="upload") return;

    return (
        <header className="activity_header">
            <button onClick={handleBack} className="button">
                <img src="/line.svg" alt="back"/>
            </button>
            {title &&
                <div className="setting_title">
                    {title}
                </div>
            }
        </header>
    );
}


