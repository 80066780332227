import axios from 'axios';
import { BASEURL } from '../../utils';
import {getStore} from "../store";
import {
    AUTH_HEADER,
    MEMBER_ID_HEADER,
    SPACE_AUTH_HEADER,
    SPACE_ID_HEADER
} from "../constants/appConstants";


export const BASE_URL = `${BASEURL}/api/v1`;

// Todo: In space we tried to regenerate space token on create space, leave space, delete space
const createAxiosInstance = (baseURL) => {
    const instance = axios.create({ baseURL });

    instance.interceptors.request.use(async (config) => {
        const accessToken = JSON.parse(localStorage.getItem('profile'))?.access_token;
        const spaceToken = sessionStorage.getItem('x-ps');

        if (accessToken) {
            config.headers[AUTH_HEADER] = `Bearer ${accessToken}`;
        }
        if (config.baseURL.includes('space')) {
            const store = await getStore();
            const space = store?.getState()?.space;

            const { spaces, currentSpaceId } = space;
            const memberId = spaces[currentSpaceId]?.currentMember?.memberId;

            if(spaceToken) {
                config.headers[SPACE_AUTH_HEADER] = spaceToken;
            }
            if (memberId) {
                config.headers[MEMBER_ID_HEADER] = memberId;
            }
            if (currentSpaceId) {
                config.headers[SPACE_ID_HEADER] = currentSpaceId;
            }
        }
        return config;
    });
    return instance;
};


export const API = createAxiosInstance(BASE_URL);
export const SPACE_API = createAxiosInstance(`${BASE_URL}/in-space`);

SPACE_API.interceptors.response.use(
    (response) => {
        try {
            const spaceToken = response.headers[SPACE_AUTH_HEADER];
            if (spaceToken) {
                sessionStorage.setItem('x-ps', spaceToken);
            }
            return response;
        } catch (error) {
            throw new Error(error);
        }
    }
);

export const emailAvailabilityApi = `${BASE_URL}/auth/check-email-availability`;
export const usernameAvailabilityApi = `${BASE_URL}/api/v1/auth/check`;