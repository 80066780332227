import {Link, useLocation} from "react-router-dom";
import {memo, useState} from "react";
import MemberEmoji from "./MemberEmoji";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentSpace, getMemberById, getPostById} from "../../service/selectors/spaceSelectors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faXmark} from "@fortawesome/free-solid-svg-icons";
import DeletePostModal from "../modal/DeletePostModal";
import DisplayImage from "../shared/DisplayImage";

function Post({postId}) {
    const post = useSelector(state => getPostById(state, postId) || {});
    let { caption, image, sharedOn, memberId, memberEmoji, aspectRatio } = post
    const member = useSelector(state => getMemberById(state, memberId));
    const {currentMember} = useSelector(getCurrentSpace)
    const [modalOpen, setModalOpen] = useState(false);
    const currentMemberPosted = (memberId === currentMember.memberId);
    const dispatch = useDispatch();
    const location = useLocation();

    if(!member) return;
    const basePath = `/space/${location.pathname.split('/')[2]}`;

    return (
        <div className="post">
            <div className="post-user-info">
                <Link
                    to={currentMemberPosted
                        ? `${basePath}/your-page`
                        : `${basePath}/member?id=${memberId}`
                    }
                    state={member?.memberId}
                    className="post-user"
                >
                    <img src={member?.user?.profile} alt="image"/>
                    <div className="post-user-content">
                        <div className="post-user-name">
                            {member?.user.username}
                        </div>
                        <div className="post-user-username">
                            {member?.user.name}
                        </div>
                    </div>
                </Link>
                <div className="timestamp">
                    <div>
                        <span>
                            <FontAwesomeIcon icon={faCircle} />
                        </span>{sharedOn.substring(11, 16)}
                    </div>
                </div>
            </div>

            <div className="post-content" style={{aspectRatio}}>
                {currentMemberPosted &&
                    <button
                        onClick={() => setModalOpen(true)}
                        className="remove-btn"
                        disabled={modalOpen}
                    >
                        <FontAwesomeIcon icon={faXmark}/>
                    </button>
                }

                <Link
                    to={`${basePath}/post`}
                    state={{post: [postId], memberId}}
                >
                    <div>
                        <DisplayImage
                            image={image}
                            aspectRatio={aspectRatio}
                            altText="pic"
                            className="post-image"
                        />
                    </div>
                </Link>

                <MemberEmoji
                    postId={postId}
                    memberId={currentMember.memberId}
                    emojiId={memberEmoji}
                />

            </div>

            <div className="post-caption">
                <p><span>:</span>{caption ? caption : 'Photo Expression'}</p>
            </div>

            {modalOpen && (
                <DeletePostModal
                    onClose={() => setModalOpen(false)}
                    post={post}
                    dispatch={dispatch}
                />
            )}
          </div>
      )
}

export default memo(Post);
