import {useDispatch, useSelector} from "react-redux";
import {removeFriendRequestAction} from "../../service/actions/userActions";
import {addActionLoading, removeActionLoading} from "../../service/actions/appActions";
import {ButtonLoading} from "../loader/ButtonLoading";
import {REMOVE_FRIEND_REQUEST_ACTION} from "../../service/constants/appConstants";


const RemoveRequest = ({request, text, className1, className2}) => {
    const {requestId, user} = request;
    const dispatch = useDispatch();

    const actionLoading = useSelector(state => state.app.actionLoading);
    const loading = actionLoading[user?.userId] || false;


    const handleRequest = async () => {
        let {userId, username} = user;
        await dispatch(addActionLoading(userId, REMOVE_FRIEND_REQUEST_ACTION));
        dispatch(removeFriendRequestAction(userId, requestId, username))
            .finally(() => {
                dispatch(removeActionLoading(userId));
            });
    };

    return (
        <div className={className1}>
            {loading === REMOVE_FRIEND_REQUEST_ACTION
                ? <ButtonLoading size={"15px"} />
                : (
                    <button
                        onClick={handleRequest}
                        className={className2}
                        disabled={loading}
                    >{text}</button>
                )
            }
        </div>
    )
}
export default RemoveRequest;