import {useDispatch, useSelector} from "react-redux";
import {addFriendRequestAction} from "../../service/actions/userActions";
import {addActionLoading, removeActionLoading} from "../../service/actions/appActions";
import {ButtonLoading} from "../loader/ButtonLoading";
import {ADD_FRIEND_REQUEST_ACTION} from "../../service/constants/appConstants";

const AddRequest = ({user, text, className1, className2}) => {
    const dispatch = useDispatch();
    const actionLoading = useSelector(state => state.app.actionLoading);
    const loading = actionLoading[user?.userId] || "";

    const handleRequest = async () => {
        const userId = user?.userId;
        await dispatch(addActionLoading(userId, ADD_FRIEND_REQUEST_ACTION));
        dispatch(addFriendRequestAction(user))
            .finally(() => {
                dispatch(removeActionLoading(userId))
            });
    };

    return (
        <div className={className1}>
            {loading === ADD_FRIEND_REQUEST_ACTION
                ? <ButtonLoading size={"15px"} />
                : (
                    <button
                        onClick={handleRequest}
                        className={className2}
                        disabled={loading}
                    >
                        {text}
                    </button>
                )
            }
        </div>
    )
}

export default AddRequest;
